import React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";

import Box from "@material-ui/core/Box";
import RefreshIcon from "@material-ui/icons/Refresh";
import ChatBlock from "~/components/block/Chat";

import { BackButton } from "~CJS/atoms/BackButton";
import Button from "@material-ui/core/Button";
import { initialize, GetChatMessage } from "~slices/common/Chat";

const Chat = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const ChatState = useSelector((state) => state.Chat);
    const search = useLocation().search;
    const query = new URLSearchParams(search);

    const talent_id = query.get("talent_id");
    const company_id = query.get("company_id");

    const InformationState = useSelector((state) => state.Information);

    //相手の名前
    const user_name = () => {
        switch (true) {
            case InformationState.talent?.login:
                return ChatState?.company_data?.company_name

            case InformationState.sponsor?.login:
                return ChatState?.talent_data?.talent_name
            default:
                return "";
        }
    };

    const argments = { talent_id: talent_id, company_id: company_id };

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetChatMessage(argments));
    }, []);

    const HandleClick = () => {
        dispatch(GetChatMessage(argments));
    };

    return (
        <React.Fragment>
            {/* <div id="kotei-kousin-padding" /> */}


            <div style={{ flexGrow: 1 }} id="kotei-kousin">

                <Grid container direction="row" justifyContent="space-between" alignItems="center">


                    <Grid item>
                        <BackButton history={history} />
                    </Grid>

                    <Grid item style={{ background: '#fff', padding: '0.7rem', textAlign: 'center', minHeight: '3rem', minWidth: '10rem', border: 'solid 1px gray' }} >
                        {user_name()}
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            style={{ marginLeft: "auto", marginRight: "0" }}
                            onClick={HandleClick}
                        >
                            更新 <RefreshIcon style={{ fontSize: "1.3rem" }} />
                        </Button>
                    </Grid>
                </Grid>
            </div>

            <ChatBlock />
        </React.Fragment>
    );
};

export default Chat;
