import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import AuditionSeetInput from "~/components/block/AuditionSeetInput";
import { BackButton } from "~CJS/atoms/BackButton";
import { initialize, GetAuditionSeet, AuditionSeetCreateApi } from "~stores/TALENTBANK/AuditionSeet";
import { makeStyles } from "@material-ui/core/styles";
import { LinkButton } from "~CJS/atoms/LinkButton";

const useStyles = makeStyles((theme) => ({
    seet_paper: {
        marginTop: "1rem",
        marginBottom: "1rem",
        padding: "0.5rem",
    },
}));

const AuditionSeet = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const path = history.location.pathname.split("/");
    const offer_id = path[3];

    const AuditionSeetState = useSelector((state) => state.AuditionSeet);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetAuditionSeet({ offer_id: offer_id }));
    }, []);

    // const talent_data = {
    //     talent_name: "山田 山子",
    //     age: "30",
    //     height: "160",
    //     weight: "100",
    //     sns_link: "https://google.com",
    //     talent_pr: "特技は空中浮遊です",
    //     image_big: "/images/talentBank/test/R_BIG/R001.png",
    //     image: "/images/talentBank/test/R/R001.png",
    // };

    const handleSubmit = (e) => {
        e.preventDefault();

        const elements = e.target?.elements;
        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }
            return value;
        };

        const params = {
            audition_info: {
                offer_id: offer_id,
                audition_pr: getValue("audition_pr"),
            },
        };

        dispatch(AuditionSeetCreateApi(params)).then((data) => {
            alert("オーディションシートに応募しました。");
            history.push(`/talent/AuditionSeet/${offer_id}`);
        });
    };

    return (
        <React.Fragment>
            <Container>
                <BackButton history={history} />
                <Paper elevation={3} className={classes.seet_paper}>
                    <form onSubmit={handleSubmit}>
                        <AuditionSeetInput data={AuditionSeetState.audition_seet_detail} />

                        <Button variant="contained" color="secondary" fullWidth type="submit">
                            送信
                        </Button>
                    </form>
                </Paper>
            </Container>
        </React.Fragment>
    );
};

export default AuditionSeet;
