import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

import { LinkButton } from "~CJS/atoms/LinkButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { initialize, vote_set, VoteSet, GetVoteList, GetVote } from "~stores/TALENTBANK/Vote";

const useStyles = makeStyles((theme) => ({
    root: {
        // width: "100%",
    },
    vote_card: {
        width: "96%",
        margin: "1rem 2%",
        border: "solid 1px orange",
        padding: "0.5rem",
    },
}));

export const VoteBlock = (props) => {
    const classes = useStyles();
    const { talent_id, ...other } = props;

    const dispatch = useDispatch();
    const voteState = useSelector((state) => state.Vote);
    const vote_flag = voteState.vote_flag;
    useEffect(() => {
        dispatch(initialize("vote_data"));
        dispatch(GetVote({ talent_id: talent_id }));
    }, []);

    const HandleVoteSet = () => {
        if (vote_flag) return;
        dispatch(vote_set(["vote_flag", talent_id]));

        dispatch(VoteSet({ talent_id: talent_id })).then((data) => {
            if (data.payload.result == "2") {
                return;
            }
            dispatch(GetVote({ talent_id: talent_id }));
            alert("ご投票ありがとうございました。");
        });
    };

    const s_vote = voteState.selected_vote;
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.root}
            >
                {s_vote ? (
                    <>
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.vote_card}
                        >
                            <Grid item xs={12}>
                                <Typography> 人気投票｢{s_vote.vote_title}｣開催中!!</Typography>
                            </Grid>

                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <img src={s_vote.image} style={{ width: "100%", maxWidth: "fit-content" }}></img>
                            </Grid>

                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item style={{ width: "5rem" }}>
                                    投票期間
                                </Grid>
                                <Grid item xs>
                                    {s_vote.vote_start}~{s_vote.vote_view_close}
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item style={{ width: "5rem" }}>
                                    投票数{" "}
                                </Grid>
                                <Grid item xs>
                                    {voteState.vote_data.poll ? voteState.vote_data.poll : 0}票
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                                <LinkButton
                                    style={{
                                        fontSize: "1.1rem",
                                    }}
                                    variant="contained"
                                    to={"/common/VoteLanking"}
                                >
                                    ランキングを見る
                                </LinkButton>
                                <Button
                                    variant="contained"
                                    onClick={HandleVoteSet}
                                    // color={vote_flag == talent_id ? "primary" : "action"}
                                    disabled={vote_flag && true}
                                    style={{
                                        fontSize: "1.1rem",
                                    }}
                                >
                                    {vote_flag == talent_id ? "Good" : "Good?"}
                                    <ThumbUpIcon color={vote_flag == talent_id ? "secondary" : "action"} />
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
            </Grid>
        </>
    );
};

// export default ButtonNavi;
