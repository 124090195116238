import React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

import Box from "@material-ui/core/Box";

import Chat from "~/components/block/Chat";
import { LinkButton } from "~CJS/atoms/LinkButton";

import { initialize, chat_set, GetChatList } from "~slices/common/Chat";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    block: {
        display: "block",
    },
}));

const ChatListItem = (props) => {
    const { classes, name, image, message, count, onClick, company_id, talent_id } = props;

    return (
        <React.Fragment>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Badge badgeContent={count} color="secondary">
                        <Avatar alt={name} src={image} to={`/common/TalentDetail/${talent_id}`} component={Link} />
                    </Badge>
                </ListItemAvatar>

                <ListItemText
                    primary={name}
                    secondary={
                        <React.Fragment>
                            <Typography component="span" variant="body2" className={classes.block} noWrap>
                                {message}
                            </Typography>
                        </React.Fragment>
                    }
                    onClick={() => onClick(talent_id, company_id)}
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </React.Fragment>
    );
};

const ChatList = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    // const search = useLocation().search;
    // const query = new URLSearchParams(search);

    // console.log(query);
    // console.log(query.get("ata"));
    const InformationState = useSelector((state) => state.Information);
    const image_label = InformationState.sponsor.login ? "talent_image" : "company_image";
    const name_label = InformationState.sponsor.login ? "talent_name" : "company_name";

    const ChatState = useSelector((state) => state.Chat);
    const chat_list = ChatState.chat_list;
    const read_count = ChatState.read_count;
    const chat_list_info = ChatState.chat_list_info;

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetChatList());
    }, []);

    const handleChangePage = (event, new_page) => {
        dispatch(GetChatList({ page: new_page }));
    };

    const HandleClick = (talent_id, company_id) => {
        history.push(`/chat/Chat?talent_id=${talent_id}&&company_id=${company_id}`);
    };

    const GetReadCount = (value) => {
        const partner_id_column = InformationState.sponsor.login ? "talent_id" : "company_id";

        if (InformationState.sponsor.login) {
            return read_count[value.talent_id]?.unread_count;
        } else {
            return read_count[value.company_id]?.unread_count;
        }
    };
    return (
        <Container>
            <LinkButton to="/" label={"TOP"} />
            <Typography variant="h1">トーク</Typography>
            <Grid container spacing={1}>
                <List className={classes.root}>
                    {chat_list.map((value, index) => (
                        <React.Fragment key={index}>
                            <ChatListItem
                                image={value[image_label]}
                                classes={classes}
                                name={value[name_label]}
                                message={value.message}
                                count={GetReadCount(value)}
                                onClick={HandleClick}
                                talent_id={value.talent_id}
                                company_id={value.company_id}
                            />
                        </React.Fragment>
                    ))}
                </List>
            </Grid>
            {chat_list_info?.page_count > 1 && (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        shape="rounded"
                        count={chat_list_info?.page_count}
                        variant="outlined"
                        page={chat_list_info.page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )}
        </Container>
    );
};

export default ChatList;
