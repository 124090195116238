import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import OfferCard from "~/components/block/OfferCard";

import Pagination from "@material-ui/lab/Pagination";

import { BackButton } from "~CJS/atoms/BackButton";

import OfferSearchCriteria from "~/components/block/OfferSearchCriteria";

import { initialize, GetOfferList, offer_set } from "~stores/TALENTBANK/Offer";

import { useDispatch, useSelector } from "react-redux";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

const OfferSearch = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const OfferState = useSelector((state) => state.Offer);
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const [first, set_first] = React.useState(true);

    const returnTop = () => {
        document.getElementById("offer-serch-item-glid").scrollIntoView({
            behavior: "smooth",
        });
    };
    const StertSerch = (criteria) => {
        const encodedurl = "?" + new URLSearchParams(criteria);

        console.log("==================================");
        console.log(criteria);
        history.push({
            pathname: "/common/OfferSearch",
            search: encodedurl,
        });

        dispatch(GetOfferList(criteria)).then(() => {
            if (first) {
                set_first(false);
            } else {
                returnTop();
            }
        });
    };

    return (
        <Container>
            <ButtonNavi left_label="TOP" left_link="/" />

            {query.get("favorite") == "true" ? (
                <Typography variant="h1">お気に入り</Typography>
            ) : (
                <Typography variant="h1">お仕事をさがす</Typography>
            )}

            <OfferSearchCriteria count={OfferState.offer_serch.page_count} StertSerch={StertSerch} query={query}>
                <div id="offer-serch-item-glid" style={{ scrollMargin: "100px" }}></div>
                <Grid container direction="row" justifyContent="space-evenly">
                    {OfferState.offer_list.map((item, index) => (
                        <React.Fragment key={index}>
                            <OfferCard data={item} />
                        </React.Fragment>
                    ))}
                </Grid>
            </OfferSearchCriteria>

            {/* {OfferListState.page_count >= 1 && (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        count={OfferListState.page_count}
                        variant="outlined"
                        page={OfferListState.page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )} */}
        </Container>
    );
};

export default OfferSearch;
