import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  LinkPanelBig: {
    width: "300px",
    margin: "1%",
    height: "200px",
  },
  link_button: {
    minWidth: "180px",
  },
}));

export const LinkButton = (props) => {
  const { to, label, children, ...other } = props;

  return (
    <Button variant="contained" color="primary" component={Link} to={to} {...other}>
      {children}
      {label}
    </Button>
  );
};

export const LinkPanelBig = (props) => {
  const { to, label, icon, className, ...other } = props;
  const classes = useStyles();

  return (
    <>
      <LinkButton
        label={
          <Grid container direction="column" justifyContent="center" alignItems="center" style={{ fontSize: "1.4rem" }}>
            {props.label}
            {icon}
          </Grid>
        }
        to={to}
        className={`${classes.LinkPanelBig} ${className}`}
        {...other}
      />
    </>
  );
};

export const LinkPanel = (props) => {
  const { to, label, icon, width, ...other } = props;
  const classes = useStyles();
  return (
    <>
      <LinkButton
        className={classes.link_button}
        label={
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {props.label}
            {icon}
          </Grid>
        }
        to={to}
        {...other}
      />
    </>
  );
};

export const LinkImage = (props) => {
  const { to, label, children, ...other } = props;

  return (
    <Button component={Link} to={to} {...other}>
      {children}
    </Button>
  );
};
