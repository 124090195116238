import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles({
    root: {
        width: "90%",
    },
});

function valuetext(value) {
    return `${value}歳`;
}
const marks = [
    {
        value: 0,
        label: "0才",
    },
    {
        value: 20,
        label: "20才",
    },
    {
        value: 50,
        label: "50才",
    },
    {
        value: 80,
        label: "80才以上",
    },
];

const SelectAge = (props) => {
    const { range } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(range);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(props.range);
    }, [props.range]);

    return (
        <div className={classes.root}>
            <Typography id="range-slider" gutterBottom>
                年齢層
            </Typography>
            <Slider
                min={0}
                max={80}
                name="age"
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                marks={marks}
            />
        </div>
    );
};

SelectAge.defaultProps = {
    range: [0, 80],
};

export default SelectAge;
