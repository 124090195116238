/**
 *
 * @param {Date} date 日時
 * @param {String} format フォーマット
 * @returns 指定のフォーマットの日時
 */
export const getStringFromDate = (date, format) => {
  let year_str = date.getFullYear();
  let month_str = 1 + date.getMonth();
  let day_str = date.getDate();
  let hour_str = date.getHours();
  let minute_str = date.getMinutes();
  let second_str = date.getSeconds();

  month_str = ("0" + month_str).slice(-2);
  day_str = ("0" + day_str).slice(-2);
  hour_str = ("0" + hour_str).slice(-2);
  minute_str = ("0" + minute_str).slice(-2);
  second_str = ("0" + second_str).slice(-2);

  let format_str = "YYYY-MM-DDThh:mm";
  if (format) {
    format_str = format;
  }
  format_str = format_str.replace(/YYYY/g, year_str);
  format_str = format_str.replace(/MM/g, month_str);
  format_str = format_str.replace(/DD/g, day_str);
  format_str = format_str.replace(/hh/g, hour_str);
  format_str = format_str.replace(/mm/g, minute_str);
  format_str = format_str.replace(/ss/g, second_str);

  return format_str;
};

export const getDateObj = (date_data) => {
  const date = date_data ? date_data : new Date();

  const year_str = date.getFullYear();
  const month_str = 1 + date.getMonth();
  const day_str = date.getDate();
  const hour_str = date.getHours();
  const minute_str = date.getMinutes();
  const second_str = date.getSeconds();

  const month = ("0" + month_str).slice(-2);
  const day = ("0" + day_str).slice(-2);
  const hour = ("0" + hour_str).slice(-2);
  const minute = ("0" + minute_str).slice(-2);
  const second = ("0" + second_str).slice(-2);

  return {
    year: year_str,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: second,
  };
};

export const getDateFromString = (datestr) => {
  const date = Date.parse(datestr);
  return date;
};

/**
 *
 * @param {String} target yesterday,tomorrow,after_week,after_one_month
 * @returns {Date}
 */
export const getTargetDate = (target) => {
  let now = new Date();

  switch (target) {
    case "yesterday":
      now.setDate(now.getDate() - 1);
      break;
    case "tomorrow":
      now.setDate(now.getDate() + 1);
      break;
    case "after_week":
      now.setDate(now.getDate() + 7);
      break;
    case "after_one_month":
      now.setDate(now.getMonth() + 1);
      break;
    default:
      break;
  }
  const target_date = now;

  return target_date;
};
