import React, { useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { MessageLeft, MessageRight } from "~CJS/atoms/Message";
import { TextInput } from "~CJS/atoms/TextInput";
import { chat_set, message_add, SendMessage, GetChatMessage, CancelMessage } from "~slices/common/Chat";
import { useLocation } from "react-router-dom";
import FixedBand from "~CJS/atoms/FixedBand";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            paddingLeft: "0",
            paddingRight: "0",
        },
        paper: {
            // width: "90vw",
            // width: "100vw",
            // height: "80vh",
            // maxWidth: "500px",
            // maxHeight: "700px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            boxShadow: "none",

            // marginRight: "auto",
            // marginLeft: "auto",
        },
        paper2: {
            width: "80vw",
            maxWidth: "500px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
        },
        container: {
            width: "100vw",
            // height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        messagesBody: {
            width: "calc( 100% - 20px )",
            margin: 10,
            overflowY: "scroll",
            // height: "calc( 100% - 80px )",
            boxShadow: "none",
            overflowWrap: "break-word",
            minHeight: "70vh",
        },
        speechBubble: {
            position: "relative",
            backgroundColor: "#f0f0f0",
            border: "1px solid #ccc",
            borderRadius: "10px",
            padding: "15px",
            width: "200px",
            textAlign: "center",
            margin: "20px auto",
        },
        speechBubbleAfter: {
            content: '""',
            position: "absolute",
            bottom: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "0",
            height: "0",
            borderStyle: "solid",
            borderWidth: "10px 10px 0 10px",
            borderColor: "#f0f0f0 transparent transparent transparent",
        },
        speechBubbleBefore: {
            content: '""',
            position: "absolute",
            bottom: "-11px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "0",
            height: "0",
            borderStyle: "solid",
            borderWidth: "11px 11px 0 11px",
            borderColor: "#ccc transparent transparent transparent",
        },
    })
);

const Chat = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const ChatState = useSelector((state) => state.Chat);
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const InformationState = useSelector((state) => state.Information);

    const user_type = () => {
        switch (true) {
            case InformationState.talent?.login:
                return {
                    type: "talent",
                    user_id: InformationState.talent.talent_id,
                };
            case InformationState.sponsor?.login:
                return {
                    type: "company",
                    user_id: InformationState.sponsor.company_id,
                };
            default:
                console.debug("ログイン情報が取得できませんでした。");
                return "undefined";
        }
    };
    const user_id = user_type().user_id;

    const messagesBodyRef = useRef(null);

    const [initialize, set_initialize] = React.useState(0);
    const [select_chat_id, set_select_chat_id] = React.useState(0);

    useEffect(() => {
        if (initialize > 2) return;
        messagesBodyRef?.current?.scrollIntoView({ behavior: "smooth" });
        set_initialize(initialize + 1);
    }, [ChatState]);

    const handleChange = (e) => {
        dispatch(chat_set(["input_message", e.target.value]));
    };

    const dt = new Date();
    const year = dt.getFullYear(); //年
    const month = ("0" + (dt.getMonth() + 1)).slice(-2); //月
    const day = ("0" + dt.getDate()).slice(-2);
    const hours = ("0" + dt.getHours()).slice(-2);
    const minutes = ("0" + dt.getMinutes()).slice(-2);
    const now = `${month}月${day}日   ${hours}時${minutes}分`;

    const SendImage = (image) => {
        const message_data = {
            talent_id: query.get("talent_id"),
            company_id: query.get("company_id"),
            message: "",
            image: image,
        };
        dispatch(SendMessage({ message_data: message_data })).then(() => {
            console.log(message_data);
            messagesBodyRef?.current?.scrollIntoView();
            const argments = { talent_id: query.get("talent_id"), company_id: query.get("company_id") };
            dispatch(GetChatMessage(argments));
        });
    };
    const handleClick = (e) => {
        const message_data = {
            talent_id: query.get("talent_id"),
            company_id: query.get("company_id"),
            message: ChatState.input_message,
            image: "",
        };

        const send_text = e.target.elements.send_text.value;

        dispatch(chat_set(["input_message", ""]));

        dispatch(SendMessage({ message_data: message_data })).then(() => {
            console.log(message_data);
            messagesBodyRef?.current?.scrollIntoView();
            const argments = { talent_id: query.get("talent_id"), company_id: query.get("company_id") };
            dispatch(GetChatMessage(argments));
        });
    };


    const HandleChatClick = (item) => {
        if (select_chat_id == item.id) {
            set_select_chat_id(0);
        } else {
            set_select_chat_id(item.id);
        }
    }

    const GetDate = (create_at) => {

        const date = new Date(create_at);
        const formattedDate = date.toISOString().split('T')[0].replace(/-/g, '/');

        const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
        const weekday = weekdays[date.getDay()];

        return formattedDate + '(' + weekday + ')';
    }
    const HandleOptionClick = (arg) => {

        switch (arg.name) {
            case '取り消し':
                const argments = { talent_id: query.get("talent_id"), company_id: query.get("company_id") };
                dispatch(CancelMessage({ chat_id: select_chat_id, ...argments })).then(() => {


                    set_select_chat_id('');
                })
                break;

            default:
                break;
        }



    }
    return (
        <Container className={classes.root}>
            {/* <div className={classes.container}> */}
            <Paper className={classes.paper}>
                <>オファートーク</>
                <Paper
                    id="style-1"
                    className={classes.messagesBody}
                    style={{
                        minHeight: "1000px",
                    }}
                >
                    {ChatState.message_list.map((item, index) => (
                        <React.Fragment key={index}>




                            <div style={{
                                textAlign: 'center',
                                margin: '1rem',
                                background: '#0000000f',
                                width: '7rem',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                borderRadius: '1rem'
                            }}>
                                {/* {index === 0 ? GetDate(ChatState.message_list[index].created_at) : ''} */}
                                {index !== 0 && (GetDate(ChatState.message_list[index > 0 ? index - 1 : 0].created_at) == GetDate(ChatState.message_list[index].created_at)) ? '' : GetDate(ChatState.message_list[index].created_at)}
                            </div>

                            {item.created_cd == user_id ? (
                                <>
                                    {select_chat_id == item.id && <>  <ChetControlPopUp HandleClick={HandleOptionClick} />
                                    </>}

                                    {item.delete_flag == 1 ? <div style={{
                                        textAlign: 'center', background: '#0000000f', width: '15rem', marginRight: 'auto',
                                        marginLeft: 'auto', borderRadius: '1rem'
                                    }}>メッセージが取り消されました。</div>
                                        :

                                        <MessageRight {...item} HandleClick={() => HandleChatClick(item)} />
                                    }

                                </>

                            ) : (

                                item.delete_flag == 1 ? <div style={{
                                    textAlign: 'center', background: '#0000000f', width: '15rem', marginRight: 'auto',
                                    marginLeft: 'auto', borderRadius: '1rem'
                                }}>メッセージが取り消されました。</div>
                                    :
                                    <MessageLeft {...item} user_type={user_type()} />

                            )}
                        </React.Fragment>
                    ))}
                    <div
                        style={{
                            float: "left",
                            clear: "both",
                            // height: "250px",
                        }}
                        ref={messagesBodyRef}
                    ></div>
                </Paper>
                <Grid
                // style={{
                //     height: "20px",
                //     display: "block",
                // }}
                />
                <FixedBand>
                    <TextInput
                        // style={{

                        // }}
                        value={ChatState.input_message}
                        SendImage={SendImage}
                        onChange={handleChange}
                        onClick={handleClick}
                    />
                </FixedBand>
            </Paper>
            {/* </div> */}
        </Container>
    );
};

const ChetControlPopUp = (props) => {
    const { HandleClick } = props;
    const classes = useStyles();

    return (<>
        <div className={classes.speechBubble}>
            <Button fullWidth onClick={() => HandleClick({ name: '取り消し' })} >送信取り消し</Button>
            {/* <Button fullWidth>リプライ</Button> */}
            {/* 吹き出しの下向き三角形 */}
            <div className={classes.speechBubbleBefore}></div>
            <div className={classes.speechBubbleAfter}></div>
        </div >

    </>)
}

export default Chat;
