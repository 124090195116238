import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Route, useLocation, Switch } from "react-router-dom";

import Box from "@material-ui/core/Box";

import DrawerMenu from "~/components/block/DrawerMenu";
import ErrerModal from "~/components/block/ErrerModal";

// 共通ページ
import Top from "~/components/pages/Top";
import TalentSearch from "~/components/pages/common/TalentSearch";
import OfferSearch from "~/components/pages/common/OfferSearch";
import TalentDetail from "~/components/pages/common/TalentDetail";
import OfferDetail from "~/components/pages/common/OfferDetail";
import Policy from "~/components/pages/common/Policy";
import Question from "~/components/pages/common/Question";
import VoteLanking from "~/components/pages/common/VoteLanking";

import TalentCreateRequest from "~/components/pages/common/TalentCreateRequest";

// チャット
import ChatList from "~/components/pages/chat/ChatList";
import Chat from "~/components/pages/chat/Chat";

// ログイン
import Login from "~/components/pages/login/Login";
import Logout from "~/components/pages/login/Logout";
import TalentLogin from "~/components/pages/login/TalentLogin";
import SponsorLogin from "~/components/pages/login/SponsorLogin";
import TalentSignUp from "~/components/pages/login/TalentSignUp";
import SponsorSignUp from "~/components/pages/login/SponsorSignUp";

// タレント
import TalentProfile from "~/components/pages/talent/TalentProfile";
import TalentProfileFix from "~/components/pages/talent/TalentProfileFix";
import MyAuditionList from "~/components/pages/talent/MyAuditionList";
import AuditionSeet from "~/components/pages/talent/AuditionSeet";
import AuditionSeetFix from "~/components/pages/talent/AuditionSeetFix";
import TalentConfig from "~/components/pages/talent/TalentConfig";

// スポンサー
import AuditionSeetList from "~/components/pages/sponsor/AuditionSeetList";
import MyOfferList from "~/components/pages/sponsor/MyOfferList";
import OfferNew from "~/components/pages/sponsor/OfferNew";
import OfferDetailFix from "~/components/pages/sponsor/OfferDetailFix";
import SponsorProfile from "~/components/pages/sponsor/SponsorProfile";

// 開発用
import Config from "~/components/pages/dev/Config";

import Footer from "~/components/block/Footer";
import { AppStyle } from "~/components/AppStyle";
import { GetLoginToken } from "~slices/Information";

const theme = createTheme({ ...AppStyle });

const App = (state) => {
    const dispatch = useDispatch();

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(() => {
        dispatch(GetLoginToken());
    }, []);

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <DrawerMenu>
                    <ErrerModal />
                    <Switch>
                        {/* 共通 */}
                        <Route
                            path="/"
                            component={() => (
                                <>
                                    <Top />
                                    {/* <Footer /> */}
                                </>
                            )}
                            exact
                        />
                        <Route path="/common/TalentSearch" component={TalentSearch} exact />
                        <Route path="/common/TalentDetail/:talent_id" component={TalentDetail} exact />

                        <Route path="/common/OfferDetail/:offer_id" component={OfferDetail} exact />
                        <Route path="/common/OfferSearch" component={OfferSearch} exact />

                        <Route path="/common/Policy" component={Policy} exact />
                        <Route path="/common/Question" component={Question} exact />

                        <Route path="/common/VoteLanking" component={VoteLanking} exact />

                        <Route path="/common/TalentCreateRequest" component={TalentCreateRequest} exact />

                        {/* ログイン */}
                        <Route path="/login/Login" component={Login} exact />
                        <Route path="/login/TalentLogin" component={TalentLogin} exact />
                        <Route path="/login/SponsorLogin" component={SponsorLogin} exact />

                        <Route path="/login/TalentSignUp" component={TalentSignUp} exact />
                        <Route path="/login/SponsorSignUp" component={SponsorSignUp} exact />
                        <Route path="/login/Logout" component={Logout} exact />

                        {/* スポンサー */}
                        <Route path="/sponsor/AuditionSeetList" component={AuditionSeetList} exact />
                        <Route path="/sponsor/MyOfferList" component={MyOfferList} exact />
                        <Route path="/sponsor/OfferNew" component={OfferNew} exact />
                        <Route path="/sponsor/OfferDetailFix/:offer_id" component={OfferDetailFix} exact />

                        <Route path="/sponsor/SponsorProfile" component={SponsorProfile} exact />

                        {/* タレント */}
                        <Route path="/talent/TalentProfile" component={TalentProfile} exact />
                        <Route path="/talent/TalentProfileFix" component={TalentProfileFix} exact />
                        <Route path="/talent/MyAuditionList" component={MyAuditionList} exact />

                        <Route path="/talent/AuditionSeet/:offer_id" component={AuditionSeet} exact />
                        <Route path="/talent/AuditionSeetFix/:offer_id" component={AuditionSeetFix} exact />

                        {/* チャット */}
                        <Route path="/chat/ChatList" component={ChatList} exact />
                        <Route path="/chat/Chat" component={Chat} exact />

                        {/* {設定} */}
                        <Route path="/dev/Config" component={Config} exact />
                        <Route path="/talent/TalentConfig" component={TalentConfig} exact />
                    </Switch>
                </DrawerMenu>
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
