import React from "react";
import { InputMaxLength } from "~CJS/atoms/TextFields";
import InputAge from "~CJS/atoms/InputAge";
import InputImagePreview from "~CJS/atoms/InputImagePreview";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import SelectSex from "~CJS/atoms/SelectSex";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import CategoryInput from "~/components/block/CategoryInput";
import { InputStyle } from "~/components/AppStyle";

const theme = createTheme({ ...InputStyle });

const TalentProfileInput = (props) => {
    const {
        disabled, // true: 閲覧のみ false: 編集
        restricted, // true: 一般公開情報のみ(タレント詳細用) false: ユーザープロフィール用
        selfFixed, // true: ユーザー本人での修正(変更可能項目に制限あり)
        talent_name,
        talent_name_kana,
        age,
        image_big,
        image,
        sex,
        city,
        address_1,
        address_2,
        tell,
        achievement_count,
        review_average,
        sns_link,
        skill,
        talent_pr,
        height,
        category,
        email_address,
        password,
        ...other
    } = props;
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                {!selfFixed && (
                    <InputImagePreview
                        comment={false}
                        height={200}
                        image_big={image_big}
                        image={image}
                        disabled={disabled}
                    />
                )}
                {!selfFixed && (
                    <InputMaxLength
                        label="芸名(活動名)"
                        name="talent_name"
                        inputProps={{ maxLength: 255 }}
                        required
                        disabled={disabled}
                        value={talent_name}
                        placeholder="例)山田太郎/タロウ"
                    />
                )}
                {!selfFixed && (
                    <InputMaxLength
                        label="ふりがな"
                        name="talent_name_kana"
                        inputProps={{ maxLength: 255 }}
                        required
                        disabled={disabled}
                        value={talent_name_kana}
                        placeholder="例)やまだたろう/たろう"
                    />
                )}
                {!selfFixed && <CategoryInput disabled={disabled} category_list={category} />}
                {disabled && (
                    <InputMaxLength
                        name="review_average"
                        label="評価平均"
                        inputProps={{ maxLength: 254 }}
                        disabled={disabled}
                        value={review_average}
                    />
                )}
                {!selfFixed && (
                    <SelectSex
                        key={`${Math.floor(Math.random() * 1000)}-sex`}
                        disabled={disabled}
                        required
                        {...(disabled ? { value: sex } : { defaultValue: sex })}
                    />
                )}
                {!selfFixed && (
                    <InputAge
                        key={`${Math.floor(Math.random() * 1000)}-age`}
                        type="date"
                        name="age"
                        label="生年月日"
                        // required
                        inputProps={{ maxLength: 254 }}
                        disabled={disabled}
                        value={age}
                    />
                )}
                <InputMaxLength
                    name="height"
                    label="身長"
                    required
                    inputProps={{
                        maxLength: 30,
                        pattern: "[0-9]+(?:.[0-9]+)?",
                    }}
                    disabled={disabled}
                    value={height}
                />
                <div>
                    <SelectPrefectures
                        disabled={disabled}
                        value={props.prefectures}
                        required
                        city={{ cityValue: city }}
                        nocity={restricted}
                    />
                </div>
                {!restricted && (
                    <>
                        <InputMaxLength
                            name="address_1"
                            label="市区町村より下の住所"
                            inputProps={{ maxLength: 255 }}
                            disabled={disabled}
                            value={address_1}
                            placeholder="例)南久宝寺町4-1-1"
                        />
                        <InputMaxLength
                            name="address_2"
                            label="マンション・建物名・部屋番号"
                            inputProps={{ maxLength: 255 }}
                            disabled={disabled}
                            value={address_2}
                            placeholder="例)イデア御堂筋ビル7F"
                        />
                    </>
                )}
                <InputMaxLength
                    multiline
                    name="skill"
                    label="特技"
                    inputProps={{ maxLength: 10000 }}
                    disabled={disabled}
                    value={skill}
                    placeholder="例)歌/ギター/スタイリング/化粧"
                />
                <InputMaxLength
                    required
                    multiline
                    name="talent_pr"
                    label="自己PR"
                    inputProps={{ maxLength: 10000 }}
                    disabled={disabled}
                    value={talent_pr}
                    placeholder="フットワークが軽いです
                    レスポンスを大事にしています
                    モデルに興味があります
                    こういった活動初めてなので何でもやります"
                />
                {/* {disabled && (
                    <InputMaxLength
                        name="achievement_count"
                        label="採用件数"
                        inputProps={{ maxLength: 254 }}
                        disabled={disabled}
                        value={achievement_count}
                    />
                )} */}
            </ThemeProvider>
        </React.Fragment>
    );
};

export default TalentProfileInput;
