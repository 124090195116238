import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import OptionInput from "~CJS/block/OptionInput";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useDispatch, useSelector } from "react-redux";
import TalentProfileInput from "~/components/block/TalentProfileInput";
import { InputMaxLength, InputDate, InputTime } from "~CJS/atoms/TextFields";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

// import { GetTalentUserInfo } from "../../../stores/slices/Information";

// import { GetCategoryList } from "~stores/TALENTBANK/Talent";
import { GetOptionInput, RequestTalentCreate } from "~stores/TALENTBANK/TalentCreateRequest";


const TalentCreateRequest = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const TalentCreateRequestState = useSelector((state) => state.TalentCreateRequest);

    const input_option_list = TalentCreateRequestState.input_option_list;

    useEffect(() => {
        dispatch(GetOptionInput());
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        const getValue = (name) => {
            let value = "";
            if (elem[name]?.value) {
                value = elem[name]?.value;
            }

            return value;
        };

        if (!elem.consent_check.checked) {
            alert("承諾書に同意されていません。");
            return;
        }

        // 基本情報
        const talent_info = {
            email_address: getValue("email_address"),
            // password: getValue("password"),
            height: getValue("height"),
            image: getValue("image"),
            image_big: getValue("image_big"),
            age: getValue("age"),
            talent_name: getValue("talent_name"),
            talent_name_kana: getValue("talent_name_kana"),
            talent_pr: getValue("talent_pr"),
            skill: getValue("skill"),
            sex: getValue("sex"),
            city: getValue("city"),
            prefectures: getValue("prefectures"),
            address_1: getValue("address_1"),
            address_2: getValue("address_2"),
            tell: getValue("tell"),
            achievement_count: getValue("achievement_count"),
            review_average: getValue("review_average"),
            real_name: getValue("real_name"),
            real_name_kana: getValue("real_name_kana"),
        };
        // 追加情報
        let inputOption = [];

        input_option_list.map((value) => {
            inputOption.push({
                type: value.type,
                input_id: value.input_id,
                value: elem[value.input_id]?.value,
            });
        });

        const params = {
            talent_info: talent_info,
            input_option: inputOption,
            category: getValue("category"),
        };

        dispatch(RequestTalentCreate(params)).then((res) => {
            if (res.payload?.result === "1") {
                alert("登録しました。");
                history.push("/");
            }
        });
    };

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="TOP" left_link="/" />
                <form onSubmit={HandleSubmit}>
                    登録情報
                    <Grid style={{ border: "solid 1px gray", padding: "1rem", marginBottom: "1rem" }}>
                        ※公開はされません。
                        <InputMaxLength
                            label="FiftyCardに登録予定のメールアドレス"
                            name="email_address"
                            type="email"
                            inputProps={{ maxLength: 255 }}
                            required
                        />
                        <InputMaxLength label="本名" name="real_name" inputProps={{ maxLength: 255 }} required />
                        <InputMaxLength
                            label="ふりがな"
                            name="real_name_kana"
                            inputProps={{ maxLength: 255 }}
                            required
                        />
                    </Grid>
                    タレント公開情報
                    <Grid style={{ border: "solid 1px gray", padding: "1rem", marginBottom: "1rem" }}>
                        {/* <Typography variant="h1">プロフィール</Typography> */}
                        <TalentProfileInput category={[]} />
                        <OptionInput inputs={input_option_list} />
                    </Grid>
                    {/* <LinkButton to="/talent/TalentProfileFix" label="編集" fullWidth /> */}
                    <img src="/images/talentBank/create_request/タレント承諾書_2023_02.png" width={"100%"} />
                    <Grid>
                        <FormControlLabel control={<Checkbox name="consent_check" />} label="承諾書に同意する" />
                    </Grid>
                    <Button variant="contained" color="secondary" type="submit">
                        登録
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default TalentCreateRequest;
