import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageBlue: {
      minWidth: "12rem",
      position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#A8DDFD",
      width: "60%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #97C6E3",
      borderRadius: "10px",
      display: "inline-block",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #A8DDFD",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #97C6E3",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#f8e896",
      width: "60%",
      minWidth: "12rem",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #dfd087",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #f8e896",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #dfd087",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      marginBottom: "0.3rem",
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: "0.85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "-3px",
      right: "5px",
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: "20px",
    },
    messageReadRight: {
      marginTop: "auto",
      marginBottom: "0.5rem",
      marginRight: "0.2rem",
    },
    messageReadLeft: {
      marginTop: "auto",
      display: "inline-block",
      verticalAlign: "bottom",
      marginBottom: "0.5rem",
      marginLeft: "0.2rem",
    },
  })
);

const timestampToTime = (timestamp) => {
  const date = new Date(timestamp);
  const yyyy = `${date.getFullYear()}`;
  // .slice(-2)で文字列中の末尾の2文字を取得する
  // `0${date.getHoge()}`.slice(-2) と書くことで０埋めをする
  const MM = `0${date.getMonth() + 1}`.slice(-2); // getMonth()の返り値は0が基点
  const dd = `0${date.getDate()}`.slice(-2);
  const HH = `0${date.getHours()}`.slice(-2);
  const mm = `0${date.getMinutes()}`.slice(-2);
  const ss = `0${date.getSeconds()}`.slice(-2);

  return `${yyyy}年${MM}月${dd}日${HH}時${mm}分`;
};

//avatarが左にあるメッセージ（他人）
export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.created_at ? timestampToTime(props.created_at) : "";

  const photoURL = getPhotoURL(props);
  const displayName = getDisplayName(props);
  const classes = useStyles();
  const history = useHistory();

  const AvatarClick = () => {
    if (props.created_cd == props.company_id) return;
    history.push(`/common/TalentDetail/${props.talent_id}`);
  };

  const pattern = /^(https?|ftp)(:\/\/[\w\/:%#\$&\?\(\)~\.=\+\-]+)/;

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.messageRow}>
      <Avatar alt={displayName} className={classes.orange} src={photoURL} onClick={AvatarClick}></Avatar>
      <div>
        <div className={classes.displayName}>{displayName}</div>
        <div className={classes.messageBlue}>
          <div className={classes.messageContent}>
            {props.image ? (
              <>
                <Modal open={open} onClose={handleClose} style={{ display: "grid" }}>
                  <img src={props.image} style={{ width: "100%" }} onClick={handleClose} />
                </Modal>

                <img src={props.image} style={{ width: "100%" }} onClick={handleOpen} />
              </>
            ) : (
              <>
                {message.split("\n").map((t, i) => (
                  <React.Fragment key={i}>
                    {pattern.test(t) ? (
                      <a href={t} rel="stylesheet">
                        {t}
                      </a>
                    ) : (
                      <p>{t}</p>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
          <div className={classes.messageTimeStampRight}>{timestamp}</div>
        </div>
        <div className={classes.messageReadLeft}>{props.read_flag == "1" && "既読"}</div>
      </div>
    </div>
  );
};
const getPhotoURL = (props) => {
  switch (props.user_type?.type) {
    case "talent":
      return props.company_image ? props.company_image : "dummy.js";

    case "company":
      return props.talent_image ? props.talent_image : "dummy.js";

    default:
      return "dummy.js";
  }
};
const getDisplayName = (props) => {
  switch (props.user_type?.type) {
    case "talent":
      return props.company_name ? props.company_name : "dummy.js";

    case "company":
      return props.talent_name ? props.talent_name : "dummy.js";

    default:
      return "名無しさん";
  }
};
//自分のメッセージ
export const MessageRight = (props) => {
  const classes = useStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.created_at ? timestampToTime(props.created_at) : "";

  const displayName = getDisplayName(props);
  const photoURL = getPhotoURL(props);
  const pattern = /^(https?|ftp)(:\/\/[\w\/:%#\$&\?\(\)~\.=\+\-]+)/;

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageReadRight}>{props.read_flag == "1" && "既読"}</div>
      <div className={classes.messageOrange}>
        <div className={classes.messageContent}>
          {props.image ? (
            <>
              <Modal open={open} onClose={handleClose} style={{ display: "grid" }}>
                <img
                  src={props.image}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "90%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  onClick={handleClose}
                />
              </Modal>

              <img src={props.image} style={{ width: "100%" }} onClick={handleOpen} />
            </>
          ) : (
            <>
              {message.split("\n").map((t, i) => (
                <React.Fragment key={i}>{pattern.test(t) ? <a href={t}> {t}</a> : <p>{t}</p>}</React.Fragment>
              ))}
            </>
          )}
        </div>
        <div className={classes.messageTimeStampRight}>{timestamp}</div>
      </div>
      {props.admin && <Avatar alt={displayName} className={classes.orange} src={photoURL}></Avatar>}
    </div>
  );
};
