import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { ValidationText } from "~CJS/atoms/TextFields";
import SelectRatings from "~CJS/atoms/SelectRatings";
import { useWindowDimensions } from "~CJS/functions/useWindowDimensions";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getDateObj, getStringFromDate, getTargetDate } from "~CJS/functions/Time";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      marginTop: "0.5rem",
      background: "none",
      "& .Mui-disabled": {
        background: "none",
      },
    },
  })
);
export const AdoptSumRange = (props) => {
  const classes = useStyles();

  const { startValue, endValue, ...other } = props;
  const [adopt_sum_start, set_adopt_sum_start] = React.useState(startValue);
  const [adopt_sum_end, set_adopt_sum_end] = React.useState(endValue);

  const HandleOnChange = (e) => {
    const name = e.target.name;
    const input = e.target.value;
    if (isNaN(input) && input) {
      return false;
    }
    const value = input ? parseInt(input) : 0;

    if (name == "adopt_sum_start") {
      if (adopt_sum_end < value) {
        alert("適切な値を入力してください");
        return false;
      }
      set_adopt_sum_start(input);
    } else if (name == "adopt_sum_end") {
      if (adopt_sum_start > value) {
        alert("適切な値を入力してください");
        return false;
      }
      set_adopt_sum_end(input);
    }

    return true;
  };

  useEffect(() => {
    set_adopt_sum_start(startValue);
    set_adopt_sum_end(endValue);
  }, [startValue, endValue]);

  return (
    <>
      <input
        type="text"
        name="adoptsum_range"
        style={{ display: "none" }}
        value={`${adopt_sum_start},${adopt_sum_end}`}
        disabled
      />
      <Grid container>
        <Grid item xs>
          <ValidationText
            name="adopt_sum_start"
            label="採用件数"
            variant={props.disabled ? "filled" : "outlined"}
            inputProps={{
              maxLength: 5,
              pattern: "^[0-9]+$",
            }}
            value={adopt_sum_start}
            onChange={HandleOnChange}
            {...other}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          item
          xs={1}
          style={{ fontSize: "2rem" }}
        >
          ~
        </Grid>
        <Grid item xs>
          <ValidationText
            name="adopt_sum_end"
            variant={props.disabled ? "filled" : "outlined"}
            inputProps={{
              maxLength: 5,
              pattern: "^[0-9]+$",
            }}
            value={adopt_sum_end}
            onChange={HandleOnChange}
            {...other}
          />
        </Grid>{" "}
      </Grid>
    </>
  );
};

AdoptSumRange.defaultProps = {
  startValue: 0,
  endValue: 99999,
};

// EvaluationRange
export const EvaluationRange = (props) => {
  const classes = useStyles();
  const { startValue, endValue, ...other } = props;
  const [evaluation_start, set_evaluation_start] = React.useState(startValue);
  const [evaluation_end, set_evaluation_end] = React.useState(endValue);
  const HandleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "evaluation_start") {
      if (evaluation_end < value) {
        alert("適切な値を入力してください");
        return;
      }
      set_evaluation_start(value);
    } else if (name == "evaluation_end") {
      if (evaluation_start > value) {
        alert("適切な値を入力してください");
        return;
      }
      set_evaluation_end(value);
    }
  };

  useEffect(() => {
    set_evaluation_start(startValue);
    set_evaluation_end(endValue);
  }, [startValue, endValue]);

  const HandleReset = () => {
    set_evaluation_start(0);
    set_evaluation_end(5);
  };
  return (
    <>
      <input
        type="text"
        name="evaluation_range"
        style={{ display: "none" }}
        value={`${evaluation_start},${evaluation_end}`}
        disabled
      />
      <label
        style={{
          marginLeft: "12px",
          marginRight: "auto",
          color: "black",
          marginBottom: "-0.5rem",
          fontSize: "small",
          zIndex: "10",
          background: "#FFF",
        }}
      >
        評価
      </label>
      <Grid
        container
        style={{
          border: "solid 1px rgba(0, 0, 0, 0.17)",
          marginBottom: "1rem",
          borderRadius: "0.2rem",
          minHeight: "3rem",
          minWidth: "300px",
        }}
      >
        <Grid item xs container direction="row" justifyContent="center" alignItems="center">
          <SelectRatings name="evaluation_start" value={evaluation_start} onChange={HandleOnChange} {...other} />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          item
          xs={1}
          style={{ fontSize: "2rem" }}
        >
          ~
        </Grid>
        <Grid item xs container direction="row" justifyContent="center" alignItems="center">
          <SelectRatings name="evaluation_end" value={evaluation_end} onChange={HandleOnChange} {...other} />
        </Grid>
        <Button style={{ padding: "0", margin: "0" }} onClick={HandleReset}>
          reset
        </Button>
      </Grid>
    </>
  );
};

EvaluationRange.defaultProps = {
  startValue: 0,
  endValue: 5,
};

export const SelectRange = (props) => {
  const classes = useStyles();
  const { ...other } = props;

  const [date_start, set_date_start] = React.useState("2000-01-01");
  const [date_end, set_date_end] = React.useState("2030-12-31");
  const HandleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "date_start") {
      if (date_end < value) {
        alert("適切な値を入力してください");
        return;
      }
      set_date_start(value);
    } else if (name == "date_end") {
      if (date_start > value) {
        alert("適切な値を入力してください");
        return;
      }
      set_date_end(value);
    }
  };
  return (
    <>
      <input type="text" name="date_range" style={{ display: "none" }} value={`${date_start},${date_end}`} disabled />

      <Grid container>
        <Grid item xs>
          <TextField
            className={classes.textField}
            fullWidth
            name="date_start"
            type="date"
            label="期間"
            variant={props.disabled ? "filled" : "outlined"}
            value={date_start}
            onChange={HandleOnChange}
            {...other}
          ></TextField>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          item
          xs={1}
          style={{ fontSize: "2rem" }}
        >
          ~
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.textField}
            fullWidth
            name="date_end"
            type="date"
            variant={props.disabled ? "filled" : "outlined"}
            value={date_end}
            onChange={HandleOnChange}
            {...other}
          ></TextField>
        </Grid>{" "}
      </Grid>
    </>
  );
};

export const SelectDateRange = (props) => {
  const classes = useStyles();
  const { start, end, start_name, end_name, start_label, end_label, ...other } = props;

  const [date_start, set_date_start] = React.useState("2000-01-01");
  const [date_end, set_date_end] = React.useState("2030-12-31");
  const width = useWindowDimensions()["width"];
  useEffect(() => {
    if (!start) return;
    set_date_start(start.split(" ")[0]);
  }, [start]);
  useEffect(() => {
    if (!end) return;
    set_date_end(end.split(" ")[0]);
  }, [end]);

  const HandleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "date_start") {
      if (date_end < value) {
        alert("適切な値を入力してください");
        return;
      }
      set_date_start(value);
    } else if (name == "date_end") {
      if (date_start > value) {
        alert("適切な値を入力してください");
        return;
      }
      set_date_end(value);
    }
  };
  return (
    <>
      <input
        type="text"
        name={start_name ? start_name : "start_date"}
        style={{ display: "none" }}
        value={date_start}
        disabled
      />
      <input
        type="text"
        name={end_name ? end_name : "end_date"}
        style={{ display: "none" }}
        value={date_end}
        disabled
      />

      <Grid container>
        <Grid item xs>
          <TextField
            className={classes.textField}
            fullWidth
            name="date_start"
            type="date"
            label={start_label ? start_label : "期間"}
            variant={props.disabled ? "filled" : "outlined"}
            value={date_start}
            onChange={HandleOnChange}
            start_label="応募開始日"
            {...other}
          ></TextField>
        </Grid>
        {width > 815 && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            xs={1}
            style={{ fontSize: "2rem" }}
          >
            ~
          </Grid>
        )}
        <Grid item xs>
          <TextField
            className={classes.textField}
            fullWidth
            name="date_end"
            type="date"
            variant={props.disabled ? "filled" : "outlined"}
            value={date_end}
            onChange={HandleOnChange}
            label={end_label ? end_label : ""}
            {...other}
          ></TextField>
        </Grid>{" "}
      </Grid>
    </>
  );
};

export const SelectTimeRange = (props) => {
  const classes = useStyles();
  const { start, end, start_name, end_name, start_label, end_label, disabled, noset, ...other } = props;

  const [disabled_flag, set_disabled_flag] = React.useState(disabled);

  const [time_start, set_time_start] = React.useState("09:00");
  const [time_end, set_time_end] = React.useState("18:00");
  const [not_set, set_not_set] = React.useState(false);

  useEffect(() => {
    if (!start) return;
    set_time_start(start);
    if (!end) return;
    set_time_end(end);
  }, [start, end]);

  useEffect(() => {
    if ((time_start == "00:00:00" && time_end == "00:00:00") || (time_start == "00:00" && time_end == "00:00")) {
      set_not_set(true);
    } else {
      set_not_set(false);
    }
  }, [time_start, time_end]);

  const notsetChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      set_time_start("00:00:00");
      set_time_end("00:00:00");
      set_disabled_flag(true);
    } else {
      set_time_start("09:00:00");
      set_time_end("18:00:00");
      set_disabled_flag(false);
    }
    set_not_set(checked);
  };

  const HandleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "time_start") {
      if (time_end < value) {
        alert("終了時間より前の時間を入力してください");
        return;
      }
      set_time_start(value);
    } else if (name == "time_end") {
      if (time_start > value) {
        alert("開始時間より後の時間を入力してください");
        return;
      }
      set_time_end(value);
    }
  };

  return (
    <>
      <input
        type="text"
        name={start_name ? start_name : "start_time"}
        style={{ display: "none" }}
        value={`${time_start}`}
        disabled
      />
      <input
        type="text"
        name={end_name ? end_name : "end_time"}
        style={{ display: "none" }}
        value={`${time_end}`}
        disabled
      />
      {not_set ? (
        <Grid container>
          <TextField
            className={classes.textField}
            fullWidth
            label={start_label ? start_label : ""}
            variant="filled"
            value="未定"
            disabled
          ></TextField>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs>
            <TextField
              className={classes.textField}
              fullWidth
              name="time_start"
              type="time"
              label={start_label ? start_label : "期間"}
              variant={disabled_flag ? "filled" : "outlined"}
              disabled={disabled_flag}
              value={time_start}
              onChange={HandleOnChange}
              start_label="応募開始日"
              {...other}
            ></TextField>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            xs={1}
            style={{ fontSize: "2rem" }}
          >
            ~
          </Grid>
          <Grid item xs>
            <TextField
              className={classes.textField}
              fullWidth
              name="time_end"
              type="time"
              variant={disabled_flag ? "filled" : "outlined"}
              disabled={disabled_flag}
              value={time_end}
              onChange={HandleOnChange}
              label={end_label ? end_label : ""}
              {...other}
            ></TextField>
          </Grid>{" "}
        </Grid>
      )}
      {noset && !disabled && (
        <Grid container>
          <FormControlLabel
            control={
              <Switch
                checked={not_set}
                onChange={notsetChange}
                name="checkedA"
                disabled={disabled}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={`${start_label}を指定しない`}
          />
        </Grid>
      )}
    </>
  );
};

export const SelectDateTimeRange = (props) => {
  const classes = useStyles();
  const { start, end, start_name, end_name, start_label, end_label, ...other } = props;

  const [time_start, set_time_start] = React.useState("2022-01-01T01:01");
  const [time_end, set_time_end] = React.useState("2022-01-01T01:01");

  useEffect(() => {
    if (!start) return;
    set_time_start(start.replace(" ", "T"));
  }, [start]);
  useEffect(() => {
    if (!end) return;
    set_time_end(end.replace(" ", "T"));
  }, [end]);
  const width = useWindowDimensions()["width"];
  const HandleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const value_date_time = value.split("T");

    if (name == "time_start") {
      if (time_end < value) {
        alert("終了時間より前の時間を入力してください");
        return;
      }
      set_time_start(value);
      const end_date_time = time_end.split("T");
      set_time_end(`${value_date_time[0]}T${end_date_time[1]}`);
    } else if (name == "time_end") {
      if (time_start > value) {
        alert("開始時間より後の時間を入力してください");
        return;
      }
      set_time_end(value);
      const start_date_time = time_start.split("T");
      set_time_start(`${value_date_time[0]}T${start_date_time[1]}`);
    }
  };
  return (
    <>
      <input
        type="text"
        name={start_name ? start_name : "start_time"}
        style={{ display: "none" }}
        value={`${time_start}`}
        disabled
      />
      <input
        type="text"
        name={end_name ? end_name : "end_time"}
        style={{ display: "none" }}
        value={`${time_end}`}
        disabled
      />

      <Grid container>
        <Grid item xs>
          <TextField
            className={classes.textField}
            fullWidth
            name="time_start"
            type="datetime-local"
            label={start_label ? start_label : "期間"}
            variant={props.disabled ? "filled" : "outlined"}
            value={time_start}
            onChange={HandleOnChange}
            start_label="応募開始日"
            {...other}
          ></TextField>
        </Grid>
        {width > 815 && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            xs={1}
            style={{ fontSize: "2rem" }}
          >
            ~
          </Grid>
        )}
        <Grid item xs>
          <TextField
            className={classes.textField}
            fullWidth
            name="time_end"
            type="datetime-local"
            variant={props.disabled ? "filled" : "outlined"}
            value={time_end}
            onChange={HandleOnChange}
            label={end_label ? end_label : ""}
            {...other}
          ></TextField>
        </Grid>{" "}
      </Grid>
    </>
  );
};
