import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        width: "100%",
        position: "sticky",
        margin: "0",
        bottom: "0",
        left: "0",
        zIndex: "1300",
    },
});

const FixedBand = (props) => {
    const classes = useStyles();
    const { ...other } = props;

    return (
        <>
            <div style={{ height: "100px" }} />
            <div className={classes.root} {...other}></div>
        </>
    );
};

export default FixedBand;
