import React, { useState } from "react";
import { useEffect } from "react";

import { Grid } from "@material-ui/core/";
import { errorHandler_set } from "~slices/ErrorHandler";
import Button from "@material-ui/core/Button";

import { useDispatch, useSelector } from "react-redux";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// https://swiperjs.com/swiper-api
import { Navigation, Autoplay, Pagination, Scrollbar, A11y, EffectCoverflow } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";

export const ImageSwiper = (props) => {
    const { data } = props;

    const dispatch = useDispatch();

    return (
        <>
            <Grid item style={{ display: "grid" }}>
                {data.length >= 1 && (
                    <Swiper
                        // spaceBetween={3}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        coverflowEffect={{
                            rotate: 20,
                            stretch: 0,
                            depth: 400,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        loop={true}
                        pagination={true}
                        modules={[Autoplay, EffectCoverflow, Pagination]}
                        className="mySwiper"
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                    >
                        {data.map((item, index) => (
                            <SwiperSlide
                                key={index}
                                style={{
                                    width: "fit-content",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    // width: "10rem",
                                }}
                            >
                                <a href={item.link_url}>
                                    <img src={item.image} />
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </Grid>
        </>
    );
};

// export default ImageSwiper;

export const TalentImageSwiper = (props) => {
    // const { data } = props;
    const dispatch = useDispatch();

    const sliceByNumber = (array, number) => {
        const length = Math.ceil(array.length / number);
        return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
    };

    const data_array = sliceByNumber(props?.data ? props.data : [], 5);

    const [view_data, setViewData] = useState([]);
    const [view_page, setViewPage] = useState(1);

    useEffect(() => {
        if (!data_array[0]) return;
        setViewData(data_array[0]);
    }, [props.data]);

    const OnReachEnd = () => {
        if (view_page >= 10) return;

        if (view_data.length < 4) return;

        setViewData([...view_data, ...data_array[view_page]]);
        setViewPage(view_page + 1);
    };
    return (
        <>
            <React.Fragment>
                <Grid item style={{ display: "grid", marginTop: "1rem", maxWidth: "800px" }}>
                    <Swiper
                        // spaceBetween={3}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            scale: 0.7,
                            slideShadows: true,
                        }}
                        maxBackfaceHiddenSlides={5}
                        initialSlide={2}
                        // loop={true}
                        // pagination={true}
                        modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
                        className="mySwiper"
                        // autoplay={{
                        //     delay: 2500,
                        //     disableOnInteraction: false,
                        // }}
                        onReachEnd={OnReachEnd}
                        navigation={true}
                        rewind={true}
                    >
                        {view_data.map((item, index) => (
                            <SwiperSlide
                                key={index}
                                style={{
                                    // width: "150px",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    // height: "150px",
                                    height: "50vw",
                                    width: "50vw",
                                    maxWidth: "170px",
                                    maxHeight: "170px",
                                }}
                            >
                                <a href={`/common/TalentDetail/${item.talent_id}`}>
                                    <img
                                        src={item.image_small}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Grid>
            </React.Fragment>

            {/* {data_array.map((data, count) => (
                <React.Fragment key={count}>
                    <Grid item style={{ display: "grid", marginTop: "1rem", maxWidth: "800px" }}>
                        {data.length >= 1 && (
                            <Swiper
                                // spaceBetween={3}
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 20,
                                    stretch: 0,
                                    depth: 400,
                                    modifier: 1,
                                    slideShadows: true,
                                }}
                                initialSlide={2}
                                // loop={true}
                                // pagination={true}
                                modules={[Autoplay, EffectCoverflow, Pagination]}
                                className="mySwiper"
                                // autoplay={{
                                //     delay: 2500,
                                //     disableOnInteraction: false,
                                // }}
                                onReachEnd={OnReachEnd}
                            >
                                {data.map((item, index) => (
                                    <SwiperSlide
                                        key={index}
                                        style={{
                                            // width: "150px",
                                            marginRight: "auto",
                                            marginLeft: "auto",
                                            // height: "150px",
                                            height: "50vw",
                                            width: "50vw",
                                            maxWidth: "170px",
                                            maxHeight: "170px",
                                        }}
                                    >
                                        <a href={`/common/TalentDetail/${item.talent_id}`}>
                                            <img
                                                src={item.image_small}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </a>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </Grid>
                </React.Fragment>
            ))} */}
        </>
    );
};
