import React from "react";
import TextField from "@material-ui/core/TextField";

const SelectSort = (props) => {
    const { order_list, ...other } = props;
    return (
        <>
            <TextField
                style={{ width: "100%", marginBottom: "1rem" }}
                id="select-order"
                select
                name="order"
                label="並び順"
                fullWidth
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...other}
            >

                {order_list.map((sorts, index) => (
                    <option key={index} value={index}>
                        {sorts.label}
                    </option>
                ))}
            </TextField>
        </>
    );
};

export default SelectSort;
