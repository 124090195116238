import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { offer_set } from "~stores/TALENTBANK/Offer";
import { useDispatch, useSelector } from "react-redux";
import { ValidationText } from "~CJS/atoms/TextFields";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import SelectSort from "~CJS/atoms/SelectSort";
import { SwitchInput } from "~CJS/atoms/SwitchInput";

const useStyles = makeStyles((theme) =>
    createStyles({
        wrapForm: {
            display: "flex",
            justifyContent: "center",
            width: "95%",
            margin: `${theme.spacing(0)} auto`,
            flexWrap: "wrap",
            padding: "1rem",
            border: "solid 1px",
            // background: "#FFF",
        },
        wrapText: {
            width: "100%",
        },
        button: {
            //margin: theme.spacing(1),
        },
    })
);

const order_list = [
    {
        label: "人気順",
        value: "1",
        column: "audition_count",
        order: "desc",
    },
    { label: "登録順", value: "2", column: "created_at", order: "desc" },
    { label: "更新順", value: "3", column: "updated_at", order: "desc" },
    {
        label: "締め切り近い順",
        value: "5",
        column: "entry_close",
        order: "asc",
    },
    {
        label: "応募数が少ない順",
        value: "6",
        column: "audition_count",

        order: "asc",
    },
];
const OfferSearchCriteria = (props) => {
    const { StertSerch, count, children, query, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const submit_ref = React.useRef(null);
    const [tag_list, set_tag] = React.useState([]);
    // const [tag_select_list, set_tag_select] = React.useState([]);

    const [sort, set_sort] = React.useState(query.get("sort") ? Number(query.get("sort")) : 0);
    const [page, set_page] = React.useState(query.get("page") ? Number(query.get("page")) : 1);
    const [default_company_name, set_default_company_name] = React.useState(
        query.get("company_name") ? query.get("company_name") : criteriaState?.company_name
    );
    const [default_prefectures, set_default_prefectures] = React.useState(
        query.get("prefectures") ? query.get("prefectures") : criteriaState?.prefectures
    );

    const [before_criteria, set_before_criteria] = React.useState("");
    const [limit, set_limit] = React.useState(10);
    const [favorite, set_favorite] = React.useState(query.get("favorite") == "true" ? true : false);

    const OfferState = useSelector((state) => state.Offer);
    const criteriaState = OfferState.offer_serch_criteria;

    const default_city = criteriaState?.city;

    if (criteriaState?.sort) {
        set_sort(criteriaState.sort);
    }

    useEffect(() => {
        submit_ref.current.click();
    }, [page]);

    useEffect(() => {
        set_favorite(query.get("favorite") == "true" ? true : false);
    }, [query]);

    useEffect(() => {
        submit_ref.current.click();
    }, [favorite]);

    // const TagClick = (tag_name) => {
    //     if (tag_select_list?.includes(tag_name)) {
    //         // 選択タグリストから削除
    //         set_tag_select(tag_select_list.filter((item) => item !== tag_name));
    //     } else {
    //         if (tag_select_list.length >= 10) {
    //             alert("キーワードは10種類以上登録できません");
    //             return;
    //         }
    //         console.log(tag_select_list.length);
    //         set_tag_select([...tag_select_list, tag_name]);
    //     }
    // };

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const city = elem?.city?.value;
        const company_name = elem?.company_name?.value;
        const prefectures = elem?.prefectures?.value;
        const no_entry_close_flag = elem?.no_entry_close_flag?.value;

        const no_page_criteria = {
            prefectures: prefectures && prefectures != "未選択" ? prefectures : "",
            city: city && city != "未選択" ? city : "",
            order: JSON.stringify(order_list[sort]),
            limit: limit,
            company_name: company_name,
            favorite: favorite,
            no_entry_close_flag: no_entry_close_flag,
            sort: sort,
        };

        const new_offer_serch_criteria = {
            company_name: company_name,
            prefectures: prefectures,
            city: city,
            favorite: favorite,
        };
        dispatch(offer_set(["offer_serch_criteria", new_offer_serch_criteria]));

        let criteria = { ...no_page_criteria };

        // if (Object.is(JSON.stringify(no_page_criteria), JSON.stringify(before_criteria))) {
        // set_before_criteria(no_page_criteria);
        criteria["page"] = page;
        // } else {
        set_before_criteria(no_page_criteria);
        // set_page(1);
        // criteria["page"] = "1";

        // }

        StertSerch && StertSerch(criteria);
    };
    const handleChangePage = (event, new_page) => {
        set_page(new_page);
    };

    return (
        <React.Fragment>
            <form onSubmit={HandleSubmit} className={classes.wrapForm}>
                {/* <FormControl className={classes.wrapForm}> */}
                <SelectPrefectures value={default_prefectures} city={{ cityValue: default_city }} nocity />

                {/* <TagsDialog TagClick={TagClick} tag_list={tag_list} tag_select_list={tag_select_list} /> */}
                {/* <CategoryInput /> */}
                <ValidationText
                    name="company_name"
                    variant="outlined"
                    inputProps={{
                        maxLength: 254,
                    }}
                    value={default_company_name}
                    label="企業名"
                    {...other}
                ></ValidationText>
                <SwitchInput label="募集中のみ" name="no_entry_close_flag" value={criteriaState.no_entry_close_flag} />

                <SelectSort order_list={order_list} value={sort} onChange={(e) => set_sort(e.currentTarget.value)} />

                <Button variant="contained" type="submit" color="primary" ref={submit_ref} fullWidth>
                    検索
                </Button>
                {/* </FormControl> */}
            </form>
            {children}
            {count > 1 && (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        shape="rounded"
                        count={count}
                        variant="outlined"
                        page={page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
};

export default OfferSearchCriteria;
