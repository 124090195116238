import React, { useEffect } from "react";

import { InputMaxLength } from "~CJS/atoms/TextFields";
import InputImagePreview from "~CJS/atoms/InputImagePreview";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
    root: {
        background: "none",
        color: "black",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        "& .MuiInputBase-root .Mui-disabled": {
            color: "black",
        },
        background: "none",
        "& .Mui-disabled": {
          background: "none",
        },
    },
    comment_paper: {
        marginTop: "1rem",
        marginBottom: "1rem",
        background: "#f9cbaa",
        padding: "0.5rem",
    },
    seet_paper: {
        marginTop: "1rem",
        marginBottom: "1rem",
        padding: "0.5rem",
    },
    subtitle: {
        marginTop: "1rem",
    },
}));

const AuditionSeetInput = (props) => {
    const { disabled, data, ...other } = props;
    const classes = useStyles();
    const audition_pr = data?.audition_pr ? data.audition_pr : "";
    return (
        <React.Fragment>
            <Typography variant="h5" align="center">
                オーディションシート
            </Typography>
            <Typography className={classes.subtitle} variant="h6">
                説明
            </Typography>
            <Typography variant="caption">
                オーディションシートを記入し応募することができます。
                募集している企業が確認した後、メッセージがオファートークに届きます。
            </Typography>
            <Typography className={classes.subtitle} variant="h6">
                入力欄
            </Typography>
            <InputMaxLength
                className={classes.root}
                label="PRメッセージ"
                name="audition_pr"
                inputProps={{ maxLength: 10000 }}
                disabled={disabled}
                multiline
                value={audition_pr}
                placeholder="例 未経験ですが、精一杯がんばります。"
            />
        </React.Fragment>
    );
};

export default AuditionSeetInput;
