import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import { InputMaxLength } from "~CJS/atoms/TextFields";

const LoginPasswordForm = (props) => {
    const { HandleSubmit, ...other } = props;
    return (
        <form
            noValidate
            autoComplete="off"
            style={{ width: "100%" }}
            onSubmit={HandleSubmit}
            name="login_form"
        >
            <FormControl
                noValidate
                autoComplete="off"
                // onSubmit={HandleSubmit}
                fullWidth
            >
                <InputMaxLength
                    required
                    label="パスワード"
                    type="password"
                    name="password"
                    inputProps={{ maxLength: 254 }}
                />
                <Button variant="contained" color="secondary" type="submit">
                    ログイン
                </Button>
            </FormControl>
        </form>
    );
};

export default LoginPasswordForm;
