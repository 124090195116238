import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    talent_list: [],
    company_list: [],
};

export const GetUserList = createAsyncThunk("Config/GetUserList", async (arg, thunkAPI) => {
    const url = "/api/get_user_list";
    const params = arg;
    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {});
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "Config",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                return initialState;
            }
        },
        config_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetUserList.fulfilled]: (state, action) => {
            const resdata = action?.payload;

            if (resdata.result == "OK") {
                state.talent_list = resdata?.talent_list;
                state.company_list = resdata?.company_list;
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, config_set } = slice.actions;
