import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    category_list: [],
};

export const GetCategoryList = createAsyncThunk("Talent/GetCategoryList", async (arg, thunkAPI) => {
    const api_key = "";
    const url = "/api/category_list";

    const params = arg;

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: {
                "API-KEY": api_key,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});
// Sliceを生成する
const slice = createSlice({
    name: "Category",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        category_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetCategoryList.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "1") {
                console.debug("ジャンルの取得成功");
                state.category_list = resdata.category_list;
            }
        },
        [GetCategoryList.rejected]: (state, action) => {},
    },
});

export default slice.reducer;
export const { initialize, category_set } = slice.actions;
