import React from "react";
import { Button } from "@material-ui/core";

export const BackButton = (props) => {
  const { history } = props;
  const GoBack = () => {
    history.goBack();
  };

  return (
    <Button variant="contained" color="primary" onClick={GoBack} {...props}>
      戻る
    </Button>
  );
};
