import { orange, red, deepOrange } from "@material-ui/core/colors";

export const AppStyle = {
    palette: {
        primary: {
            main: orange[500],
            light: deepOrange[200],
            dark: "#FFF",
            contrastText: orange[0],
        },
        secondary: {
            main: deepOrange[500],
        },
    },
    overrides: {
        MuiButton: {
            root: {
                margin: "0.2rem",
                borderRadius: "0px",
                border: "double 0.4rem white",
            },
            colorInherit: {
                border: "none",
            },
            containedPrimary: {
                "&:hover": {
                    backgroundColor: orange[800],
                },
                "&:active": {
                    // backgroundColor: "#b0dcfa",
                },
            },
        },
        MuiContainer: {
            root: {
                // paddingBottom: "1rem",
            },
        },
        MuiTypography: {
            h1: {
                textAlign: "center",
                fontSize: "2rem",
                margin: "1rem 0rem",
                width: "100%",
                // backgroundColor: "#FFF",
                // backdropFilter: "blur(3px)",
                // textStroke: "1px #FFF",
                // fontWeight: "800",
                color: "orange",
            },
            h2: {
                fontSize: "1.5rem",
            },
        },
        MuiFormControl: {
            root: {
                backdropFilter: "blur(3px)",
                // backgroundColor: "#FFF",
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: "none",
            },
        },
        MuiDrawer: {
            root: {},
            paperAnchorLeft: {
                backgroundColor: "#fff",
            },
        },
        MuiDialog: {
            paperFullWidth: {
                backgroundColor: "#fff",
            },
        },
        MuiChip: {
            root: {
                borderRadius: "0px",
                margin: "3px",
            },
            // clickable: {
            //     margin: "3px",

            // },
            // colorPrimary: {
            //     margin: "3px",
            //     borderRadius: "0px",
            // },
        },
        MuiPagination: {
            root: {
                marginTop: "0.1rem",
                marginBottom: "3rem",
            },
        },
    },
};

export const InputStyle = {
    palette: {
        primary: {
            main: orange[400],
            light: deepOrange[200],
            dark: "#FFF",
            contrastText: orange[0],
        },
        secondary: {
            main: deepOrange[500],
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                "&.Mui-disabled": {
                    color: "black",
                    // opacity: 1,
                },
            },
        },
        MuiFormLabel: {
            root: {
                "&.Mui-disabled": {
                    // color: "black",
                    // opacity: 1,
                },
            },
        },
        MuiInputLabel: {
            outlined: {
                "&$shrink": {
                    color: "black",
                },
            },
        },
        MuiChip: {
            root: {
                borderRadius: "0px",
                margin: "3px",
            },
        },
    },
};
