import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";

const city_List = {
  北海道: {
    id: "01",
    name: "北海道",
    short: "北海道",
    kana: "ホッカイドウ",
    en: "hokkaido",
    city: [
      { citycode: "0010001", city: "札幌市中央区" },
      { citycode: "0010002", city: "札幌市北区" },
      { citycode: "0010003", city: "札幌市東区" },
      { citycode: "0010004", city: "札幌市白石区" },
      { citycode: "0010005", city: "札幌市豊平区" },
      { citycode: "0010006", city: "札幌市南区" },
      { citycode: "0010007", city: "札幌市西区" },
      { citycode: "0010008", city: "札幌市厚別区" },
      { citycode: "0010009", city: "札幌市手稲区" },
      { citycode: "0010010", city: "札幌市清田区" },
      { citycode: "0010011", city: "函館市" },
      { citycode: "0010012", city: "小樽市" },
      { citycode: "0010013", city: "旭川市" },
      { citycode: "0010014", city: "室蘭市" },
      { citycode: "0010015", city: "釧路市" },
      { citycode: "0010016", city: "帯広市" },
      { citycode: "0010017", city: "北見市" },
      { citycode: "0010018", city: "夕張市" },
      { citycode: "0010019", city: "岩見沢市" },
      { citycode: "0010020", city: "網走市" },
      { citycode: "0010021", city: "留萌市" },
      { citycode: "0010022", city: "苫小牧市" },
      { citycode: "0010023", city: "稚内市" },
      { citycode: "0010024", city: "美唄市" },
      { citycode: "0010025", city: "芦別市" },
      { citycode: "0010026", city: "江別市" },
      { citycode: "0010027", city: "赤平市" },
      { citycode: "0010028", city: "紋別市" },
      { citycode: "0010029", city: "士別市" },
      { citycode: "0010030", city: "名寄市" },
      { citycode: "0010031", city: "三笠市" },
      { citycode: "0010032", city: "根室市" },
      { citycode: "0010033", city: "千歳市" },
      { citycode: "0010034", city: "滝川市" },
      { citycode: "0010035", city: "砂川市" },
      { citycode: "0010036", city: "歌志内市" },
      { citycode: "0010037", city: "深川市" },
      { citycode: "0010038", city: "富良野市" },
      { citycode: "0010039", city: "登別市" },
      { citycode: "0010040", city: "恵庭市" },
      { citycode: "0010041", city: "伊達市" },
      { citycode: "0010042", city: "北広島市" },
      { citycode: "0010043", city: "石狩市" },
      { citycode: "0010044", city: "北斗市" },
      { citycode: "0010045", city: "石狩郡" },
      { citycode: "0010046", city: "松前郡" },
      { citycode: "0010047", city: "上磯郡" },
      { citycode: "0010048", city: "亀田郡" },
      { citycode: "0010049", city: "茅部郡" },
      { citycode: "0010050", city: "二海郡" },
      { citycode: "0010051", city: "山越郡" },
      { citycode: "0010052", city: "檜山郡" },
      { citycode: "0010053", city: "爾志郡" },
      { citycode: "0010054", city: "奥尻郡" },
      { citycode: "0010055", city: "瀬棚郡" },
      { citycode: "0010056", city: "久遠郡" },
      { citycode: "0010057", city: "島牧郡" },
      { citycode: "0010058", city: "寿都郡" },
      { citycode: "0010059", city: "磯谷郡" },
      { citycode: "0010060", city: "虻田郡" },
      { citycode: "0010061", city: "岩内郡" },
      { citycode: "0010062", city: "古宇郡" },
      { citycode: "0010063", city: "積丹郡" },
      { citycode: "0010064", city: "古平郡" },
      { citycode: "0010065", city: "余市郡" },
      { citycode: "0010066", city: "空知郡" },
      { citycode: "0010067", city: "夕張郡" },
      { citycode: "0010068", city: "樺戸郡" },
      { citycode: "0010069", city: "雨竜郡" },
      { citycode: "0010070", city: "上川郡" },
      { citycode: "0010071", city: "勇払郡" },
      { citycode: "0010072", city: "中川郡" },
      { citycode: "0010073", city: "増毛郡" },
      { citycode: "0010074", city: "留萌郡" },
      { citycode: "0010075", city: "苫前郡" },
      { citycode: "0010076", city: "天塩郡" },
      { citycode: "0010077", city: "宗谷郡" },
      { citycode: "0010078", city: "枝幸郡" },
      { citycode: "0010079", city: "礼文郡" },
      { citycode: "0010080", city: "利尻郡" },
      { citycode: "0010081", city: "網走郡" },
      { citycode: "0010082", city: "斜里郡" },
      { citycode: "0010083", city: "常呂郡" },
      { citycode: "0010084", city: "紋別郡" },
      { citycode: "0010085", city: "虻田郡" },
      { citycode: "0010086", city: "有珠郡" },
      { citycode: "0010087", city: "白老郡" },
      { citycode: "0010088", city: "沙流郡" },
      { citycode: "0010089", city: "新冠郡" },
      { citycode: "0010090", city: "浦河郡" },
      { citycode: "0010091", city: "様似郡" },
      { citycode: "0010092", city: "幌泉郡" },
      { citycode: "0010093", city: "日高郡" },
      { citycode: "0010094", city: "河東郡" },
      { citycode: "0010095", city: "河西郡" },
      { citycode: "0010096", city: "広尾郡" },
      { citycode: "0010097", city: "足寄郡" },
      { citycode: "0010098", city: "十勝郡" },
      { citycode: "0010099", city: "釧路郡" },
      { citycode: "0010100", city: "厚岸郡" },
      { citycode: "0010101", city: "川上郡" },
      { citycode: "0010102", city: "阿寒郡" },
      { citycode: "0010103", city: "白糠郡" },
      { citycode: "0010104", city: "野付郡" },
      { citycode: "0010105", city: "標津郡" },
      { citycode: "0010106", city: "目梨郡" }
    ]
  },
  青森県: {
    id: "02",
    name: "青森県",
    short: "青森",
    kana: "アオモリケン",
    en: "aomori",
    city: [
      { citycode: "0020001", city: "青森市" },
      { citycode: "0020002", city: "弘前市" },
      { citycode: "0020003", city: "八戸市" },
      { citycode: "0020004", city: "黒石市" },
      { citycode: "0020005", city: "五所川原市" },
      { citycode: "0020006", city: "十和田市" },
      { citycode: "0020007", city: "三沢市" },
      { citycode: "0020008", city: "むつ市" },
      { citycode: "0020009", city: "つがる市" },
      { citycode: "0020010", city: "平川市" },
      { citycode: "0020011", city: "東津軽郡" },
      { citycode: "0020012", city: "西津軽郡" },
      { citycode: "0020013", city: "中津軽郡" },
      { citycode: "0020014", city: "南津軽郡" },
      { citycode: "0020015", city: "北津軽郡" },
      { citycode: "0020016", city: "上北郡" },
      { citycode: "0020017", city: "下北郡" },
      { citycode: "0020018", city: "三戸郡" }
    ]
  },
  岩手県: {
    id: "03",
    name: "岩手県",
    short: "岩手",
    kana: "イワテケン",
    en: "iwate",
    city: [
      { citycode: "0030001", city: "盛岡市" },
      { citycode: "0030002", city: "宮古市" },
      { citycode: "0030003", city: "大船渡市" },
      { citycode: "0030004", city: "花巻市" },
      { citycode: "0030005", city: "北上市" },
      { citycode: "0030006", city: "久慈市" },
      { citycode: "0030007", city: "遠野市" },
      { citycode: "0030008", city: "一関市" },
      { citycode: "0030009", city: "陸前高田市" },
      { citycode: "0030010", city: "釜石市" },
      { citycode: "0030011", city: "二戸市" },
      { citycode: "0030012", city: "八幡平市" },
      { citycode: "0030013", city: "奥州市" },
      { citycode: "0030014", city: "滝沢市" },
      { citycode: "0030015", city: "岩手郡" },
      { citycode: "0030016", city: "紫波郡" },
      { citycode: "0030017", city: "和賀郡" },
      { citycode: "0030018", city: "胆沢郡" },
      { citycode: "0030019", city: "西磐井郡" },
      { citycode: "0030020", city: "気仙郡" },
      { citycode: "0030021", city: "上閉伊郡" },
      { citycode: "0030022", city: "下閉伊郡" },
      { citycode: "0030023", city: "九戸郡" },
      { citycode: "0030024", city: "二戸郡" }
    ]
  },
  宮城県: {
    id: "04",
    name: "宮城県",
    short: "宮城",
    kana: "ミヤギケン",
    en: "miyagi",
    city: [
      { citycode: "0040001", city: "仙台市青葉区" },
      { citycode: "0040002", city: "仙台市宮城野区" },
      { citycode: "0040003", city: "仙台市若林区" },
      { citycode: "0040004", city: "仙台市泉区" },
      { citycode: "0040005", city: "石巻市" },
      { citycode: "0040006", city: "塩竈市" },
      { citycode: "0040007", city: "気仙沼市" },
      { citycode: "0040008", city: "白石市" },
      { citycode: "0040009", city: "名取市" },
      { citycode: "0040010", city: "角田市" },
      { citycode: "0040011", city: "多賀城市" },
      { citycode: "0040012", city: "岩沼市" },
      { citycode: "0040013", city: "登米市" },
      { citycode: "0040014", city: "栗原市" },
      { citycode: "0040015", city: "東松島市" },
      { citycode: "0040016", city: "大崎市" },
      { citycode: "0040017", city: "富谷市" },
      { citycode: "0040018", city: "刈田郡" },
      { citycode: "0040019", city: "柴田郡" },
      { citycode: "0040020", city: "伊具郡" },
      { citycode: "0040021", city: "亘理郡" },
      { citycode: "0040022", city: "宮城郡" },
      { citycode: "0040023", city: "黒川郡" },
      { citycode: "0040024", city: "加美郡" },
      { citycode: "0040025", city: "遠田郡" },
      { citycode: "0040026", city: "牡鹿郡" },
      { citycode: "0040027", city: "本吉郡" }
    ]
  },
  秋田県: {
    id: "05",
    name: "秋田県",
    short: "秋田",
    kana: "アキタケン",
    en: "akita",
    city: [
      { citycode: "0050001", city: "秋田市" },
      { citycode: "0050002", city: "能代市" },
      { citycode: "0050003", city: "横手市" },
      { citycode: "0050004", city: "大館市" },
      { citycode: "0050005", city: "男鹿市" },
      { citycode: "0050006", city: "湯沢市" },
      { citycode: "0050007", city: "鹿角市" },
      { citycode: "0050008", city: "由利本荘市" },
      { citycode: "0050009", city: "潟上市" },
      { citycode: "0050010", city: "大仙市" },
      { citycode: "0050011", city: "北秋田市" },
      { citycode: "0050012", city: "にかほ市" },
      { citycode: "0050013", city: "仙北市" },
      { citycode: "0050014", city: "鹿角郡" },
      { citycode: "0050015", city: "北秋田郡" },
      { citycode: "0050016", city: "山本郡" },
      { citycode: "0050017", city: "南秋田郡" },
      { citycode: "0050018", city: "仙北郡" },
      { citycode: "0050019", city: "雄勝郡" }
    ]
  },
  山形県: {
    id: "06",
    name: "山形県",
    short: "山形",
    kana: "ヤマガタケン",
    en: "yamagata",
    city: [
      { citycode: "0060001", city: "山形市" },
      { citycode: "0060002", city: "米沢市" },
      { citycode: "0060003", city: "鶴岡市" },
      { citycode: "0060004", city: "酒田市" },
      { citycode: "0060005", city: "新庄市" },
      { citycode: "0060006", city: "寒河江市" },
      { citycode: "0060007", city: "上山市" },
      { citycode: "0060008", city: "村山市" },
      { citycode: "0060009", city: "長井市" },
      { citycode: "0060010", city: "天童市" },
      { citycode: "0060011", city: "東根市" },
      { citycode: "0060012", city: "尾花沢市" },
      { citycode: "0060013", city: "南陽市" },
      { citycode: "0060014", city: "東村山郡" },
      { citycode: "0060015", city: "西村山郡" },
      { citycode: "0060016", city: "北村山郡" },
      { citycode: "0060017", city: "最上郡" },
      { citycode: "0060018", city: "東置賜郡" },
      { citycode: "0060019", city: "西置賜郡" },
      { citycode: "0060020", city: "東田川郡" },
      { citycode: "0060021", city: "飽海郡" }
    ]
  },
  福島県: {
    id: "07",
    name: "福島県",
    short: "福島",
    kana: "フクシマケン",
    en: "fukushima",
    city: [
      { citycode: "0070001", city: "福島市" },
      { citycode: "0070002", city: "会津若松市" },
      { citycode: "0070003", city: "郡山市" },
      { citycode: "0070004", city: "いわき市" },
      { citycode: "0070005", city: "白河市" },
      { citycode: "0070006", city: "須賀川市" },
      { citycode: "0070007", city: "喜多方市" },
      { citycode: "0070008", city: "相馬市" },
      { citycode: "0070009", city: "二本松市" },
      { citycode: "0070010", city: "田村市" },
      { citycode: "0070011", city: "南相馬市" },
      { citycode: "0070012", city: "伊達市" },
      { citycode: "0070013", city: "本宮市" },
      { citycode: "0070014", city: "伊達郡" },
      { citycode: "0070015", city: "安達郡" },
      { citycode: "0070016", city: "岩瀬郡" },
      { citycode: "0070017", city: "南会津郡" },
      { citycode: "0070018", city: "耶麻郡" },
      { citycode: "0070019", city: "河沼郡" },
      { citycode: "0070020", city: "大沼郡" },
      { citycode: "0070021", city: "西白河郡" },
      { citycode: "0070022", city: "東白川郡" },
      { citycode: "0070023", city: "石川郡" },
      { citycode: "0070024", city: "田村郡" },
      { citycode: "0070025", city: "双葉郡" },
      { citycode: "0070026", city: "相馬郡" }
    ]
  },
  茨城県: {
    id: "08",
    name: "茨城県",
    short: "茨城",
    kana: "イバラキケン",
    en: "ibaraki",
    city: [
      { citycode: "0080001", city: "水戸市" },
      { citycode: "0080002", city: "日立市" },
      { citycode: "0080003", city: "土浦市" },
      { citycode: "0080004", city: "古河市" },
      { citycode: "0080005", city: "石岡市" },
      { citycode: "0080006", city: "結城市" },
      { citycode: "0080007", city: "龍ケ崎市" },
      { citycode: "0080008", city: "下妻市" },
      { citycode: "0080009", city: "常総市" },
      { citycode: "0080010", city: "常陸太田市" },
      { citycode: "0080011", city: "高萩市" },
      { citycode: "0080012", city: "北茨城市" },
      { citycode: "0080013", city: "笠間市" },
      { citycode: "0080014", city: "取手市" },
      { citycode: "0080015", city: "牛久市" },
      { citycode: "0080016", city: "つくば市" },
      { citycode: "0080017", city: "ひたちなか市" },
      { citycode: "0080018", city: "鹿嶋市" },
      { citycode: "0080019", city: "潮来市" },
      { citycode: "0080020", city: "守谷市" },
      { citycode: "0080021", city: "常陸大宮市" },
      { citycode: "0080022", city: "那珂市" },
      { citycode: "0080023", city: "筑西市" },
      { citycode: "0080024", city: "坂東市" },
      { citycode: "0080025", city: "稲敷市" },
      { citycode: "0080026", city: "かすみがうら市" },
      { citycode: "0080027", city: "桜川市" },
      { citycode: "0080028", city: "神栖市" },
      { citycode: "0080029", city: "行方市" },
      { citycode: "0080030", city: "鉾田市" },
      { citycode: "0080031", city: "つくばみらい市" },
      { citycode: "0080032", city: "小美玉市" },
      { citycode: "0080033", city: "東茨城郡" },
      { citycode: "0080034", city: "那珂郡" },
      { citycode: "0080035", city: "久慈郡" },
      { citycode: "0080036", city: "稲敷郡" },
      { citycode: "0080037", city: "結城郡" },
      { citycode: "0080038", city: "猿島郡" },
      { citycode: "0080039", city: "北相馬郡" }
    ]
  },
  栃木県: {
    id: "09",
    name: "栃木県",
    short: "栃木",
    kana: "トチギケン",
    en: "tochigi",
    city: [
      { citycode: "0090001", city: "宇都宮市" },
      { citycode: "0090002", city: "足利市" },
      { citycode: "0090003", city: "栃木市" },
      { citycode: "0090004", city: "佐野市" },
      { citycode: "0090005", city: "鹿沼市" },
      { citycode: "0090006", city: "日光市" },
      { citycode: "0090007", city: "小山市" },
      { citycode: "0090008", city: "真岡市" },
      { citycode: "0090009", city: "大田原市" },
      { citycode: "0090010", city: "矢板市" },
      { citycode: "0090011", city: "那須塩原市" },
      { citycode: "0090012", city: "さくら市" },
      { citycode: "0090013", city: "那須烏山市" },
      { citycode: "0090014", city: "下野市" },
      { citycode: "0090015", city: "河内郡" },
      { citycode: "0090016", city: "芳賀郡" },
      { citycode: "0090017", city: "下都賀郡" },
      { citycode: "0090018", city: "塩谷郡" },
      { citycode: "0090019", city: "那須郡" }
    ]
  },
  群馬県: {
    id: "10",
    name: "群馬県",
    short: "群馬",
    kana: "グンマケン",
    en: "gunma",
    city: [
      { citycode: "0100001", city: "前橋市" },
      { citycode: "0100002", city: "高崎市" },
      { citycode: "0100003", city: "桐生市" },
      { citycode: "0100004", city: "伊勢崎市" },
      { citycode: "0100005", city: "太田市" },
      { citycode: "0100006", city: "沼田市" },
      { citycode: "0100007", city: "館林市" },
      { citycode: "0100008", city: "渋川市" },
      { citycode: "0100009", city: "藤岡市" },
      { citycode: "0100010", city: "富岡市" },
      { citycode: "0100011", city: "安中市" },
      { citycode: "0100012", city: "みどり市" },
      { citycode: "0100013", city: "北群馬郡" },
      { citycode: "0100014", city: "多野郡" },
      { citycode: "0100015", city: "甘楽郡" },
      { citycode: "0100016", city: "吾妻郡" },
      { citycode: "0100017", city: "利根郡" },
      { citycode: "0100018", city: "佐波郡" },
      { citycode: "0100019", city: "邑楽郡" }
    ]
  },
  埼玉県: {
    id: "11",
    name: "埼玉県",
    short: "埼玉",
    kana: "サイタマケン",
    en: "saitama",
    city: [
      { citycode: "0110001", city: "さいたま市西区" },
      { citycode: "0110002", city: "さいたま市北区" },
      { citycode: "0110003", city: "さいたま市大宮区" },
      { citycode: "0110004", city: "さいたま市見沼区" },
      { citycode: "0110005", city: "さいたま市中央区" },
      { citycode: "0110006", city: "さいたま市桜区" },
      { citycode: "0110007", city: "さいたま市浦和区" },
      { citycode: "0110008", city: "さいたま市南区" },
      { citycode: "0110009", city: "さいたま市緑区" },
      { citycode: "0110010", city: "さいたま市岩槻区" },
      { citycode: "0110011", city: "川越市" },
      { citycode: "0110012", city: "熊谷市" },
      { citycode: "0110013", city: "川口市" },
      { citycode: "0110014", city: "行田市" },
      { citycode: "0110015", city: "秩父市" },
      { citycode: "0110016", city: "所沢市" },
      { citycode: "0110017", city: "飯能市" },
      { citycode: "0110018", city: "加須市" },
      { citycode: "0110019", city: "本庄市" },
      { citycode: "0110020", city: "東松山市" },
      { citycode: "0110021", city: "春日部市" },
      { citycode: "0110022", city: "狭山市" },
      { citycode: "0110023", city: "羽生市" },
      { citycode: "0110024", city: "鴻巣市" },
      { citycode: "0110025", city: "深谷市" },
      { citycode: "0110026", city: "上尾市" },
      { citycode: "0110027", city: "草加市" },
      { citycode: "0110028", city: "越谷市" },
      { citycode: "0110029", city: "蕨市" },
      { citycode: "0110030", city: "戸田市" },
      { citycode: "0110031", city: "入間市" },
      { citycode: "0110032", city: "朝霞市" },
      { citycode: "0110033", city: "志木市" },
      { citycode: "0110034", city: "和光市" },
      { citycode: "0110035", city: "新座市" },
      { citycode: "0110036", city: "桶川市" },
      { citycode: "0110037", city: "久喜市" },
      { citycode: "0110038", city: "北本市" },
      { citycode: "0110039", city: "八潮市" },
      { citycode: "0110040", city: "富士見市" },
      { citycode: "0110041", city: "三郷市" },
      { citycode: "0110042", city: "蓮田市" },
      { citycode: "0110043", city: "坂戸市" },
      { citycode: "0110044", city: "幸手市" },
      { citycode: "0110045", city: "鶴ヶ島市" },
      { citycode: "0110046", city: "日高市" },
      { citycode: "0110047", city: "吉川市" },
      { citycode: "0110048", city: "ふじみ野市" },
      { citycode: "0110049", city: "白岡市" },
      { citycode: "0110050", city: "北足立郡" },
      { citycode: "0110051", city: "入間郡" },
      { citycode: "0110052", city: "比企郡" },
      { citycode: "0110053", city: "秩父郡" },
      { citycode: "0110054", city: "児玉郡" },
      { citycode: "0110055", city: "大里郡" },
      { citycode: "0110056", city: "南埼玉郡" },
      { citycode: "0110057", city: "北葛飾郡" }
    ]
  },
  千葉県: {
    id: "12",
    name: "千葉県",
    short: "千葉",
    kana: "チバケン",
    en: "chiba",
    city: [
      { citycode: "0120001", city: "千葉市中央区" },
      { citycode: "0120002", city: "千葉市花見川区" },
      { citycode: "0120003", city: "千葉市稲毛区" },
      { citycode: "0120004", city: "千葉市若葉区" },
      { citycode: "0120005", city: "千葉市緑区" },
      { citycode: "0120006", city: "千葉市美浜区" },
      { citycode: "0120007", city: "銚子市" },
      { citycode: "0120008", city: "市川市" },
      { citycode: "0120009", city: "船橋市" },
      { citycode: "0120010", city: "館山市" },
      { citycode: "0120011", city: "木更津市" },
      { citycode: "0120012", city: "松戸市" },
      { citycode: "0120013", city: "野田市" },
      { citycode: "0120014", city: "茂原市" },
      { citycode: "0120015", city: "成田市" },
      { citycode: "0120016", city: "佐倉市" },
      { citycode: "0120017", city: "東金市" },
      { citycode: "0120018", city: "旭市" },
      { citycode: "0120019", city: "習志野市" },
      { citycode: "0120020", city: "柏市" },
      { citycode: "0120021", city: "勝浦市" },
      { citycode: "0120022", city: "市原市" },
      { citycode: "0120023", city: "流山市" },
      { citycode: "0120024", city: "八千代市" },
      { citycode: "0120025", city: "我孫子市" },
      { citycode: "0120026", city: "鴨川市" },
      { citycode: "0120027", city: "鎌ケ谷市" },
      { citycode: "0120028", city: "君津市" },
      { citycode: "0120029", city: "富津市" },
      { citycode: "0120030", city: "浦安市" },
      { citycode: "0120031", city: "四街道市" },
      { citycode: "0120032", city: "袖ケ浦市" },
      { citycode: "0120033", city: "八街市" },
      { citycode: "0120034", city: "印西市" },
      { citycode: "0120035", city: "白井市" },
      { citycode: "0120036", city: "富里市" },
      { citycode: "0120037", city: "南房総市" },
      { citycode: "0120038", city: "匝瑳市" },
      { citycode: "0120039", city: "香取市" },
      { citycode: "0120040", city: "山武市" },
      { citycode: "0120041", city: "いすみ市" },
      { citycode: "0120042", city: "大網白里市" },
      { citycode: "0120043", city: "印旛郡" },
      { citycode: "0120044", city: "香取郡" },
      { citycode: "0120045", city: "山武郡" },
      { citycode: "0120046", city: "長生郡" },
      { citycode: "0120047", city: "夷隅郡" },
      { citycode: "0120048", city: "夷隅郡" },
      { citycode: "0120049", city: "安房郡" }
    ]
  },
  東京都: {
    id: "13",
    name: "東京都",
    short: "東京",
    kana: "トウキョウト",
    en: "tokyo",
    city: [
      { citycode: "0130001", city: "千代田区" },
      { citycode: "0130002", city: "中央区" },
      { citycode: "0130003", city: "港区" },
      { citycode: "0130004", city: "新宿区" },
      { citycode: "0130005", city: "文京区" },
      { citycode: "0130006", city: "台東区" },
      { citycode: "0130007", city: "墨田区" },
      { citycode: "0130008", city: "江東区" },
      { citycode: "0130009", city: "品川区" },
      { citycode: "0130010", city: "目黒区" },
      { citycode: "0130011", city: "大田区" },
      { citycode: "0130012", city: "世田谷区" },
      { citycode: "0130013", city: "渋谷区" },
      { citycode: "0130014", city: "中野区" },
      { citycode: "0130015", city: "杉並区" },
      { citycode: "0130016", city: "豊島区" },
      { citycode: "0130017", city: "北区" },
      { citycode: "0130018", city: "荒川区" },
      { citycode: "0130019", city: "板橋区" },
      { citycode: "0130020", city: "練馬区" },
      { citycode: "0130021", city: "足立区" },
      { citycode: "0130022", city: "葛飾区" },
      { citycode: "0130023", city: "江戸川区" },
      { citycode: "0130024", city: "八王子市" },
      { citycode: "0130025", city: "立川市" },
      { citycode: "0130026", city: "武蔵野市" },
      { citycode: "0130027", city: "三鷹市" },
      { citycode: "0130028", city: "青梅市" },
      { citycode: "0130029", city: "府中市" },
      { citycode: "0130030", city: "昭島市" },
      { citycode: "0130031", city: "調布市" },
      { citycode: "0130032", city: "町田市" },
      { citycode: "0130033", city: "小金井市" },
      { citycode: "0130034", city: "小平市" },
      { citycode: "0130035", city: "日野市" },
      { citycode: "0130036", city: "東村山市" },
      { citycode: "0130037", city: "国分寺市" },
      { citycode: "0130038", city: "国立市" },
      { citycode: "0130039", city: "福生市" },
      { citycode: "0130040", city: "狛江市" },
      { citycode: "0130041", city: "東大和市" },
      { citycode: "0130042", city: "清瀬市" },
      { citycode: "0130043", city: "東久留米市" },
      { citycode: "0130044", city: "武蔵村山市" },
      { citycode: "0130045", city: "多摩市" },
      { citycode: "0130046", city: "稲城市" },
      { citycode: "0130047", city: "羽村市" },
      { citycode: "0130048", city: "あきる野市" },
      { citycode: "0130049", city: "西東京市" },
      { citycode: "0130050", city: "西多摩郡" },
      { citycode: "0130051", city: "大島町" },
      { citycode: "0130052", city: "新島村" },
      { citycode: "0130053", city: "三宅島" },
      { citycode: "0130054", city: "八丈島" },
      { citycode: "0130055", city: "小笠原村" }
    ]
  },
  神奈川県: {
    id: "14",
    name: "神奈川県",
    short: "神奈川",
    kana: "カナガワケン",
    en: "kanagawa",
    city: [
      { citycode: "0140001", city: "横浜市鶴見区" },
      { citycode: "0140002", city: "横浜市神奈川区" },
      { citycode: "0140003", city: "横浜市西区" },
      { citycode: "0140004", city: "横浜市中区" },
      { citycode: "0140005", city: "横浜市南区" },
      { citycode: "0140006", city: "横浜市保土ケ谷区" },
      { citycode: "0140007", city: "横浜市磯子区" },
      { citycode: "0140008", city: "横浜市金沢区" },
      { citycode: "0140009", city: "横浜市港北区" },
      { citycode: "0140010", city: "横浜市戸塚区" },
      { citycode: "0140011", city: "横浜市港南区" },
      { citycode: "0140012", city: "横浜市旭区" },
      { citycode: "0140013", city: "横浜市緑区" },
      { citycode: "0140014", city: "横浜市瀬谷区" },
      { citycode: "0140015", city: "横浜市栄区" },
      { citycode: "0140016", city: "横浜市泉区" },
      { citycode: "0140017", city: "横浜市青葉区" },
      { citycode: "0140018", city: "横浜市都筑区" },
      { citycode: "0140019", city: "川崎市川崎区" },
      { citycode: "0140020", city: "川崎市幸区" },
      { citycode: "0140021", city: "川崎市中原区" },
      { citycode: "0140022", city: "川崎市高津区" },
      { citycode: "0140023", city: "川崎市多摩区" },
      { citycode: "0140024", city: "川崎市宮前区" },
      { citycode: "0140025", city: "川崎市麻生区" },
      { citycode: "0140026", city: "相模原市緑区" },
      { citycode: "0140027", city: "相模原市中央区" },
      { citycode: "0140028", city: "相模原市南区" },
      { citycode: "0140029", city: "横須賀市" },
      { citycode: "0140030", city: "平塚市" },
      { citycode: "0140031", city: "鎌倉市" },
      { citycode: "0140032", city: "藤沢市" },
      { citycode: "0140033", city: "小田原市" },
      { citycode: "0140034", city: "茅ヶ崎市" },
      { citycode: "0140035", city: "逗子市" },
      { citycode: "0140036", city: "三浦市" },
      { citycode: "0140037", city: "秦野市" },
      { citycode: "0140038", city: "厚木市" },
      { citycode: "0140039", city: "大和市" },
      { citycode: "0140040", city: "伊勢原市" },
      { citycode: "0140041", city: "海老名市" },
      { citycode: "0140042", city: "座間市" },
      { citycode: "0140043", city: "南足柄市" },
      { citycode: "0140044", city: "綾瀬市" },
      { citycode: "0140045", city: "三浦郡" },
      { citycode: "0140046", city: "高座郡" },
      { citycode: "0140047", city: "中郡" },
      { citycode: "0140048", city: "足柄上郡" },
      { citycode: "0140049", city: "足柄下郡" },
      { citycode: "0140050", city: "愛甲郡" }
    ]
  },
  新潟県: {
    id: "15",
    name: "新潟県",
    short: "新潟",
    kana: "ニイガタケン",
    en: "niigata",
    city: [
      { citycode: "0150001", city: "新潟市北区" },
      { citycode: "0150002", city: "新潟市東区" },
      { citycode: "0150003", city: "新潟市中央区" },
      { citycode: "0150004", city: "新潟市江南区" },
      { citycode: "0150005", city: "新潟市秋葉区" },
      { citycode: "0150006", city: "新潟市南区" },
      { citycode: "0150007", city: "新潟市西区" },
      { citycode: "0150008", city: "新潟市西蒲区" },
      { citycode: "0150009", city: "長岡市" },
      { citycode: "0150010", city: "三条市" },
      { citycode: "0150011", city: "柏崎市" },
      { citycode: "0150012", city: "新発田市" },
      { citycode: "0150013", city: "小千谷市" },
      { citycode: "0150014", city: "加茂市" },
      { citycode: "0150015", city: "十日町市" },
      { citycode: "0150016", city: "見附市" },
      { citycode: "0150017", city: "村上市" },
      { citycode: "0150018", city: "燕市" },
      { citycode: "0150019", city: "糸魚川市" },
      { citycode: "0150020", city: "妙高市" },
      { citycode: "0150021", city: "五泉市" },
      { citycode: "0150022", city: "上越市" },
      { citycode: "0150023", city: "阿賀野市" },
      { citycode: "0150024", city: "佐渡市" },
      { citycode: "0150025", city: "魚沼市" },
      { citycode: "0150026", city: "南魚沼市" },
      { citycode: "0150027", city: "胎内市" },
      { citycode: "0150028", city: "北蒲原郡" },
      { citycode: "0150029", city: "西蒲原郡" },
      { citycode: "0150030", city: "南蒲原郡" },
      { citycode: "0150031", city: "東蒲原郡" },
      { citycode: "0150032", city: "三島郡" },
      { citycode: "0150033", city: "南魚沼郡" },
      { citycode: "0150034", city: "中魚沼郡" },
      { citycode: "0150035", city: "刈羽郡" },
      { citycode: "0150036", city: "岩船郡" }
    ]
  },
  富山県: {
    id: "16",
    name: "富山県",
    short: "富山",
    kana: "トヤマケン",
    en: "toyama",
    city: [
      { citycode: "0160001", city: "富山市" },
      { citycode: "0160002", city: "高岡市" },
      { citycode: "0160003", city: "魚津市" },
      { citycode: "0160004", city: "氷見市" },
      { citycode: "0160005", city: "滑川市" },
      { citycode: "0160006", city: "黒部市" },
      { citycode: "0160007", city: "砺波市" },
      { citycode: "0160008", city: "小矢部市" },
      { citycode: "0160009", city: "南砺市" },
      { citycode: "0160010", city: "射水市" },
      { citycode: "0160011", city: "中新川郡" },
      { citycode: "0160012", city: "下新川郡" }
    ]
  },
  石川県: {
    id: "17",
    name: "石川県",
    short: "石川",
    kana: "イシカワケン",
    en: "ishikawa",
    city: [
      { citycode: "0170001", city: "金沢市" },
      { citycode: "0170002", city: "七尾市" },
      { citycode: "0170003", city: "小松市" },
      { citycode: "0170004", city: "輪島市" },
      { citycode: "0170005", city: "珠洲市" },
      { citycode: "0170006", city: "加賀市" },
      { citycode: "0170007", city: "羽咋市" },
      { citycode: "0170008", city: "かほく市" },
      { citycode: "0170009", city: "白山市" },
      { citycode: "0170010", city: "能美市" },
      { citycode: "0170011", city: "野々市市" },
      { citycode: "0170012", city: "能美郡" },
      { citycode: "0170013", city: "河北郡" },
      { citycode: "0170014", city: "羽咋郡" },
      { citycode: "0170015", city: "鹿島郡" },
      { citycode: "0170016", city: "鳳珠郡" }
    ]
  },
  福井県: {
    id: "18",
    name: "福井県",
    short: "福井",
    kana: "フクイケン",
    en: "fukui",
    city: [
      { citycode: "0180001", city: "福井市" },
      { citycode: "0180002", city: "敦賀市" },
      { citycode: "0180003", city: "小浜市" },
      { citycode: "0180004", city: "大野市" },
      { citycode: "0180005", city: "勝山市" },
      { citycode: "0180006", city: "鯖江市" },
      { citycode: "0180007", city: "あわら市" },
      { citycode: "0180008", city: "越前市" },
      { citycode: "0180009", city: "坂井市" },
      { citycode: "0180010", city: "吉田郡" },
      { citycode: "0180011", city: "今立郡" },
      { citycode: "0180012", city: "南条郡" },
      { citycode: "0180013", city: "丹生郡" },
      { citycode: "0180014", city: "三方郡" },
      { citycode: "0180015", city: "大飯郡" },
      { citycode: "0180016", city: "三方上中郡" }
    ]
  },
  山梨県: {
    id: "19",
    name: "山梨県",
    short: "山梨",
    kana: "ヤマナシケン",
    en: "yamanashi",
    city: [
      { citycode: "0190001", city: "甲府市" },
      { citycode: "0190002", city: "富士吉田市" },
      { citycode: "0190003", city: "都留市" },
      { citycode: "0190004", city: "山梨市" },
      { citycode: "0190005", city: "大月市" },
      { citycode: "0190006", city: "韮崎市" },
      { citycode: "0190007", city: "南アルプス市" },
      { citycode: "0190008", city: "北杜市" },
      { citycode: "0190009", city: "甲斐市" },
      { citycode: "0190010", city: "笛吹市" },
      { citycode: "0190011", city: "上野原市" },
      { citycode: "0190012", city: "甲州市" },
      { citycode: "0190013", city: "中央市" },
      { citycode: "0190014", city: "西八代郡" },
      { citycode: "0190015", city: "南巨摩郡" },
      { citycode: "0190016", city: "中巨摩郡" },
      { citycode: "0190017", city: "南都留郡" },
      { citycode: "0190018", city: "北都留郡" }
    ]
  },
  長野県: {
    id: "20",
    name: "長野県",
    short: "長野",
    kana: "ナガノケン",
    en: "nagano",
    city: [
      { citycode: "0200001", city: "長野市" },
      { citycode: "0200002", city: "松本市" },
      { citycode: "0200003", city: "上田市" },
      { citycode: "0200004", city: "岡谷市" },
      { citycode: "0200005", city: "飯田市" },
      { citycode: "0200006", city: "諏訪市" },
      { citycode: "0200007", city: "須坂市" },
      { citycode: "0200008", city: "小諸市" },
      { citycode: "0200009", city: "伊那市" },
      { citycode: "0200010", city: "駒ヶ根市" },
      { citycode: "0200011", city: "中野市" },
      { citycode: "0200012", city: "大町市" },
      { citycode: "0200013", city: "飯山市" },
      { citycode: "0200014", city: "茅野市" },
      { citycode: "0200015", city: "塩尻市" },
      { citycode: "0200016", city: "佐久市" },
      { citycode: "0200017", city: "千曲市" },
      { citycode: "0200018", city: "東御市" },
      { citycode: "0200019", city: "安曇野市" },
      { citycode: "0200020", city: "南佐久郡" },
      { citycode: "0200021", city: "北佐久郡" },
      { citycode: "0200022", city: "小県郡" },
      { citycode: "0200023", city: "諏訪郡" },
      { citycode: "0200024", city: "上伊那郡" },
      { citycode: "0200025", city: "下伊那郡" },
      { citycode: "0200026", city: "木曽郡" },
      { citycode: "0200027", city: "東筑摩郡" },
      { citycode: "0200028", city: "北安曇郡" },
      { citycode: "0200029", city: "埴科郡" },
      { citycode: "0200030", city: "上高井郡" },
      { citycode: "0200031", city: "下高井郡" },
      { citycode: "0200032", city: "上水内郡" },
      { citycode: "0200033", city: "下水内郡" }
    ]
  },
  岐阜県: {
    id: "21",
    name: "岐阜県",
    short: "岐阜",
    kana: "ギフケン",
    en: "gifu",
    city: [
      { citycode: "0210001", city: "岐阜市" },
      { citycode: "0210002", city: "大垣市" },
      { citycode: "0210003", city: "高山市" },
      { citycode: "0210004", city: "多治見市" },
      { citycode: "0210005", city: "関市" },
      { citycode: "0210006", city: "中津川市" },
      { citycode: "0210007", city: "美濃市" },
      { citycode: "0210008", city: "瑞浪市" },
      { citycode: "0210009", city: "羽島市" },
      { citycode: "0210010", city: "恵那市" },
      { citycode: "0210011", city: "美濃加茂市" },
      { citycode: "0210012", city: "土岐市" },
      { citycode: "0210013", city: "各務原市" },
      { citycode: "0210014", city: "可児市" },
      { citycode: "0210015", city: "山県市" },
      { citycode: "0210016", city: "瑞穂市" },
      { citycode: "0210017", city: "飛騨市" },
      { citycode: "0210018", city: "本巣市" },
      { citycode: "0210019", city: "郡上市" },
      { citycode: "0210020", city: "下呂市" },
      { citycode: "0210021", city: "海津市" },
      { citycode: "0210022", city: "羽島郡" },
      { citycode: "0210023", city: "養老郡" },
      { citycode: "0210024", city: "不破郡" },
      { citycode: "0210025", city: "安八郡" },
      { citycode: "0210026", city: "揖斐郡" },
      { citycode: "0210027", city: "本巣郡" },
      { citycode: "0210028", city: "加茂郡" },
      { citycode: "0210029", city: "可児郡" },
      { citycode: "0210030", city: "大野郡" }
    ]
  },
  静岡県: {
    id: "22",
    name: "静岡県",
    short: "静岡",
    kana: "シズオカケン",
    en: "shizuoka",
    city: [
      { citycode: "0220001", city: "静岡市葵区" },
      { citycode: "0220002", city: "静岡市駿河区" },
      { citycode: "0220003", city: "静岡市清水区" },
      { citycode: "0220004", city: "浜松市中区" },
      { citycode: "0220005", city: "浜松市東区" },
      { citycode: "0220006", city: "浜松市西区" },
      { citycode: "0220007", city: "浜松市南区" },
      { citycode: "0220008", city: "浜松市北区" },
      { citycode: "0220009", city: "浜松市浜北区" },
      { citycode: "0220010", city: "浜松市天竜区" },
      { citycode: "0220011", city: "沼津市" },
      { citycode: "0220012", city: "熱海市" },
      { citycode: "0220013", city: "三島市" },
      { citycode: "0220014", city: "富士宮市" },
      { citycode: "0220015", city: "伊東市" },
      { citycode: "0220016", city: "島田市" },
      { citycode: "0220017", city: "富士市" },
      { citycode: "0220018", city: "磐田市" },
      { citycode: "0220019", city: "焼津市" },
      { citycode: "0220020", city: "掛川市" },
      { citycode: "0220021", city: "藤枝市" },
      { citycode: "0220022", city: "御殿場市" },
      { citycode: "0220023", city: "袋井市" },
      { citycode: "0220024", city: "下田市" },
      { citycode: "0220025", city: "裾野市" },
      { citycode: "0220026", city: "湖西市" },
      { citycode: "0220027", city: "伊豆市" },
      { citycode: "0220028", city: "御前崎市" },
      { citycode: "0220029", city: "菊川市" },
      { citycode: "0220030", city: "伊豆の国市" },
      { citycode: "0220031", city: "牧之原市" },
      { citycode: "0220032", city: "賀茂郡" },
      { citycode: "0220033", city: "田方郡" },
      { citycode: "0220034", city: "駿東郡" },
      { citycode: "0220035", city: "榛原郡" },
      { citycode: "0220036", city: "周智郡" }
    ]
  },
  愛知県: {
    id: "23",
    name: "愛知県",
    short: "愛知",
    kana: "アイチケン",
    en: "aichi",
    city: [
      { citycode: "0230001", city: "名古屋市千種区" },
      { citycode: "0230002", city: "名古屋市東区" },
      { citycode: "0230003", city: "名古屋市北区" },
      { citycode: "0230004", city: "名古屋市西区" },
      { citycode: "0230005", city: "名古屋市中村区" },
      { citycode: "0230006", city: "名古屋市中区" },
      { citycode: "0230007", city: "名古屋市昭和区" },
      { citycode: "0230008", city: "名古屋市瑞穂区" },
      { citycode: "0230009", city: "名古屋市熱田区" },
      { citycode: "0230010", city: "名古屋市中川区" },
      { citycode: "0230011", city: "名古屋市港区" },
      { citycode: "0230012", city: "名古屋市南区" },
      { citycode: "0230013", city: "名古屋市守山区" },
      { citycode: "0230014", city: "名古屋市緑区" },
      { citycode: "0230015", city: "名古屋市名東区" },
      { citycode: "0230016", city: "名古屋市天白区" },
      { citycode: "0230017", city: "豊橋市" },
      { citycode: "0230018", city: "岡崎市" },
      { citycode: "0230019", city: "一宮市" },
      { citycode: "0230020", city: "瀬戸市" },
      { citycode: "0230021", city: "半田市" },
      { citycode: "0230022", city: "春日井市" },
      { citycode: "0230023", city: "豊川市" },
      { citycode: "0230024", city: "津島市" },
      { citycode: "0230025", city: "碧南市" },
      { citycode: "0230026", city: "刈谷市" },
      { citycode: "0230027", city: "豊田市" },
      { citycode: "0230028", city: "安城市" },
      { citycode: "0230029", city: "西尾市" },
      { citycode: "0230030", city: "蒲郡市" },
      { citycode: "0230031", city: "犬山市" },
      { citycode: "0230032", city: "常滑市" },
      { citycode: "0230033", city: "江南市" },
      { citycode: "0230034", city: "小牧市" },
      { citycode: "0230035", city: "稲沢市" },
      { citycode: "0230036", city: "新城市" },
      { citycode: "0230037", city: "東海市" },
      { citycode: "0230038", city: "大府市" },
      { citycode: "0230039", city: "知多市" },
      { citycode: "0230040", city: "知立市" },
      { citycode: "0230041", city: "尾張旭市" },
      { citycode: "0230042", city: "高浜市" },
      { citycode: "0230043", city: "岩倉市" },
      { citycode: "0230044", city: "豊明市" },
      { citycode: "0230045", city: "日進市" },
      { citycode: "0230046", city: "田原市" },
      { citycode: "0230047", city: "愛西市" },
      { citycode: "0230048", city: "清須市" },
      { citycode: "0230049", city: "北名古屋市" },
      { citycode: "0230050", city: "弥富市" },
      { citycode: "0230051", city: "みよし市" },
      { citycode: "0230052", city: "あま市" },
      { citycode: "0230053", city: "長久手市" },
      { citycode: "0230054", city: "愛知郡" },
      { citycode: "0230055", city: "西春日井郡" },
      { citycode: "0230056", city: "丹羽郡" },
      { citycode: "0230057", city: "海部郡" },
      { citycode: "0230058", city: "知多郡" },
      { citycode: "0230059", city: "額田郡" },
      { citycode: "0230060", city: "北設楽郡" }
    ]
  },
  三重県: {
    id: "24",
    name: "三重県",
    short: "三重",
    kana: "ミエケン",
    en: "mie",
    city: [
      { citycode: "0240001", city: "津市" },
      { citycode: "0240002", city: "四日市市" },
      { citycode: "0240003", city: "伊勢市" },
      { citycode: "0240004", city: "松阪市" },
      { citycode: "0240005", city: "桑名市" },
      { citycode: "0240006", city: "鈴鹿市" },
      { citycode: "0240007", city: "名張市" },
      { citycode: "0240008", city: "尾鷲市" },
      { citycode: "0240009", city: "亀山市" },
      { citycode: "0240010", city: "鳥羽市" },
      { citycode: "0240011", city: "熊野市" },
      { citycode: "0240012", city: "いなべ市" },
      { citycode: "0240013", city: "志摩市" },
      { citycode: "0240014", city: "伊賀市" },
      { citycode: "0240015", city: "桑名郡" },
      { citycode: "0240016", city: "員弁郡" },
      { citycode: "0240017", city: "三重郡" },
      { citycode: "0240018", city: "多気郡" },
      { citycode: "0240019", city: "度会郡" },
      { citycode: "0240020", city: "北牟婁郡" },
      { citycode: "0240021", city: "南牟婁郡" }
    ]
  },
  滋賀県: {
    id: "25",
    name: "滋賀県",
    short: "滋賀",
    kana: "シガケン",
    en: "shiga",
    city: [
      { citycode: "0250001", city: "大津市" },
      { citycode: "0250002", city: "彦根市" },
      { citycode: "0250003", city: "長浜市" },
      { citycode: "0250004", city: "近江八幡市" },
      { citycode: "0250005", city: "草津市" },
      { citycode: "0250006", city: "守山市" },
      { citycode: "0250007", city: "栗東市" },
      { citycode: "0250008", city: "甲賀市" },
      { citycode: "0250009", city: "野洲市" },
      { citycode: "0250010", city: "湖南市" },
      { citycode: "0250011", city: "高島市" },
      { citycode: "0250012", city: "東近江市" },
      { citycode: "0250013", city: "米原市" },
      { citycode: "0250014", city: "蒲生郡" },
      { citycode: "0250015", city: "愛知郡" },
      { citycode: "0250016", city: "犬上郡" }
    ]
  },
  京都府: {
    id: "26",
    name: "京都府",
    short: "京都",
    kana: "キョウトフ",
    en: "kyoto",
    city: [
      { citycode: "0260001", city: "京都市北区" },
      { citycode: "0260002", city: "京都市上京区" },
      { citycode: "0260003", city: "京都市左京区" },
      { citycode: "0260004", city: "京都市中京区" },
      { citycode: "0260005", city: "京都市東山区" },
      { citycode: "0260006", city: "京都市下京区" },
      { citycode: "0260007", city: "京都市南区" },
      { citycode: "0260008", city: "京都市右京区" },
      { citycode: "0260009", city: "京都市伏見区" },
      { citycode: "0260010", city: "京都市山科区" },
      { citycode: "0260011", city: "京都市西京区" },
      { citycode: "0260012", city: "福知山市" },
      { citycode: "0260013", city: "舞鶴市" },
      { citycode: "0260014", city: "綾部市" },
      { citycode: "0260015", city: "宇治市" },
      { citycode: "0260016", city: "宮津市" },
      { citycode: "0260017", city: "亀岡市" },
      { citycode: "0260018", city: "城陽市" },
      { citycode: "0260019", city: "向日市" },
      { citycode: "0260020", city: "長岡京市" },
      { citycode: "0260021", city: "八幡市" },
      { citycode: "0260022", city: "京田辺市" },
      { citycode: "0260023", city: "京丹後市" },
      { citycode: "0260024", city: "南丹市" },
      { citycode: "0260025", city: "木津川市" },
      { citycode: "0260026", city: "乙訓郡" },
      { citycode: "0260027", city: "久世郡" },
      { citycode: "0260028", city: "綴喜郡" },
      { citycode: "0260029", city: "相楽郡" },
      { citycode: "0260030", city: "船井郡" },
      { citycode: "0260031", city: "与謝郡" }
    ]
  },
  大阪府: {
    id: "27",
    name: "大阪府",
    short: "大阪",
    kana: "オオサカフ",
    en: "osaka",
    city: [
      { citycode: "0270001", city: "大阪市都島区" },
      { citycode: "0270002", city: "大阪市福島区" },
      { citycode: "0270003", city: "大阪市此花区" },
      { citycode: "0270004", city: "大阪市西区" },
      { citycode: "0270005", city: "大阪市港区" },
      { citycode: "0270006", city: "大阪市大正区" },
      { citycode: "0270007", city: "大阪市天王寺区" },
      { citycode: "0270008", city: "大阪市浪速区" },
      { citycode: "0270009", city: "大阪市西淀川区" },
      { citycode: "0270010", city: "大阪市東淀川区" },
      { citycode: "0270011", city: "大阪市東成区" },
      { citycode: "0270012", city: "大阪市生野区" },
      { citycode: "0270013", city: "大阪市旭区" },
      { citycode: "0270014", city: "大阪市城東区" },
      { citycode: "0270015", city: "大阪市阿倍野区" },
      { citycode: "0270016", city: "大阪市住吉区" },
      { citycode: "0270017", city: "大阪市東住吉区" },
      { citycode: "0270018", city: "大阪市西成区" },
      { citycode: "0270019", city: "大阪市淀川区" },
      { citycode: "0270020", city: "大阪市鶴見区" },
      { citycode: "0270021", city: "大阪市住之江区" },
      { citycode: "0270022", city: "大阪市平野区" },
      { citycode: "0270023", city: "大阪市北区" },
      { citycode: "0270024", city: "大阪市中央区" },
      { citycode: "0270025", city: "堺市堺区" },
      { citycode: "0270026", city: "堺市中区" },
      { citycode: "0270027", city: "堺市東区" },
      { citycode: "0270028", city: "堺市西区" },
      { citycode: "0270029", city: "堺市南区" },
      { citycode: "0270030", city: "堺市北区" },
      { citycode: "0270031", city: "堺市美原区" },
      { citycode: "0270032", city: "岸和田市" },
      { citycode: "0270033", city: "豊中市" },
      { citycode: "0270034", city: "池田市" },
      { citycode: "0270035", city: "吹田市" },
      { citycode: "0270036", city: "泉大津市" },
      { citycode: "0270037", city: "高槻市" },
      { citycode: "0270038", city: "貝塚市" },
      { citycode: "0270039", city: "守口市" },
      { citycode: "0270040", city: "枚方市" },
      { citycode: "0270041", city: "茨木市" },
      { citycode: "0270042", city: "八尾市" },
      { citycode: "0270043", city: "泉佐野市" },
      { citycode: "0270044", city: "富田林市" },
      { citycode: "0270045", city: "寝屋川市" },
      { citycode: "0270046", city: "河内長野市" },
      { citycode: "0270047", city: "松原市" },
      { citycode: "0270048", city: "大東市" },
      { citycode: "0270049", city: "和泉市" },
      { citycode: "0270050", city: "箕面市" },
      { citycode: "0270051", city: "柏原市" },
      { citycode: "0270052", city: "羽曳野市" },
      { citycode: "0270053", city: "門真市" },
      { citycode: "0270054", city: "摂津市" },
      { citycode: "0270055", city: "高石市" },
      { citycode: "0270056", city: "藤井寺市" },
      { citycode: "0270057", city: "東大阪市" },
      { citycode: "0270058", city: "泉南市" },
      { citycode: "0270059", city: "四條畷市" },
      { citycode: "0270060", city: "交野市" },
      { citycode: "0270061", city: "大阪狭山市" },
      { citycode: "0270062", city: "阪南市" },
      { citycode: "0270063", city: "三島郡" },
      { citycode: "0270064", city: "豊能郡" },
      { citycode: "0270065", city: "泉北郡" },
      { citycode: "0270066", city: "泉南郡" },
      { citycode: "0270067", city: "南河内郡" }
    ]
  },
  兵庫県: {
    id: "28",
    name: "兵庫県",
    short: "兵庫",
    kana: "ヒョウゴケン",
    en: "hyogo",
    city: [
      { citycode: "0280001", city: "神戸市東灘区" },
      { citycode: "0280002", city: "神戸市灘区" },
      { citycode: "0280003", city: "神戸市兵庫区" },
      { citycode: "0280004", city: "神戸市長田区" },
      { citycode: "0280005", city: "神戸市須磨区" },
      { citycode: "0280006", city: "神戸市垂水区" },
      { citycode: "0280007", city: "神戸市北区" },
      { citycode: "0280008", city: "神戸市中央区" },
      { citycode: "0280009", city: "神戸市西区" },
      { citycode: "0280010", city: "姫路市" },
      { citycode: "0280011", city: "尼崎市" },
      { citycode: "0280012", city: "明石市" },
      { citycode: "0280013", city: "西宮市" },
      { citycode: "0280014", city: "洲本市" },
      { citycode: "0280015", city: "芦屋市" },
      { citycode: "0280016", city: "伊丹市" },
      { citycode: "0280017", city: "相生市" },
      { citycode: "0280018", city: "豊岡市" },
      { citycode: "0280019", city: "加古川市" },
      { citycode: "0280020", city: "赤穂市" },
      { citycode: "0280021", city: "西脇市" },
      { citycode: "0280022", city: "宝塚市" },
      { citycode: "0280023", city: "三木市" },
      { citycode: "0280024", city: "高砂市" },
      { citycode: "0280025", city: "川西市" },
      { citycode: "0280026", city: "小野市" },
      { citycode: "0280027", city: "三田市" },
      { citycode: "0280028", city: "加西市" },
      { citycode: "0280029", city: "篠山市" },
      { citycode: "0280030", city: "養父市" },
      { citycode: "0280031", city: "丹波市" },
      { citycode: "0280032", city: "南あわじ市" },
      { citycode: "0280033", city: "朝来市" },
      { citycode: "0280034", city: "淡路市" },
      { citycode: "0280035", city: "宍粟市" },
      { citycode: "0280036", city: "加東市" },
      { citycode: "0280037", city: "たつの市" },
      { citycode: "0280038", city: "川辺郡" },
      { citycode: "0280039", city: "多可郡" },
      { citycode: "0280040", city: "加古郡" },
      { citycode: "0280041", city: "神崎郡" },
      { citycode: "0280042", city: "揖保郡" },
      { citycode: "0280043", city: "赤穂郡" },
      { citycode: "0280044", city: "佐用郡" },
      { citycode: "0280045", city: "美方郡" }
    ]
  },
  奈良県: {
    id: "29",
    name: "奈良県",
    short: "奈良",
    kana: "ナラケン",
    en: "nara",
    city: [
      { citycode: "0290001", city: "奈良市" },
      { citycode: "0290002", city: "大和高田市" },
      { citycode: "0290003", city: "大和郡山市" },
      { citycode: "0290004", city: "天理市" },
      { citycode: "0290005", city: "橿原市" },
      { citycode: "0290006", city: "桜井市" },
      { citycode: "0290007", city: "五條市" },
      { citycode: "0290008", city: "御所市" },
      { citycode: "0290009", city: "生駒市" },
      { citycode: "0290010", city: "香芝市" },
      { citycode: "0290011", city: "葛城市" },
      { citycode: "0290012", city: "宇陀市" },
      { citycode: "0290013", city: "山辺郡" },
      { citycode: "0290014", city: "生駒郡" },
      { citycode: "0290015", city: "磯城郡" },
      { citycode: "0290016", city: "宇陀郡" },
      { citycode: "0290017", city: "高市郡" },
      { citycode: "0290018", city: "北葛城郡" },
      { citycode: "0290019", city: "吉野郡" }
    ]
  },
  和歌山県: {
    id: "30",
    name: "和歌山県",
    short: "和歌山",
    kana: "ワカヤマケン",
    en: "wakayama",
    city: [
      { citycode: "0300001", city: "和歌山市" },
      { citycode: "0300002", city: "海南市" },
      { citycode: "0300003", city: "橋本市" },
      { citycode: "0300004", city: "有田市" },
      { citycode: "0300005", city: "御坊市" },
      { citycode: "0300006", city: "田辺市" },
      { citycode: "0300007", city: "新宮市" },
      { citycode: "0300008", city: "紀の川市" },
      { citycode: "0300009", city: "岩出市" },
      { citycode: "0300010", city: "海草郡" },
      { citycode: "0300011", city: "伊都郡" },
      { citycode: "0300012", city: "有田郡" },
      { citycode: "0300013", city: "日高郡" },
      { citycode: "0300014", city: "西牟婁郡" },
      { citycode: "0300015", city: "東牟婁郡" }
    ]
  },
  鳥取県: {
    id: "31",
    name: "鳥取県",
    short: "鳥取",
    kana: "トットリケン",
    en: "tottori",
    city: [
      { citycode: "0310001", city: "鳥取市" },
      { citycode: "0310002", city: "米子市" },
      { citycode: "0310003", city: "倉吉市" },
      { citycode: "0310004", city: "境港市" },
      { citycode: "0310005", city: "岩美郡" },
      { citycode: "0310006", city: "八頭郡" },
      { citycode: "0310007", city: "東伯郡" },
      { citycode: "0310008", city: "西伯郡" },
      { citycode: "0310009", city: "日野郡" }
    ]
  },
  島根県: {
    id: "32",
    name: "島根県",
    short: "島根",
    kana: "シマネケン",
    en: "shimane",
    city: [
      { citycode: "0320001", city: "松江市" },
      { citycode: "0320002", city: "浜田市" },
      { citycode: "0320003", city: "出雲市" },
      { citycode: "0320004", city: "益田市" },
      { citycode: "0320005", city: "大田市" },
      { citycode: "0320006", city: "安来市" },
      { citycode: "0320007", city: "江津市" },
      { citycode: "0320008", city: "雲南市" },
      { citycode: "0320009", city: "仁多郡" },
      { citycode: "0320010", city: "飯石郡" },
      { citycode: "0320011", city: "邑智郡" },
      { citycode: "0320012", city: "鹿足郡" },
      { citycode: "0320013", city: "隠岐郡" }
    ]
  },
  岡山県: {
    id: "33",
    name: "岡山県",
    short: "岡山",
    kana: "オカヤマケン",
    en: "okayama",
    city: [
      { citycode: "0330001", city: "岡山市北区" },
      { citycode: "0330002", city: "岡山市中区" },
      { citycode: "0330003", city: "岡山市東区" },
      { citycode: "0330004", city: "岡山市南区" },
      { citycode: "0330005", city: "倉敷市" },
      { citycode: "0330006", city: "津山市" },
      { citycode: "0330007", city: "玉野市" },
      { citycode: "0330008", city: "笠岡市" },
      { citycode: "0330009", city: "井原市" },
      { citycode: "0330010", city: "総社市" },
      { citycode: "0330011", city: "高梁市" },
      { citycode: "0330012", city: "新見市" },
      { citycode: "0330013", city: "備前市" },
      { citycode: "0330014", city: "瀬戸内市" },
      { citycode: "0330015", city: "赤磐市" },
      { citycode: "0330016", city: "真庭市" },
      { citycode: "0330017", city: "美作市" },
      { citycode: "0330018", city: "浅口市" },
      { citycode: "0330019", city: "和気郡" },
      { citycode: "0330020", city: "都窪郡" },
      { citycode: "0330021", city: "浅口郡" },
      { citycode: "0330022", city: "小田郡" },
      { citycode: "0330023", city: "苫田郡" },
      { citycode: "0330024", city: "勝田郡" },
      { citycode: "0330025", city: "英田郡" },
      { citycode: "0330026", city: "久米郡" },
      { citycode: "0330027", city: "加賀郡" }
    ]
  },
  広島県: {
    id: "34",
    name: "広島県",
    short: "広島",
    kana: "ヒロシマケン",
    en: "hiroshima",
    city: [
      { citycode: "0340001", city: "広島市中区" },
      { citycode: "0340002", city: "広島市東区" },
      { citycode: "0340003", city: "広島市南区" },
      { citycode: "0340004", city: "広島市西区" },
      { citycode: "0340005", city: "広島市安佐南区" },
      { citycode: "0340006", city: "広島市安佐北区" },
      { citycode: "0340007", city: "広島市安芸区" },
      { citycode: "0340008", city: "広島市佐伯区" },
      { citycode: "0340009", city: "呉市" },
      { citycode: "0340010", city: "竹原市" },
      { citycode: "0340011", city: "三原市" },
      { citycode: "0340012", city: "尾道市" },
      { citycode: "0340013", city: "福山市" },
      { citycode: "0340014", city: "府中市" },
      { citycode: "0340015", city: "三次市" },
      { citycode: "0340016", city: "庄原市" },
      { citycode: "0340017", city: "大竹市" },
      { citycode: "0340018", city: "東広島市" },
      { citycode: "0340019", city: "廿日市市" },
      { citycode: "0340020", city: "安芸高田市" },
      { citycode: "0340021", city: "江田島市" },
      { citycode: "0340022", city: "安芸郡" },
      { citycode: "0340023", city: "山県郡" },
      { citycode: "0340024", city: "豊田郡" },
      { citycode: "0340025", city: "世羅郡" },
      { citycode: "0340026", city: "神石郡" }
    ]
  },
  山口県: {
    id: "35",
    name: "山口県",
    short: "山口",
    kana: "ヤマグチケン",
    en: "yamaguchi",
    city: [
      { citycode: "0350001", city: "下関市" },
      { citycode: "0350002", city: "宇部市" },
      { citycode: "0350003", city: "山口市" },
      { citycode: "0350004", city: "萩市" },
      { citycode: "0350005", city: "防府市" },
      { citycode: "0350006", city: "下松市" },
      { citycode: "0350007", city: "岩国市" },
      { citycode: "0350008", city: "光市" },
      { citycode: "0350009", city: "長門市" },
      { citycode: "0350010", city: "柳井市" },
      { citycode: "0350011", city: "美祢市" },
      { citycode: "0350012", city: "周南市" },
      { citycode: "0350013", city: "山陽小野田市" },
      { citycode: "0350014", city: "大島郡" },
      { citycode: "0350015", city: "玖珂郡" },
      { citycode: "0350016", city: "熊毛郡" },
      { citycode: "0350017", city: "阿武郡" }
    ]
  },
  徳島県: {
    id: "36",
    name: "徳島県",
    short: "徳島",
    kana: "トクシマケン",
    en: "tokushima",
    city: [
      { citycode: "0360001", city: "徳島市" },
      { citycode: "0360002", city: "鳴門市" },
      { citycode: "0360003", city: "小松島市" },
      { citycode: "0360004", city: "阿南市" },
      { citycode: "0360005", city: "吉野川市" },
      { citycode: "0360006", city: "阿波市" },
      { citycode: "0360007", city: "美馬市" },
      { citycode: "0360008", city: "三好市" },
      { citycode: "0360009", city: "勝浦郡" },
      { citycode: "0360010", city: "名東郡" },
      { citycode: "0360011", city: "名西郡" },
      { citycode: "0360012", city: "那賀郡" },
      { citycode: "0360013", city: "海部郡" },
      { citycode: "0360014", city: "板野郡" },
      { citycode: "0360015", city: "美馬郡" },
      { citycode: "0360016", city: "三好郡" }
    ]
  },
  香川県: {
    id: "37",
    name: "香川県",
    short: "香川",
    kana: "カガワケン",
    en: "kagawa",
    city: [
      { citycode: "0370001", city: "高松市" },
      { citycode: "0370002", city: "丸亀市" },
      { citycode: "0370003", city: "坂出市" },
      { citycode: "0370004", city: "善通寺市" },
      { citycode: "0370005", city: "観音寺市" },
      { citycode: "0370006", city: "さぬき市" },
      { citycode: "0370007", city: "東かがわ市" },
      { citycode: "0370008", city: "三豊市" },
      { citycode: "0370009", city: "小豆郡" },
      { citycode: "0370010", city: "小豆郡" },
      { citycode: "0370011", city: "木田郡" },
      { citycode: "0370012", city: "綾歌郡" },
      { citycode: "0370013", city: "仲多度" }
    ]
  },
  愛媛県: {
    id: "38",
    name: "愛媛県",
    short: "愛媛",
    kana: "エヒメケン",
    en: "ehime",
    city: [
      { citycode: "0380001", city: "松山市" },
      { citycode: "0380002", city: "今治市" },
      { citycode: "0380003", city: "宇和島市" },
      { citycode: "0380004", city: "八幡浜市" },
      { citycode: "0380005", city: "新居浜市" },
      { citycode: "0380006", city: "西条市" },
      { citycode: "0380007", city: "大洲市" },
      { citycode: "0380008", city: "伊予市" },
      { citycode: "0380009", city: "四国中央市" },
      { citycode: "0380010", city: "西予市" },
      { citycode: "0380011", city: "東温市" },
      { citycode: "0380012", city: "越智郡" },
      { citycode: "0380013", city: "上浮穴郡" },
      { citycode: "0380014", city: "伊予郡" },
      { citycode: "0380015", city: "喜多郡" },
      { citycode: "0380016", city: "西宇和郡" },
      { citycode: "0380017", city: "北宇和郡" },
      { citycode: "0380018", city: "南宇和郡" }
    ]
  },
  高知県: {
    id: "39",
    name: "高知県",
    short: "高知",
    kana: "コウチケン",
    en: "kochi",
    city: [
      { citycode: "0390001", city: "高知市" },
      { citycode: "0390002", city: "室戸市" },
      { citycode: "0390003", city: "安芸市" },
      { citycode: "0390004", city: "南国市" },
      { citycode: "0390005", city: "土佐市" },
      { citycode: "0390006", city: "須崎市" },
      { citycode: "0390007", city: "宿毛市" },
      { citycode: "0390008", city: "土佐清水市" },
      { citycode: "0390009", city: "四万十市" },
      { citycode: "0390010", city: "香南市" },
      { citycode: "0390011", city: "香美市" },
      { citycode: "0390012", city: "安芸郡" },
      { citycode: "0390013", city: "長岡郡" },
      { citycode: "0390014", city: "土佐郡" },
      { citycode: "0390015", city: "吾川郡" },
      { citycode: "0390016", city: "高岡郡" },
      { citycode: "0390017", city: "幡多郡" }
    ]
  },
  福岡県: {
    id: "40",
    name: "福岡県",
    short: "福岡",
    kana: "フクオカケン",
    en: "fukuoka",
    city: [
      { citycode: "0400001", city: "北九州市門司区" },
      { citycode: "0400002", city: "北九州市若松区" },
      { citycode: "0400003", city: "北九州市戸畑区" },
      { citycode: "0400004", city: "北九州市小倉北区" },
      { citycode: "0400005", city: "北九州市小倉南区" },
      { citycode: "0400006", city: "北九州市八幡東区" },
      { citycode: "0400007", city: "北九州市八幡西区" },
      { citycode: "0400008", city: "福岡市東区" },
      { citycode: "0400009", city: "福岡市博多区" },
      { citycode: "0400010", city: "福岡市中央区" },
      { citycode: "0400011", city: "福岡市南区" },
      { citycode: "0400012", city: "福岡市西区" },
      { citycode: "0400013", city: "福岡市城南区" },
      { citycode: "0400014", city: "福岡市早良区" },
      { citycode: "0400015", city: "大牟田市" },
      { citycode: "0400016", city: "久留米市" },
      { citycode: "0400017", city: "直方市" },
      { citycode: "0400018", city: "飯塚市" },
      { citycode: "0400019", city: "田川市" },
      { citycode: "0400020", city: "柳川市" },
      { citycode: "0400021", city: "八女市" },
      { citycode: "0400022", city: "筑後市" },
      { citycode: "0400023", city: "大川市" },
      { citycode: "0400024", city: "行橋市" },
      { citycode: "0400025", city: "豊前市" },
      { citycode: "0400026", city: "中間市" },
      { citycode: "0400027", city: "小郡市" },
      { citycode: "0400028", city: "筑紫野市" },
      { citycode: "0400029", city: "春日市" },
      { citycode: "0400030", city: "大野城市" },
      { citycode: "0400031", city: "宗像市" },
      { citycode: "0400032", city: "太宰府市" },
      { citycode: "0400033", city: "古賀市" },
      { citycode: "0400034", city: "福津市" },
      { citycode: "0400035", city: "うきは市" },
      { citycode: "0400036", city: "宮若市" },
      { citycode: "0400037", city: "嘉麻市" },
      { citycode: "0400038", city: "朝倉市" },
      { citycode: "0400039", city: "みやま市" },
      { citycode: "0400040", city: "糸島市" },
      { citycode: "0400041", city: "筑紫郡" },
      { citycode: "0400042", city: "糟屋郡" },
      { citycode: "0400043", city: "遠賀郡" },
      { citycode: "0400044", city: "鞍手郡" },
      { citycode: "0400045", city: "嘉穂郡" },
      { citycode: "0400046", city: "朝倉郡" },
      { citycode: "0400047", city: "三井郡" },
      { citycode: "0400048", city: "三潴郡" },
      { citycode: "0400049", city: "八女郡" },
      { citycode: "0400050", city: "田川郡" },
      { citycode: "0400051", city: "京都郡" },
      { citycode: "0400052", city: "築上郡" }
    ]
  },
  佐賀県: {
    id: "41",
    name: "佐賀県",
    short: "佐賀",
    kana: "サガケン",
    en: "saga",
    city: [
      { citycode: "0410001", city: "佐賀市" },
      { citycode: "0410002", city: "唐津市" },
      { citycode: "0410003", city: "鳥栖市" },
      { citycode: "0410004", city: "多久市" },
      { citycode: "0410005", city: "伊万里市" },
      { citycode: "0410006", city: "武雄市" },
      { citycode: "0410007", city: "鹿島市" },
      { citycode: "0410008", city: "小城市" },
      { citycode: "0410009", city: "嬉野市" },
      { citycode: "0410010", city: "神埼市" },
      { citycode: "0410011", city: "神埼郡" },
      { citycode: "0410012", city: "三養基郡" },
      { citycode: "0410013", city: "東松浦郡" },
      { citycode: "0410014", city: "西松浦郡" },
      { citycode: "0410015", city: "杵島郡" },
      { citycode: "0410016", city: "藤津郡" }
    ]
  },
  長崎県: {
    id: "42",
    name: "長崎県",
    short: "長崎",
    kana: "ナガサキケン",
    en: "nagasaki",
    city: [
      { citycode: "0420001", city: "長崎市" },
      { citycode: "0420002", city: "佐世保市" },
      { citycode: "0420003", city: "島原市" },
      { citycode: "0420004", city: "諫早市" },
      { citycode: "0420005", city: "大村市" },
      { citycode: "0420006", city: "平戸市" },
      { citycode: "0420007", city: "松浦市" },
      { citycode: "0420008", city: "対馬市" },
      { citycode: "0420009", city: "壱岐市" },
      { citycode: "0420010", city: "五島市" },
      { citycode: "0420011", city: "西海市" },
      { citycode: "0420012", city: "雲仙市" },
      { citycode: "0420013", city: "南島原市" },
      { citycode: "0420014", city: "西彼杵郡" },
      { citycode: "0420015", city: "東彼杵郡" },
      { citycode: "0420016", city: "北松浦郡" },
      { citycode: "0420017", city: "南松浦郡" }
    ]
  },
  熊本県: {
    id: "43",
    name: "熊本県",
    short: "熊本",
    kana: "クマモトケン",
    en: "kumamoto",
    city: [
      { citycode: "0430001", city: "熊本市中央区" },
      { citycode: "0430002", city: "熊本市東区" },
      { citycode: "0430003", city: "熊本市西区" },
      { citycode: "0430004", city: "熊本市南区" },
      { citycode: "0430005", city: "熊本市北区" },
      { citycode: "0430006", city: "八代市" },
      { citycode: "0430007", city: "人吉市" },
      { citycode: "0430008", city: "荒尾市" },
      { citycode: "0430009", city: "水俣市" },
      { citycode: "0430010", city: "玉名市" },
      { citycode: "0430011", city: "山鹿市" },
      { citycode: "0430012", city: "菊池市" },
      { citycode: "0430013", city: "宇土市" },
      { citycode: "0430014", city: "上天草市" },
      { citycode: "0430015", city: "宇城市" },
      { citycode: "0430016", city: "阿蘇市" },
      { citycode: "0430017", city: "天草市" },
      { citycode: "0430018", city: "合志市" },
      { citycode: "0430019", city: "下益城郡" },
      { citycode: "0430020", city: "玉名郡" },
      { citycode: "0430021", city: "菊池郡" },
      { citycode: "0430022", city: "阿蘇郡" },
      { citycode: "0430023", city: "上益城郡" },
      { citycode: "0430024", city: "八代郡" },
      { citycode: "0430025", city: "葦北郡" },
      { citycode: "0430026", city: "球磨郡" },
      { citycode: "0430027", city: "天草郡" }
    ]
  },
  大分県: {
    id: "44",
    name: "大分県",
    short: "大分",
    kana: "オオイタケン",
    en: "oita",
    city: [
      { citycode: "0440001", city: "大分市" },
      { citycode: "0440002", city: "別府市" },
      { citycode: "0440003", city: "中津市" },
      { citycode: "0440004", city: "日田市" },
      { citycode: "0440005", city: "佐伯市" },
      { citycode: "0440006", city: "臼杵市" },
      { citycode: "0440007", city: "津久見市" },
      { citycode: "0440008", city: "竹田市" },
      { citycode: "0440009", city: "豊後高田市" },
      { citycode: "0440010", city: "杵築市" },
      { citycode: "0440011", city: "宇佐市" },
      { citycode: "0440012", city: "豊後大野市" },
      { citycode: "0440013", city: "由布市" },
      { citycode: "0440014", city: "国東市" },
      { citycode: "0440015", city: "速見郡" },
      { citycode: "0440016", city: "玖珠郡" }
    ]
  },
  宮崎県: {
    id: "45",
    name: "宮崎県",
    short: "宮崎",
    kana: "ミヤザキケン",
    en: "miyazaki",
    city: [
      { citycode: "0450001", city: "宮崎市" },
      { citycode: "0450002", city: "都城市" },
      { citycode: "0450003", city: "延岡市" },
      { citycode: "0450004", city: "日南市" },
      { citycode: "0450005", city: "小林市" },
      { citycode: "0450006", city: "日向市" },
      { citycode: "0450007", city: "串間市" },
      { citycode: "0450008", city: "西都市" },
      { citycode: "0450009", city: "えびの市" },
      { citycode: "0450010", city: "北諸県郡" },
      { citycode: "0450011", city: "西諸県郡" },
      { citycode: "0450012", city: "東諸県郡" },
      { citycode: "0450013", city: "児湯郡都" },
      { citycode: "0450014", city: "東臼杵郡" },
      { citycode: "0450015", city: "西臼杵郡" }
    ]
  },
  鹿児島県: {
    id: "46",
    name: "鹿児島県",
    short: "鹿児島",
    kana: "カゴシマケン",
    en: "kagoshima",
    city: [
      { citycode: "0460001", city: "鹿児島市" },
      { citycode: "0460002", city: "鹿屋市" },
      { citycode: "0460003", city: "枕崎市" },
      { citycode: "0460004", city: "阿久根市" },
      { citycode: "0460005", city: "出水市" },
      { citycode: "0460006", city: "指宿市" },
      { citycode: "0460007", city: "西之表市" },
      { citycode: "0460008", city: "垂水市" },
      { citycode: "0460009", city: "薩摩川内市" },
      { citycode: "0460010", city: "日置市" },
      { citycode: "0460011", city: "曽於市" },
      { citycode: "0460012", city: "霧島市" },
      { citycode: "0460013", city: "いちき串木野市" },
      { citycode: "0460014", city: "南さつま市" },
      { citycode: "0460015", city: "志布志市" },
      { citycode: "0460016", city: "奄美市" },
      { citycode: "0460017", city: "南九州市" },
      { citycode: "0460018", city: "伊佐市" },
      { citycode: "0460019", city: "姶良市" },
      { citycode: "0460020", city: "鹿児島郡" },
      { citycode: "0460021", city: "薩摩郡" },
      { citycode: "0460022", city: "出水郡" },
      { citycode: "0460023", city: "姶良郡" },
      { citycode: "0460024", city: "曽於郡" },
      { citycode: "0460025", city: "肝属郡" },
      { citycode: "0460026", city: "熊毛郡" },
      { citycode: "0460027", city: "大島郡" }
    ]
  },
  沖縄県: {
    id: "47",
    name: "沖縄県",
    short: "沖縄",
    kana: "オキナワケン",
    en: "okinawa",
    city: [
      { citycode: "0470001", city: "那覇市" },
      { citycode: "0470002", city: "宜野湾市" },
      { citycode: "0470003", city: "石垣市" },
      { citycode: "0470004", city: "浦添市" },
      { citycode: "0470005", city: "名護市" },
      { citycode: "0470006", city: "糸満市" },
      { citycode: "0470007", city: "沖縄市" },
      { citycode: "0470008", city: "豊見城市" },
      { citycode: "0470009", city: "うるま市" },
      { citycode: "0470010", city: "宮古島市" },
      { citycode: "0470011", city: "南城市" },
      { citycode: "0470012", city: "国頭郡" },
      { citycode: "0470013", city: "中頭郡" },
      { citycode: "0470014", city: "島尻郡" },
      { citycode: "0470015", city: "宮古郡" },
      { citycode: "0470016", city: "八重山郡" }
    ]
  }
};

const SelectCity = (props) => {
  const { prefectures, ...other } = props;
  const [city, set_city] = React.useState(props.value);

  useEffect(() => {
    set_city(props.value);
  }, [props.value]);

  return (
    <>
      {prefectures && prefectures != "未選択" && (
        <TextField
          style={{ width: "100%", marginBottom: "1rem" }}
          id="city"
          select
          name="city"
          label="市町村"
          fullWidth
          variant="outlined"
          SelectProps={{
            native: true
          }}
          {...other}
          value={city}
        >
          <option value={"未選択"}>未選択</option>

          {city_List[prefectures]?.city.map((cities, index) => (
            <option key={index} value={cities.city}>
              {cities.city}
            </option>
          ))}
        </TextField>
      )}
    </>
  );
};

export default SelectCity;
