import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import { InputMail } from "~CJS/atoms/TextFields";

const LoginMailForm = (props) => {
    const { HandleSubmit, ...other } = props;

    return (
        <form
            noValidate
            autoComplete="off"
            style={{ width: "100%" }}
            onSubmit={HandleSubmit}
            name="mail_form"
        >
            <FormControl fullWidth noValidate autoComplete="off">
                <InputMail required />
                <Button variant="contained" color="secondary" type="submit">
                    送信
                </Button>
            </FormControl>
        </form>
    );
};

export default LoginMailForm;
