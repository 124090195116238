import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ChatIcon from "@material-ui/icons/Chat";
import { LinkButton } from "~CJS/atoms/LinkButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { useDispatch } from "react-redux";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TalentReviewDialog from "./TalentReviewDialog";
import { ReviewTalent, audition_seet_set } from "../../stores/TALENTBANK/AuditionSeet";

const LINES_TO_SHOW = 3;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "500px",
        boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
        margin: "0.1rem",
        marginBottom: "0.2rem",
        marginTop: "0.2rem",
    },
    MuiCardHeader: {
        paddingBottom: "0rem",
        marginBottom: "0rem",
    },
    MuiCardContent: {
        paddingBottom: "0rem",
        marginBottom: "0rem",
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
        paddingBottom: "0rem",
        marginBottom: "0rem",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    avatar: {
        backgroundColor: red[500],
    },
    Accordion: {
        padding: "0rem",
        margin: "0rem",
        boxShadow: "none",
        paddingBottom: "0rem",
        marginBottom: "0rem",
    },
    multiLineEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": LINES_TO_SHOW,
        "-webkit-box-orient": "vertical",
    },
}));

const AuditionSeetCard = (props) => {
    const {
        audition_id,
        talent_id,
        company_id,
        offer_id,
        audition_pr,
        talent_name,
        image_small,
        review,
        review_comment,
        onAdoption,
        onRepudiation,
        onClear,
        status,
        jobDate,
        reason,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [reviewed, setReviewed] = useState(review !== null);
    const [openReview, setOpenReview] = useState(false);
    const [reviewValue, setReviewValue] = useState(review || 3);
    const [reviewCommentValue, setreviewCommentValue] = useState(review_comment || "");

    let nextDayOfJob = new Date(jobDate.replace("/", "/"));
    nextDayOfJob.setDate(nextDayOfJob.getDate() + 1);
    const now = new Date();

    // 評価ダイアログを開く
    const openReviewDialog = () => {
        setOpenReview(true);
    };
    // 評価ダイアログを閉じる
    const closeReviewDialog = () => {
        setOpenReview(false);
    };
    // レビュースライダーの値変更ハンドラー
    const handleChangeReview = (e, value) => {
        setReviewValue(value);
    };
    // 評価を送信
    const sendReview = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }
            return value;
        };

        const params = {
            audition_id: audition_id,
            review: getValue("review"),
            review_comment: getValue("review_comment"),
        };

        setreviewCommentValue(getValue("review_comment"));

        dispatch(ReviewTalent(params)).then((res) => {
            if (res.payload?.result === "OK") {
                setReviewed(true);
                setOpenReview(false);
            }
        });
    };

    return (
        <React.Fragment>
            <Card
                className={classes.root}
                style={{
                    background:
                        (status == "0" && "#fff") || (status == "1" && "#d1ffff") || (status == "2" && "#dcdcdc"),
                }}
            >
                <CardHeader
                    className={classes.MuiCardHeader}
                    avatar={
                        <Avatar
                            component={Link}
                            to={`/common/TalentDetail/${talent_id}`}
                            aria-label="recipe"
                            src={image_small}
                            className={classes.avatar}
                        >
                            R
                        </Avatar>
                    }
                    action={
                        <LinkButton
                            label={
                                <>
                                    トーク <ChatIcon style={{ fontSize: "1.8rem" }} />
                                </>
                            }
                            variant="outlined"
                            to={`/chat/Chat?talent_id=${talent_id}&&company_id=${company_id}`}
                        />
                    }
                    title={
                        <Link style={{ fontSize: "1.4rem", color: "black" }} to={`/common/TalentDetail/${talent_id}`}>
                            {talent_name}
                        </Link>
                    }
                    // subheader="採用回数 0 回"
                />
                <CardContent className={classes.MuiCardContent}>
                    自己PR
                    <Accordion className={classes.Accordion} square>
                        <AccordionSummary className={classes.Accordion} style={{ display: "unset" }}>
                            <Typography className={classes.multiLineEllipsis}>{audition_pr}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {audition_pr}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    {reason && (
                        <>
                            {status == "0" && "未判定"}
                            {status == "1" && "採用"}
                            {status == "2" && "不採用"}
                            理由
                            <Grid container style={{ paddingTop: "0.5rem" }}>
                                理由{reason}
                            </Grid>
                        </>
                    )}
                </CardContent>
                <CardActions disableSpacing>
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                        {now < nextDayOfJob && (
                            <Grid item>
                                <Button
                                    style={{ border: "none" }}
                                    onClick={() => onRepudiation(talent_id, offer_id)}
                                    color="secondary"
                                >
                                    不採用
                                </Button>
                                <Button
                                    onClick={() => onAdoption(talent_id, offer_id)}
                                    variant="contained"
                                    color="primary"
                                    style={{ border: "orange" }}
                                >
                                    採用
                                </Button>
                                <Button
                                    style={{ border: "none" }}
                                    onClick={() => onClear(talent_id, offer_id)}
                                    color="secondary"
                                >
                                    判定クリア
                                </Button>
                            </Grid>
                        )}

                        {now >= nextDayOfJob && status == "1" && (
                            <Grid item>
                                <Button
                                    variant={reviewed ? "outlined" : "contained"}
                                    color="secondary"
                                    style={{ border: "1px solid rgba(255, 87, 34, 0.5)" }}
                                    onClick={openReviewDialog}
                                >
                                    {reviewed ? "評価済み" : "評価する"}
                                </Button>
                            </Grid>
                        )}
                        <Grid item style={{ marginLeft: "auto" }}>
                            {status == "0" && "未判定"}
                            {status == "1" && "採用"}
                            {status == "2" && "不採用"}
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
            <TalentReviewDialog
                open={openReview}
                value={reviewValue}
                onChange={handleChangeReview}
                onClose={closeReviewDialog}
                onClickSend={sendReview}
                review_comment={reviewCommentValue}
            />
        </React.Fragment>
    );
};

export default AuditionSeetCard;
