import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    company_detail: {},
};

export const GetCompanyDetail = createAsyncThunk("Company/GetCompanyDetail", async (arg, thunkAPI) => {
    const url = "/api/company_detail";

    const params = arg;
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: { ...headers },
        });
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "Company",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        company_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        // 企業詳細取得
        [GetCompanyDetail.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (resdata.result == "1") {
                state.company_detail = resdata.company_info;
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, company_set } = slice.actions;
//
// message
// timestamp
// photoURL
// displayName
// avatarDisp
//
//
//
//
