import React from "react";
import { InputMaxLength, InputDate, InputTime } from "~CJS/atoms/TextFields";
import InputImagePreview from "~CJS/atoms/InputImagePreview";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { SelectTimeRange } from "~CJS/atoms/SelectRange";
import { InputStyle } from "~/components/AppStyle";

const theme = createTheme({ ...InputStyle });

const OfferDetailInput = (props) => {
    const { disabled, data, Create, ...other } = props;
    //Create は新規作成フラグ
    const offer_title = data?.offer_title ? data.offer_title : "";
    const guarantee = data?.guarantee ? data.guarantee : "";
    const recruitment_count = data?.recruitment_count;
    const job_start_time = data?.job_start_time;
    const job_end_time = data?.job_end_time;
    const job_detail = data?.job_detail ? data.job_detail : "";
    const image_big = data?.image_big;
    const image = data?.image_small;
    const job_date = data?.job_date;
    const city = data?.city;
    const prefectures = data?.prefectures;
    const entry_close = data?.entry_close;

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <InputImagePreview
                    comment={false}
                    height={200}
                    image_big={image_big}
                    image={image}
                    disabled={disabled}
                />
                <InputMaxLength
                    label="案件タイトル"
                    name="offer_title"
                    inputProps={{ maxLength: 32 }}
                    disabled={disabled}
                    value={offer_title}
                    required
                />

                <InputDate
                    label="案件日程"
                    name="job_date"
                    defaultValue="tomorrow"
                    disabled={disabled}
                    value={job_date}
                    required
                />
                <SelectTimeRange
                    start_name="job_start_time"
                    end_name="job_end_time"
                    start_label="案件開始予定時間"
                    end_label="案件終了予定時間"
                    disabled={disabled}
                    start={job_start_time}
                    end={job_end_time}
                    noset
                />

                <SelectPrefectures disabled={disabled} value={prefectures} required city={{ cityValue: city }} />

                <InputMaxLength
                    label="お仕事詳細"
                    multiline
                    name="job_detail"
                    inputProps={{ maxLength: 50000 }}
                    disabled={disabled}
                    value={job_detail}
                />

                <InputMaxLength
                    label="採用人数"
                    name="recruitment_count"
                    inputProps={{ maxLength: 10 }}
                    disabled={disabled}
                    value={recruitment_count}
                    required
                />

                <InputMaxLength
                    name="guarantee"
                    label="ギャランティー"
                    inputProps={{ maxLength: 254 }}
                    disabled={disabled}
                    value={guarantee}
                    multiline
                    required
                />

                <InputTime
                    label="募集終了"
                    name="entry_close"
                    defaultValue="after_week"
                    disabled={disabled}
                    value={entry_close}
                    required
                />
            </ThemeProvider>
        </React.Fragment>
    );
};

export default OfferDetailInput;
