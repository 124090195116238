import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory, useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import TalentSearchCriteria from "~/components/block/TalentSearchCriteria";
import TalentCard from "~CJS/block/TalentCard";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { initialize, talent_set, GetTalentList } from "~stores/TALENTBANK/Talent";

const TalentSearch = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const TalentState = useSelector((state) => state.Talent);
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const [first, set_first] = React.useState(true);

    const returnTop = () => {
        document.getElementById("talent-serch-item-glid").scrollIntoView({
            behavior: "smooth",
        });
    };

    const StartSearch = (criteria) => {
        dispatch(GetTalentList(criteria)).then((res) => {
            // 通信が成功した時
            if (res.payload?.serch_info) {
                // 検索条件をクエリストリングに反映する
                const queryString = new URLSearchParams(res.meta.arg);
                queryString.delete("favorite"); // 別のところで設定するのでここでは消す
                const nextURL = query.get("favorite")
                    ? `/common/TalentSearch?favorite=true&${queryString}`
                    : `/common/TalentSearch?${queryString}`;
                history.push(nextURL);

                if (first) {
                    set_first(false);
                } else {
                    returnTop();
                }
            }
        });
    };

    return (
        <React.Fragment>
            <Container >
                <ButtonNavi left_label="TOP" left_link="/" right_label="" right_link="" />

                {query.get("favorite") === "true" ? (
                    <Typography variant="h1">お気に入りタレント</Typography>
                ) : (
                    <Typography variant="h1">タレントをさがす</Typography>
                )}

                <TalentSearchCriteria
                    StertSerch={StartSearch}
                    count={TalentState.talent_serch.page_count}
                    query={query}
                    state={TalentState.search_condition}
                >
                    <div id="talent-serch-item-glid" style={{ scrollMargin: "100px" }}></div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        // alignItems="center"
                        style={{
                            maxWidth: "1000px",
                            marginInline: "auto",
                        }}
                    >
                        {TalentState.talent_list.map((item, index) => (
                            <React.Fragment key={index}>
                                <TalentCard data={item} detailUrl={`/common/TalentDetail/${item.talent_id}`} />
                            </React.Fragment>
                        ))}
                    </Grid>
                </TalentSearchCriteria>
            </Container>
        </React.Fragment>
    );
};

export default TalentSearch;
