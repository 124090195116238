import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { GetVoteList } from "~stores/TALENTBANK/Vote";
import { BackButton } from "~CJS/atoms/BackButton";

const VoteLanking = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const path = history.location.pathname.split("/");
    const offer_id = path[3];

    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const status = query.get("status");

    const VoteState = useSelector((state) => state.Vote);
    // const OfferState = useSelector((state) => state.Offer);

    useEffect(() => {
        dispatch(GetVoteList({}));
    }, []);

    return (
        <Container className="page_container">
            {/* <ButtonNavi left_label="TOP" left_link="/" /> */}
            <BackButton history={history} />
            <Typography variant="h1">投票数ランキング!!</Typography>
            {VoteState.selected_vote ? (
                <>
                    <VoteInfoCard value={VoteState.selected_vote} />

                    {VoteState.vote_talent_list ? (
                        <>
                            {VoteState.vote_talent_list.map((value, index) => (
                                <VoteCard index={index} key={index} value={value} history={history} />
                            ))}
                        </>
                    ) : (
                        <> 投票されていません</>
                    )}
                </>
            ) : (
                <>現在開催されている投票はありません。</>
            )}
        </Container>
    );
};

const VoteInfoCard = (props) => {
    const { value, ...other } = props;
    const { image, vote_close, vote_detail, vote_id, vote_start, vote_title, vote_view_close, vote_view_start } = value;

    return (
        <>
            <Grid
                container
                style={{
                    maxWidth: "800px",
                    border: "solid 1px",
                    margin: "1rem",
                    maxWidth: "800px",
                    marginRight: "auto",
                    marginLeft: "auto",
                    padding: "0.5rem",
                }}
            >
                <Grid item xs={12}>
                    <img
                        src={image}
                        style={{
                            marginRight: "auto",
                            marginLeft: "auto",
                            display: "block",
                            width: "100%",
                            maxWidth: "fit-content",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h2">{vote_title}</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    公開開始日: {vote_view_start}
                </Grid>
                <Grid item xs={6} sm={3}>
                    公開終了日:{vote_view_close}
                </Grid>
                <Grid item xs={6} sm={3}>
                    投票開始日:{vote_start}
                </Grid>
                <Grid item xs={6} sm={3}>
                    投票終了日:{vote_close}
                </Grid>
                <Grid item xs={12}>
                    <Grid>{vote_detail}</Grid>
                </Grid>
            </Grid>
        </>
    );
};

const VoteCard = (props) => {
    const { index, value, history, ...other } = props;
    const { image_big, talent_id, talent_name, poll } = value;

    const onClickLink = () => {
        history.push(`/common/TalentDetail/${talent_id}`);
    };

    return (
        <Grid
            container
            style={{
                maxWidth: "800px",
                border: "solid 1px",
                margin: "1rem",
                maxWidth: "800px",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "0.5rem",
            }}
            onClick={onClickLink}
        >
            <Grid item>
                <Grid
                    item
                    style={{
                        fontSize: "2rem",
                    }}
                >
                    {index + 1}位
                </Grid>
            </Grid>
            <Grid item>
                <Grid item>
                    <img
                        src={image_big}
                        style={{
                            height: "150px",
                            width: "150px",
                            marginRight: "auto",
                            marginLeft: "auto",
                            display: "block",
                            maxWidth: "fit-content",
                        }}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                xs
                container
                direction="column"
                justifyContent="space-around"
                spacing={2}
                style={{
                    padding: "0.2px",
                }}
            >
                <Grid item xs>
                    <Typography gutterBottom variant="subtitle1">
                        {talent_name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        投票数
                    </Typography>
                    <Typography
                        variant="body2"
                        gutterBottom
                        style={{
                            fontSize: "1.5rem",
                        }}
                    >
                        {poll}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{
                            minWidth: "10rem",
                        }}
                    >
                        プロフィール
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VoteLanking;
