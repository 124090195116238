import React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
// import Box from "@material-ui/core/Box";
import ImageResizer from "~CJS/functions/ImageResizer";
import ImageSharpIcon from "@material-ui/icons/ImageSharp";


import { talent_set, GetTalentList } from "~stores/TALENTBANK/Talent";

import Chat from "~/components/block/Chat";
import { LinkButton } from "~CJS/atoms/LinkButton";

import { initialize, chat_set, GetChatList, BulkSendMessage } from "~slices/common/Chat";
import { words } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    block: {
        display: "block",
    },
}));

const ChatListItem = (props) => {
    const { classes, name, image, message, count, onClick, company_id, talent_id } = props;

    return (
        <React.Fragment>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Badge badgeContent={count} color="secondary">
                        <Avatar alt={name} src={image} to={`/common/TalentDetail/${talent_id}`} component={Link} />
                    </Badge>
                </ListItemAvatar>

                <ListItemText
                    primary={name}
                    secondary={
                        <React.Fragment>
                            <Typography component="span" variant="body2" className={classes.block} noWrap>
                                {message}
                            </Typography>
                        </React.Fragment>
                    }
                    onClick={() => onClick(talent_id, company_id)}
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </React.Fragment>
    );
};

const ChatList = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const TalentState = useSelector((state) => state.Talent);


    const [talent_serch, setTalentSerch] = React.useState({});

    const [serch_item, setSerchItem] = React.useState({ words: '' });
    const [BulkMessagesOpen, setBulkMessagesOpen] = React.useState(false);



    const [message, setMessage] = React.useState('');
    // const search = useLocation().search;
    // const query = new URLSearchParams(search);

    // console.log(query);
    // console.log(query.get("ata"));
    const InformationState = useSelector((state) => state.Information);
    const image_label = InformationState.sponsor.login ? "talent_image" : "company_image";
    const name_label = InformationState.sponsor.login ? "talent_name" : "company_name";

    const ChatState = useSelector((state) => state.Chat);
    const chat_list = ChatState.chat_list;
    const read_count = ChatState.read_count;
    const chat_list_info = ChatState.chat_list_info;


    useEffect(() => {
        dispatch(initialize());
        dispatch(GetChatList());
    }, []);

    const handleChangePage = (event, new_page) => {
        dispatch(GetChatList({ page: new_page }));
    };

    const HendleSerch = () => {

        dispatch(GetChatList(serch_item));

    }

    const HandleClick = (talent_id, company_id) => {
        history.push(`/chat/Chat?talent_id=${talent_id}&&company_id=${company_id}`);
    };

    const GetReadCount = (value) => {
        const partner_id_column = InformationState.sponsor.login ? "talent_id" : "company_id";

        if (InformationState.sponsor.login) {
            return read_count[value.talent_id]?.unread_count;
        } else {
            return read_count[value.company_id]?.unread_count;
        }
    };


    const [selectedTalents, setSelectedTalents] = React.useState([]);
    const [selectedTalentsName, setSelectedTalentsName] = React.useState([]);
    // チェックボックスの状態を管理
    const handleCheckboxChange = (event, talentId, talentName) => {
        if (event.target.checked) {
            setSelectedTalents([...selectedTalents, talentId]);
            setSelectedTalentsName([...selectedTalentsName, { talent_id: talentId, talent_name: talentName }]);
            talentName
        } else {
            setSelectedTalents(selectedTalents.filter(id => id !== talentId));
            setSelectedTalentsName(selectedTalentsName.filter(talent => talent.talent_id !== talentId));
        }
    };


    const HandleTalentSerchChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const new_search_item = {
            ...talent_serch,
            [name]: value,
        }
        setTalentSerch(new_search_item);


    }

    const HandleGetTalentList = () => {

        const no_page_criteria = {
            adoptsum_range: "0,99999",
            age: "0,80",
            city: "",
            evaluation_range: "0,5",
            favorite: false,
            height: "50,200",
            keyword: "",
            limit: 24,
            order: '{"label":"採用件数順","value":"2","column":"achievement_count","order":"desc"}',
            page: 1,
            prefectures: "未選択",
            sex: "0",
            tag_select_list: '[]',
            ...talent_serch
        };


        dispatch(GetTalentList(no_page_criteria));

    }

    const [base64_image, set_base64_image] = React.useState(false);


    const HandleChange = async (e) => {
        const send_image = e.target.files[0];
        try {
            const option = { height: 800, width: 1200 };
            const url = await ImageResizer(send_image, option);
            set_base64_image(url);
        } catch (err) {
            console.log(err);
        }
    };


    const SendBulkMessages = () => {
        const image = base64_image ? base64_image : '';
        const params = {
            message_data: { message: message, image: image },
            talent_id_list: selectedTalents,
        };

        dispatch(BulkSendMessage(params)).then((data) => {
            if (data?.payload?.result == 1) {
                alert("送信が完了しました。");
                setBulkMessagesOpen(false);
            }

        });

    }
    return (
        <Container style={{ maxWidth: '40rem' }}>
            <LinkButton to="/" label={"TOP"} />


            {/* 一括ログイン機能は後日リリース */}
            {/* {InformationState.sponsor.login ?
                <Button variant='outlined'
                    style={{ marginRight: '0px', marginLeft: 'auto', display: 'block' }}
                    onClick={() => {

                        HandleGetTalentList();
                        setBulkMessagesOpen(true);

                    }}>一括送信
                </Button> : ''
            } */}

            <Modal
                open={BulkMessagesOpen}
                onClose={() => setBulkMessagesOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="background.paper"
                    p={3}
                    borderRadius={5}
                    // minWidth={'80%'}
                    width={'90%'}
                    // height={200}
                    position="absolute"
                    top="10%"
                    left="5%"
                // style={{ transform: 'translate(-50%, -50%)' }}
                >



                    <Box>
                        <TextField
                            label=""
                            variant="outlined"
                            value={talent_serch?.keyword ? talent_serch.keyword : ''}
                            onChange={HandleTalentSerchChange}
                            margin="normal"
                            name={'keyword'}
                            helperText='最大で24名表示されます'

                        />
                        <Button
                            variant="contained"
                            color="primary"
                            margin="normal"
                            style={{ marginTop: '1rem' }}
                            onClick={HandleGetTalentList}


                        >
                            検索
                        </Button>
                    </Box>
                    <Grid container spacing={2} style={{ height: '15rem', overflowY: 'scroll' }}>
                        {TalentState.talent_list.map((val, i) => (
                            <Grid item key={i}>
                                <FormControlLabel
                                    style={{
                                        width: '13rem',
                                        background: '#e9e9e9',
                                        overflow: 'hidden',
                                        border: 'solid 1px #c8c8c8',
                                        paddingTop: '0.1rem',
                                        paddingBottom: '0.1rem'
                                    }}
                                    control={
                                        <Checkbox
                                            checked={selectedTalents.includes(val.talent_id)}
                                            onChange={(event) => handleCheckboxChange(event, val.talent_id, val.talent_name)}
                                            name={val.talent_name}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <React.Fragment>
                                            <img
                                                src={val.image_small}
                                                alt={val.talent_name}
                                                style={{
                                                    width: '3rem',
                                                    height: '3rem',
                                                    marginRight: '0.5rem',
                                                }}
                                            />
                                            {val.talent_name}
                                        </React.Fragment>
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Box> 送信先</Box>
                    <Box>
                        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selectedTalentsName.map((val, i) => (
                                <React.Fragment key={i}>
                                    <Box style={{ margin: '0.5rem', padding: '0.3rem', background: '#e3e1e1' }}>
                                        {val.talent_name}
                                    </Box>
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                    <Typography variant="h6" id="simple-modal-title">
                        メッセージを入力
                    </Typography>

                    <label
                        htmlFor={"file_button"}
                        style={{
                            marginTop: "auto",
                            background: "orange",
                            border: "double #fff",
                            padding: "0.2rem 0.5rem",
                        }}
                    >
                        イメージを選択する
                        <input
                            accept="image/*"
                            id={"file_button"}
                            name="send_image"
                            type="file"
                            style={{ display: "none" }}
                            multiple
                            onChange={HandleChange}
                        />
                        <input name="base64_image" type="text" style={{ display: "none" }} disabled value={base64_image} />



                        <ImageSharpIcon style={{ fontSize: "2rem" }} />
                    </label>

                    {base64_image ?
                        <img
                            src={base64_image}
                            alt={'input_image'}
                            style={{
                                width: '10rem',
                                height: '10rem',
                                marginRight: '0.5rem',
                            }}
                        /> : ''}


                    <TextField
                        label="メッセージ"
                        variant="outlined"
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        minRows={4}
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={SendBulkMessages}
                        fullWidth
                    >
                        一斉送信
                    </Button>
                </Box>
            </Modal>


            {/* BulkMessagesOpen */}
            <Typography variant="h1">トーク</Typography>




            <TextField label='検索' value={serch_item.words} onChange={(e) => setSerchItem({ words: e.target.value })} /> <Button variant='contained' onClick={HendleSerch}>検索</Button>
            <Grid container spacing={1}>
                <List className={classes.root}>
                    {chat_list.map((value, index) => (
                        <React.Fragment key={index}>
                            <ChatListItem
                                image={value[image_label]}
                                classes={classes}
                                name={value[name_label]}
                                message={value.message}
                                count={GetReadCount(value)}
                                onClick={HandleClick}
                                talent_id={value.talent_id}
                                company_id={value.company_id}
                            />
                        </React.Fragment>
                    ))}
                </List>
            </Grid>
            {chat_list_info?.page_count > 1 && (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        shape="rounded"
                        count={chat_list_info?.page_count}
                        variant="outlined"
                        page={chat_list_info.page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )}
        </Container>
    );
};

export default ChatList;
