import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
// import { LinkButton } from "~CJS/atoms/LinkButton";
// import OptionInput from "~CJS/block/OptionInput";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useDispatch, useSelector } from "react-redux";
import { GetTalentUserInfo } from "../../../stores/slices/Information";

import { SwitchInput } from "~CJS/atoms/SwitchInput";

const TalentConfig = () => {
    // const history = useHistory();
    const dispatch = useDispatch();
    // const informationState = useSelector((state) => state.Information);

    // useEffect(() => {
    //     dispatch(GetTalentUserInfo());
    // }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        // const city = elem?.city?.value;
    };

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="TOP" left_link="/" />
                <Typography variant="h1">タレント設定</Typography>
                <form onSubmit={HandleSubmit}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <SwitchInput
                            label="トーク時のメール送信"
                            name="no_entry_close_flag"
                            // value={criteriaState.no_entry_close_flag}
                            helper_text="オファートークを受信したらメールを送信します。"
                        />

                        <SwitchInput
                            label="採用･不採用時のメール送信"
                            name="no_entry_close_flag"
                            helper_text="採用・不採用の際にメールを送信します。"
                        />
                    </Grid>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default TalentConfig;
