import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TagList from "~CJS/atoms/TagList";

export default function FormDialog(props) {
    const { tag_list, tag_select_list, TagClick, ButtonProps } = props;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const title = props.title ? props.title : "タグ";

    return (
        <div>
            <Button
                variant="text"
                color="primary"
                onClick={handleClickOpen}
                {...ButtonProps}
            >
                {title}一覧を開く
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">{title}一覧</DialogTitle>
                <DialogContent>
                    <TagList
                        TagClick={TagClick}
                        tag_list={tag_list}
                        tag_select_list={tag_select_list}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
