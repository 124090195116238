import React, { useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    background: "none",
    "& .Mui-disabled": {
      background: "none",
    },
    "& svg": {
      display: "none",
    },
  },
});

const SelectSex = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <>
      <TextField
        style={{ width: "100%", marginBottom: "1rem" }}
        className={classes.root}
        select
        name="sex"
        label="性別"
        fullWidth
        variant={props.disabled ? "filled" : "outlined"}
        SelectProps={{
          native: true,
        }}
        {...props}
        value={value}
        onChange={handleChange}
      >
        <option value={0}>未選択</option>
        <option value={2}>女性</option>
        <option value={1}>男性</option>
        <option value={3}>その他</option>
      </TextField>
    </>
  );
};

export default SelectSex;
