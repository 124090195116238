import React from "react";
import { useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

/**
 * ラジオボタン
 * @module SwitchInput
 * @param label 表示名
 * @param value 選択値
 * @return DOM
 */
export const SwitchInput = (props) => {
  const { value, label, disabled, helper_text, ...other } = props;

  const [checked, set_checked] = React.useState(value ? true : false);

  useEffect(() => {
    if (checked == value) return;
    set_checked(value && value != "false" ? true : false);
  }, [value]);

  const handleChange = () => {
    if (disabled) return;
    set_checked(!checked);
  };
  return (
    <>
      <FormControl component="fieldset" variant="outlined" style={{ margin: "0.5rem" }}>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange} {...other} value={checked} />}
          label={label}
        />
        <FormHelperText>{helper_text}</FormHelperText>
      </FormControl>
    </>
  );
};

/**
 * ラジオボタン
 * @module RadioButtonsInput
 * @param item_list 選択項目リスト
 * @param item_list.value 項目の値
 * @param item_list.label 項目の表示名
 * @param label 表示名
 * @param name 名前
 * @return DOM
 */
export const RadioButtonsInput = (props) => {
  const { item_list, disabled, name, value, label, helper_text, ...other } = props;
  const [select_value, setSelectValue] = React.useState(value);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const handleChange = (event) => {
    if (disabled) return;
    setSelectValue(event.target.value);
  };

  return (
    <FormControl component="fieldset" variant="outlined" style={{ margin: "0.5rem" }}>
      <FormLabel component="legend" style={{ color: "black" }}>
        {label}
      </FormLabel>
      <RadioGroup
        style={{ marginLeft: "14px" }}
        row
        aria-label={label}
        name={name}
        value={select_value}
        onChange={handleChange}
        {...other}
      >
        {item_list.map((item, index) => (
          <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} />
        ))}
      </RadioGroup>
      <FormHelperText>{helper_text}</FormHelperText>
    </FormControl>
  );
};
