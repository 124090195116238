import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { LinkButton } from "~CJS/atoms/LinkButton";

import { makeStyles } from "@material-ui/core/styles";
import { getStringFromDate } from "~CJS/functions/Time";

const LINES_TO_SHOW = 3;
const useStyles = makeStyles({
    multiLineEllipsis: {
        minHeight: "4.5rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": LINES_TO_SHOW,
        "-webkit-box-orient": "vertical",
    },
    status_0: {
        border: "solid 1px",
        // borderRadius: "0.5rem",
        boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
        position: "relative",
        // backgroundColor: "#fff",
        maxWidth: "400px",
        marginRight: "auto",
        marginLeft: "auto",
        marginBottom: "0.5rem",
        marginTop: "0.5rem",
        backgroundColor: "moccasin",
    },
    status_not0: {
        border: "solid 1px",
        // borderRadius: "0.5rem",
        boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
        position: "relative",
        // backgroundColor: "#fff",
        maxWidth: "400px",
        marginRight: "auto",
        marginLeft: "auto",
        marginBottom: "0.5rem",
        marginTop: "0.5rem",
        backgroundColor: "#fff",
    },
    sinsei_label: {
        padding: "0.5rem",
        backgroundColor: "yellow",
        width: "fit-content",
        marginLeft: "1rem",
        borderBottom: "solid 1px",
        borderRight: "solid 1px",
        borderLeft: "solid 1px",
    },
    time_limit_card: {
        backgroundColor: "#d1cfcf",
        filter: "grayscale(1)",
    },
    disabledImage: {
        // "-webkit-filter": "grayscale(1)",
        // filter: "gray",
        // filter: "grayscale(1)",
        // cursor: "default",
    },
});

const OfferCard = (props) => {
    const {
        offer_id,
        image_small,
        offer_title,
        created_at,
        prefectures,
        city,
        entry_close,
        job_detail,
        guarantee,
        job_date,
        job_start_time,
        job_end_time,
        status,
        audition_count,
        recruitment_count,
    } = props.data;
    const classes = useStyles();

    let nextDayOfJob = new Date(entry_close);
    // nextDayOfJob.setDate(nextDayOfJob.getDate() + 1);
    const now = new Date();

    // newの判定 1ヶ月以上経過しているかどうか
    const created_date = Date.parse(created_at);
    const elapsed = now - created_date;
    const new_flag = elapsed < 2678400000 ? true : false;
    const time_limit_flag = Date.parse(entry_close.replaceAll("-", "/")) - now < 0 ? true : false;

    const arr_date = entry_close ? entry_close.split(" ")[0].split("-") : ["", "", ""];

    return (
        <React.Fragment>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                // alignItems="flex-start"
                className={`cards ${status == 0 ? classes.status_0 : classes.status_not0} ${classes.card} ${
                    time_limit_flag && classes.time_limit_card
                }`}
            >
                {status == 0 && <Typography className={classes.sinsei_label}>申請中</Typography>}

                {new_flag && !time_limit_flag ? (
                    <p
                        style={{
                            backgroundImage: "URL(/images/talentBank/test/new.png)",
                            left: "auto",
                            right: "0",
                            backgroundSize: "55px 55px",
                            height: "55px",
                            width: "55px",
                            position: "absolute",
                        }}
                    ></p>
                ) : (
                    ""
                )}
                {time_limit_flag ? (
                    <p
                        style={{
                            backgroundImage: "URL(/images/closed.png)",
                            left: "auto",
                            right: "0",
                            backgroundSize: "140px 140px",
                            height: "140px",
                            width: "140px",
                            position: "absolute",
                        }}
                    ></p>
                ) : (
                    ""
                )}

                <Grid
                    style={{
                        padding: "0.8rem",
                        width: "100%",
                        padding: "0.8rem",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid>
                        <Typography noWrap style={{ fontSize: "1.5rem" }}>
                            {offer_title}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <img
                            src={image_small ? image_small : "null"}
                            style={{ width: "150px", height: "150px" }}
                            className={`${time_limit_flag && classes.disabledImage}`}
                            onError={(e) => {
                                e.target.onerror = "null";
                                e.target.src = "/images/talentBank/test/no_image.png";
                            }}
                        />

                        <Grid item xs style={{ maxWidth: "11rem" }}>
                            【​場所】
                            <Typography noWrap align="right">
                                {prefectures && " " + prefectures}
                                {city && " " + city}
                            </Typography>
                            【ギャランティ】
                            <Typography noWrap align="right">
                                {guarantee}
                            </Typography>
                            【応募締め切り】
                            <Typography noWrap align="right">
                                {arr_date[0] ? (
                                    <>
                                        {arr_date[0] && arr_date[0] + "年"}
                                        {arr_date[1] && arr_date[1] + "月"}
                                        {arr_date[2] && arr_date[2] + "日"}
                                    </>
                                ) : (
                                    "未定"
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        【現在の応募人数】{audition_count && audition_count}
                        【採用人数】{recruitment_count && recruitment_count}
                    </Grid>
                    <Grid>
                        【お仕事内容】
                        <Typography className={classes.multiLineEllipsis}>{job_detail}</Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{
                            bottom: "0",
                            // marginTop: "auto",
                        }}
                    >
                        <LinkButton
                            style={{
                                // fontSize: "1.4rem",
                                color: "#fff",
                                // padding: "0.5rem 3rem",
                                // fontWeight: "700",
                                // borderBottom: "5px solid #9f000c",
                                backgroundColor: "#eb6100",
                                // borderRadius: "100vh",
                                width: "100%",
                                bottom: "0",
                            }}
                            to={`/common/OfferDetail/${offer_id}`}
                            label="詳細を確認"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default OfferCard;
