import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { TalentImageSwiper } from "~/components/block/ImageSwiper";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: "#333333",
        padding: "1rem 0rem",
    },
    imageList: {
        width: "100",
        height: "100",
    },
}));

export default function ImageListView() {
    const classes = useStyles();
    const history = useHistory();
    const TopState = useSelector((state) => state.Top);

    const HandleClick = (e) => {
        history.push(`/common/TalentDetail/${e.target.name}`);
    };
    // useEffect(() => {
    //     // setwidth(elm.current.offsetWidth);
    // }, []);

    return (
        <div className={classes.root}>
            {TopState.talent_list && (
                <>
                    {/* <ImageList rowHeight={"auto"} className={classes.imageList} cols={5} gap={2}>
                        {TopState.talent_list.map((item, index) => (
                            <ImageListItem key={index} onClick={HandleClick}>
                                <img
                                    src={item.image_small}
                                    name={item.talent_id}
                                    alt={item.talent_id}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList> */}
                    <TalentImageSwiper data={TopState.talent_list} />
                </>
            )}
        </div>
    );
}
