import React from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import ImageSharpIcon from "@material-ui/icons/ImageSharp";
import ImageResizer from "~CJS/functions/ImageResizer";

const useStyles = makeStyles((theme) =>
    createStyles({
        wrapForm: {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            margin: `${theme.spacing(0)} auto`,
            position: "sticky",
            bottom: "0",
        },
        wrapText: {
            width: "100%",
        },
        button: {
            //margin: theme.spacing(1),
        },
    })
);

export const TextInput = (props) => {
    const classes = useStyles();
    const [base64_image, set_base64_image] = React.useState(false);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const send_text = e.target.elements.send_text.value;
        if (!send_text) {
            alert("メッセージが入力されていません");
            return;
        }
        props.onClick(e);
    };

    const HandleChange = async (e) => {
        const send_image = e.target.files[0];
        try {
            const option = { height: 800, width: 1200 };
            const url = await ImageResizer(send_image, option);
            props.SendImage && props.SendImage(url);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <form className={classes.wrapForm} noValidate autoComplete="off" onSubmit={HandleSubmit}>
                <label
                    htmlFor={"file_button"}
                    style={{
                        marginTop: "auto",
                        background: "orange",
                        border: "double #fff",
                        padding: "0.2rem 0.5rem",
                    }}
                >
                    <input
                        accept="image/*"
                        id={"file_button"}
                        name="send_image"
                        type="file"
                        style={{ display: "none" }}
                        multiple
                        onChange={HandleChange}
                    />
                    <input name="base64_image" type="text" style={{ display: "none" }} disabled value={base64_image} />

                    <ImageSharpIcon style={{ fontSize: "2rem" }} />
                </label>
                <TextField
                    value={props.value}
                    name="send_text"
                    id="standard-text"
                    label="メッセージを入力"
                    className={classes.wrapText}
                    onChange={props.onChange}
                    multiline
                />
                <Button variant="contained" color="primary" className={classes.button} type="submit">
                    <SendIcon />
                </Button>
            </form>
        </>
    );
};
