import React, { useState, useEffect } from "react";

// import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { initialize, GetCategoryList } from "~stores/TALENTBANK/Category";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import ButtonBase from "@material-ui/core/ButtonBase";

import { useDispatch, useSelector } from "react-redux";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        background: orange[50],
        // textAlign: "center",
        marginTop: "auto",
        // minHeight: "8rem",
        // paddingTop: "1rem",
    },
    paper: {
        padding: theme.spacing(2),
        margin: "auto",
        // maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%",
    },
}));

const Footer = (props) => {
    const { ...other } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <ButtonBase className={classes.image} component={Link} href="http://www.hybridbank.net">
                                <img
                                    className={classes.img}
                                    alt="complex"
                                    src="/images/talentBank/test/HB500x500.png"
                                />
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1">
                                        HYBRID BANK co.,ltd
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Headquarters 7F 4-1-1 minami-kyuuhoujimachi, chuo-ku osaka-city 541-0058
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        tel.06-6226-7877
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        mail: info@talen-park.net
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        hp: <a href="http://www.hybridbank.net">www.hybridbank.net</a>
                                    </Typography>
                                    {/* <Typography variant="body2" color="textSecondary">
                                        〒541-0058
                                    </Typography> */}
                                </Grid>
                            </Grid>
                            {/* <Grid item>
                                <Typography variant="subtitle1">情報</Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default Footer;
