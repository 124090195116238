import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SelectRatings from "~CJS/atoms/SelectRatings";
import { useHistory } from "react-router-dom";

/**
 * saveAsImage
 * @param data タレントの情報 {image_small, new_flag, talent_name, talent_id, review_avarage, achievement_count}
 * @param {string} detailUrl タレントカード押下時に遷移する画面のURL
 * @param {boolean} admin 管理画面かどうか
 * @return DOM
 */
const TalentCard = (props) => {
  const { image_small, new_flag, talent_name, talent_id, review_average, achievement_count } = props.data;
  const history = useHistory();

  const handleClick = () => {
    history.push(props.detailUrl);
  };

  return (
    <div className="big-scale" onClick={handleClick}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className="cards"
        style={{
          border: "solid 1px",
          boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
          position: "relative",
          backgroundColor: "#fff",
          width: "152px",
          margin: "0.1rem",
          display: "block",
          marginBottom: "1rem",
        }}
      >
        {new_flag && (
          <p
            style={{
              backgroundImage: "URL(/images/talentBank/test/new.png)",
              left: "auto",
              right: "0",
              backgroundSize: "55px 55px",
              height: "55px",
              width: "55px",
              position: "absolute",
            }}
          ></p>
        )}
        {achievement_count >= 1000 && (
          <p
            style={{
              backgroundImage: "URL(/images/talentBank/test/medal/gold.png)",
              left: "auto",
              right: "0",
              backgroundSize: "55px 55px",
              height: "55px",
              width: "55px",
              position: "absolute",
            }}
          ></p>
        )}
        {achievement_count >= 500 && achievement_count < 1000 && (
          <p
            style={{
              backgroundImage: "URL(/images/talentBank/test/medal/silver.png)",

              left: "auto",
              right: "0",
              backgroundSize: "55px 55px",
              height: "55px",
              width: "55px",
              position: "absolute",
            }}
          ></p>
        )}
        {achievement_count >= 100 && achievement_count < 500 && (
          <p
            style={{
              backgroundImage: "URL(/images/talentBank/test/medal/bronze.png)",
              left: "auto",
              right: "0",
              backgroundSize: "55px 55px",
              height: "55px",
              width: "55px",
              position: "absolute",
            }}
          ></p>
        )}

        <Grid style={{ width: "inherit" }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <img
              style={{
                height: "150px",
                width: "150px",
              }}
              src={image_small ? image_small : "null"}
              onError={(e) => {
                e.target.onerror = "null";
                e.target.src = "/images/no_image_150px_150px.png";
              }}
            />

            {props.admin && (
              <Grid item xs>
                <Typography
                  style={{
                    background: "black",
                    color: "white",
                    fontSize: "inherit",
                    padding: "0.2rem",
                  }}
                  align="center"
                >
                  {talent_id}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid>
            <Typography
              noWrap
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              {talent_name}
            </Typography>
          </Grid>
          <Grid>
            <SelectRatings value={review_average} readOnly />
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Button
              style={{
                marginTop: "0.3rem",
                color: "#fff",
                padding: "0.2rem 1rem",
                width: "100%",
                backgroundColor: "#eb6100",
              }}
            >
              プロフィール
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TalentCard;
