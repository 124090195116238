import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import AuditionSeetInput from "~/components/block/AuditionSeetInput";
import { BackButton } from "~CJS/atoms/BackButton";
import { initialize, GetAuditionSeet } from "~stores/TALENTBANK/AuditionSeet";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonNavi } from "~CJS/block/ButtonNavi";

const useStyles = makeStyles((theme) => ({
    comment_paper: {
        color: "white",
        padding: "0.5rem",
        fontSize: "large",
        background: "darkorange",
        marginTop: "1rem",
        fontWeight: "bold",
        marginBottom: "1rem",
        borderRadius: "1.5rem",
        padding: "1rem",
    },
    seet_paper: {
        marginTop: "1rem",
        marginBottom: "1rem",
        padding: "0.5rem",
    },
    subtitle: {
        marginTop: "1rem",
    },
}));

const AuditionSeet = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const path = history.location.pathname.split("/");
    const offer_id = path[3];

    const AuditionSeetState = useSelector((state) => state.AuditionSeet);

    let nextDayOfJob = new Date(AuditionSeetState.offer_info.job_date?.replace("/", "/"));
    nextDayOfJob.setDate(nextDayOfJob.getDate() + 1);
    const now = new Date();

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetAuditionSeet({ offer_id: offer_id }));
    }, []);

    const detail = AuditionSeetState.audition_seet_detail;
    const status = () => {
        const status = detail?.status;
        switch (status) {
            case "0":
                return "未判定";

            case "1":
                return "採用";

            case "2":
                return "不採用";
            default:
                return "未判定";
        }
    };
    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="お仕事詳細" left_link={`/common/OfferDetail/${offer_id}`} />

                {/* <Paper elevation={3} className={classes.comment_paper}>
                    <Typography variant="h6">オーディションシートについて</Typography>
                    オーディションシートを記入し応募することができます。
                    <br />
                    募集している企業が確認した後、メッセージがオファートークに届きます。
                </Paper> */}

                <Paper elevation={3} className={classes.comment_paper}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs={4}>
                            ステータス
                        </Grid>
                        <Grid item xs={8}>
                            {status()}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ marginTop: "0.5rem" }}
                    >
                        {detail?.reason && (
                            <>
                                <Grid item xs={4}>
                                    {status()}理由
                                </Grid>
                                <Grid item xs={8}>
                                    {detail.reason}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>

                <Paper elevation={3} className={classes.seet_paper}>
                    <form>
                        <AuditionSeetInput data={detail} disabled />
                    </form>
                </Paper>
                {now < nextDayOfJob && detail?.status == "0" && (
                    <LinkButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        label="編集画面へ"
                        to={`/talent/AuditionSeetFix/${offer_id}`}
                    />
                )}
            </Container>
        </React.Fragment>
    );
};

export default AuditionSeet;
