import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import OfferCardList from "~/components/block/OfferCardList";
import { initialize, GetMyAuditionList } from "~stores/TALENTBANK/Offer";

import { useDispatch, useSelector } from "react-redux";

const MyAuditionList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const thisState = useSelector((state) => state.Offer);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetMyAuditionList());
    }, []);

    return (
        <React.Fragment>
            <Typography variant="h1">応募中オーディション</Typography>
            <OfferCardList offerlist={thisState.my_audition_list} view_info={thisState.my_audition_view_info} />
        </React.Fragment>
    );
};

export default MyAuditionList;
