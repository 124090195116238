import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    input_option_list: [],
};

export const GetOptionInput = createAsyncThunk("Talent/GetOptionInput", async (arg, thunkAPI) => {
    const api_key = "";
    const url = "/api/option_input_list";

    const params = arg;

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: {
                "API-KEY": api_key,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const RequestTalentCreate = createAsyncThunk("Talent/RequestTalentCreate", async (arg, thunkAPI) => {
    let url = "/api/request_talent_create";
    const params = arg;
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    try {
        const response = await axios.post(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "TalentCreateRequest",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        talent_create_request_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetOptionInput.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "1") {
                state.input_option_list = resdata.input_option_list;
            }
        },
        [GetOptionInput.rejected]: (state, action) => {},
    },
});

export default slice.reducer;
export const { initialize, talent_create_request_set } = slice.actions;
