import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { Input, Button, TextField } from "@material-ui/core/";
import { InputMaxLength } from "~CJS/atoms/TextFields";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SelectPrefectures from "~CJS/atoms/SelectPrefectures";
import SelectSort from "~CJS/atoms/SelectSort";
import SelectSex from "~CJS/atoms/SelectSex";
import SelectAge from "~CJS/atoms/SelectAge";
import SelectHeight from "~CJS/atoms/SelectHeight";
import { AdoptSumRange, EvaluationRange } from "~CJS/atoms/SelectRange";
import CategoryInput from "~/components/block/CategoryInput";
import { initialState } from "../../stores/TALENTBANK/Talent";
import { useSelector } from "react-redux";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const useStyles = makeStyles((theme) =>
    createStyles({
        wrapForm: {
            display: "flex",
            justifyContent: "center",
            width: "95%",
            margin: `${theme.spacing(0)} auto`,
            flexWrap: "wrap",
            padding: "1rem",
            border: "solid 1px",
        },
        wrapText: {
            width: "100%",
        },
        hiddnButton: {
            marginRight: "auto",
            marginBottom: "20px",
        },
        resetButton: {
            marginLeft: "auto",
            marginBottom: "20px",
        },
    })
);

const TalentSearchCriteria = (props) => {
    const { StertSerch, count, children, query, state, ...other } = props;
    const classes = useStyles();
    const categoryListState = useSelector((state) => state.Category.category_list);
    const submit_ref = React.useRef(null);
    const [hiddn, set_hiddn] = React.useState(false);
    const order_list = [
        { label: "登録順", value: "1", column: "created_at", order: "desc" },
        {
            label: "採用件数順",
            value: "2",
            column: "achievement_count",
            order: "desc",
        },
        { label: "更新順", value: "3", column: "updated_at", order: "desc" },
        { label: "名前順", value: "4", column: "talent_name", order: "desc" },
        {
            label: "評価順",
            value: "5",
            column: "review_average",
            order: "desc",
        },
    ];
    const [page, set_page] = React.useState(1);
    const [before_criteria, set_before_criteria] = React.useState("");
    const [limit, set_limit] = React.useState(12);
    const [favorite, setFavorite] = React.useState(query.get("favorite") === "true" ? true : false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isFirstSearch, setIsFirstSearch] = useState(true);

    // ----------------検索条件の初期値設定-------------------
    // 都道府県
    const [prefecture, setPrefecture] = useState(
        query.has("prefectures") ? query.get("prefectures") : state.prefectures
    );
    // 市町村
    const [city, setCity] = useState(query.has("city") ? query.get("city") : state.city);
    // 年齢
    let ageList = query.has("age") ? query.get("age").split(",") : state.age.split(",");
    ageList = ageList.map((age) => parseInt(age) || 0);
    if (ageList.length === 1) {
        ageList[1] = ageList[0];
    }
    ageList.splice(2);
    const [age, setAge] = useState(ageList);
    // 身長
    let heightList = query.has("height") ? query.get("height").split(",") : state.height.split(",");
    heightList = heightList.map((height) => parseInt(height) || 100);
    if (heightList.length === 1) {
        heightList[1] = heightList[0];
    }
    heightList.splice(2);
    const [height, setHeight] = useState(heightList);
    // 採用件数
    let adoptsum_range = query.has("adoptsum_range")
        ? query.get("adoptsum_range").split(",")
        : state.adoptsum_range.split(",");
    adoptsum_range = adoptsum_range.map((item) => {
        item = parseInt(item) || 0;
        if (item < 0) return 0;
        if (item > 99999) return 99999;
        return item;
    });
    if (adoptsum_range.length >= 2 && adoptsum_range[0] > adoptsum_range[1]) {
        // 1つ目の要素が2つ目の要素より大きい場合、入れ替え
        adoptsum_range.splice(0, 2, adoptsum_range[1], adoptsum_range[0]);
    }
    const [adopt_sum_start, setAdoptSumStart] = useState(adoptsum_range[0]);
    const [adopt_sum_end, setAdoptSumEnd] = useState(adoptsum_range.length >= 2 ? adoptsum_range[1] : 99999);
    // 評価
    let evaluation_range = query.has("evaluation_range")
        ? query.get("evaluation_range").split(",")
        : state.evaluation_range.split(",");
    evaluation_range = evaluation_range.map((item) => {
        item = parseFloat(item) || 0;
        return item;
    });
    if (evaluation_range.length >= 2 && evaluation_range[0] > evaluation_range[1]) {
        // 1つ目の要素が2つ目の要素より大きい場合、入れ替え
        evaluation_range.splice(0, 2, evaluation_range[1], evaluation_range[0]);
    }
    const [evaluation_start, setEvaluationStart] = useState(evaluation_range[0]);
    const [evaluation_end, setEvaluationEnd] = useState(evaluation_range.length >= 2 ? evaluation_range[1] : 5);
    // ジャンル
    const tag_select_list = query.has("tag_select_list") ? query.get("tag_select_list") : state.tag_select_list;
    let list = [];
    try {
        const decodedValue = JSON.parse(tag_select_list);

        // デコードしたものが配列であるときのみ代入
        if (Object.prototype.toString.call(decodedValue) === "[object Array]") {
            list = decodedValue;
        }
    } catch (err) {
        // tag_select_listはJSONではなかったため無視
    }
    const [category_list, setCategoryList] = useState(list);
    // ソート
    const order = query.has("order") ? query.get("order") : state.order;
    let index = 0;
    try {
        const decodedValue = JSON.parse(order);

        // ソート選択肢配列内でのインデックスを取得
        index = order_list.findIndex(({ label }) => label === decodedValue.label); // ソートカラム名(日本語)のみで判断
        index = index === -1 ? 0 : index; // 配列に存在しなかった場合は無視
    } catch (err) {
        // orderはJSONではない。ソートカラム名(日本語)を直接指定していないかチェック
        index = order_list.findIndex(({ label }) => label === order);
        index = index === -1 ? 0 : index; // 配列に存在しなかった場合は無視
    }
    const [sort, set_sort] = React.useState(index);
    // -----------------------------------------------------------

    let firstTagSelectList = []; // 初回検索時のタグ選択リスト

    // 検索結果ページ移動時、タレント検索⇔お気に入りタレントの移動時に検索を走らせる
    useEffect(() => {
        // 初回レンダー時は、ジャンルリスト取得→タグ選択リストのフィルタリングが完了していないので検索をかけない
        if (isFirstRender) return;

        submit_ref.current.click();
    }, [page, favorite]);

    // 初回レンダー時、タレント検索⇔お気に入りタレントの移動時、検索画面かお気に入り画面かを判断する
    useEffect(() => {
        setFavorite(query.get("favorite") === "true" ? true : false);
    }, [query]);

    // タグ選択リストの中身をフィルタリング
    useEffect(() => {
        // レンダー2回目以降かつジャンルリストの中身があるとき、タグ選択リストのフィルタリングと検索を行う
        if (!isFirstRender && categoryListState.length > 0) {
            let filtered = []; // フィルター後の配列
            for (const item of category_list) {
                // タグ選択リスト
                for (const category of categoryListState) {
                    // DBから取得したジャンルリスト
                    // ジャンルリストのジャンル名に一致するものがタグ選択リストに入っていたら、そのジャンル情報をフィルター後の配列に入れる
                    if (category.category_name === item.category_name || category.category_name === item) {
                        filtered.push({ category_name: category.category_name, category_id: category.category_id });
                        continue;
                    }
                }
            }

            setCategoryList(filtered); // DOMに渡すタグ選択リストを更新
            firstTagSelectList = filtered;
            submit_ref.current.click();
            setIsFirstSearch(false);
        } else {
            // 初回レンダー時はまだジャンルリストを取得できていないため、タグ選択リストのフィルタリングは行わない
            setIsFirstRender(false);
        }
    }, [categoryListState]);

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;
        const keyword = elem.keyword.value;
        const age = elem.age.value;
        const height = elem.height.value;
        const evaluation_range = elem.evaluation_range.value;
        const adoptsum_range = elem.adoptsum_range.value;
        const city = elem?.city?.value;
        const sex = elem.sex.value;
        // 初回検索時は最新のタグ選択リストがDOMに反映されていないため、別変数に保持している値を使用
        const category = isFirstSearch ? JSON.stringify(firstTagSelectList) : elem?.category?.value;
        const prefectures = elem?.prefectures?.value;

        const no_page_criteria = {
            keyword: keyword,
            prefectures: prefectures,
            city: city && city != "未選択" ? city : "",
            order: JSON.stringify(order_list[sort]),
            tag_select_list: category,
            age: age,
            sex: sex,
            height: height,
            evaluation_range: evaluation_range,
            adoptsum_range: adoptsum_range,
            limit: limit,
            favorite: favorite,
        };

        let criteria = { ...no_page_criteria };
        if (Object.is(JSON.stringify(no_page_criteria), JSON.stringify(before_criteria))) {
            set_before_criteria(no_page_criteria);
            criteria["page"] = page;
        } else {
            set_before_criteria(no_page_criteria);

            const this_page = query?.has("page") ? query?.get("page") : "1";
            set_page(Number(this_page));
            criteria["page"] = "1";
        }

        StertSerch && StertSerch(criteria);
    };

    const handleChangePage = (event, new_page) => {
        set_page(new_page);
    };

    // 検索条件リセット
    const handleClickReset = () => {
        const ini = initialState.search_condition;
        setPrefecture(new String(ini.prefectures));
        setCity(new String(ini.city));
        setAge(ini.age.split(",").map(Number));
        setHeight(ini.height.split(",").map(Number));
        setAdoptSumStart(new Number(ini.adoptsum_range.split(",").map(Number)[0]));
        setAdoptSumEnd(new Number(ini.adoptsum_range.split(",").map(Number)[1]));
        setEvaluationStart(new Number(ini.evaluation_range.split(",").map(Number)[0]));
        setEvaluationEnd(new Number(ini.evaluation_range.split(",").map(Number)[1]));
        setCategoryList([]);
        set_sort(0);
    };

    return (
        <React.Fragment>
            <form name={"serchform"} onSubmit={HandleSubmit} className={classes.wrapForm}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Button
                        variant="contained"
                        color={hiddn ? "primary" : "default"}
                        size="small"
                        className={classes.hiddnButton}
                        onClick={() => set_hiddn(hiddn ? false : true)}
                    >
                        {hiddn ? "検索条件を表示" : "検索条件を隠す"}
                    </Button>
                    <Button
                        variant="contained"
                        color="default"
                        size="small"
                        className={classes.resetButton}
                        onClick={handleClickReset}
                    >
                        リセット
                    </Button>
                </Grid>

                <Accordion expanded={!hiddn} elevation={0}>
                    <AccordionSummary style={{ display: "none" }}></AccordionSummary>
                    <AccordionDetails>
                        <div
                        // className={hiddn ? classes.displayNone : classes.displayShow}
                        // // style={{
                        // //     transition: "1s",
                        // //     display: hiddn ? "none" : "initial",
                        // //     opacity: hiddn ? 0 : 1,
                        // //     width: hiddn ? 0 : "60vw",
                        // // }}
                        >
                            <InputMaxLength
                                style={{ width: "100%", marginBottom: "1rem" }}
                                name="keyword"
                                label="タレント名"
                                variant="outlined"
                                inputProps={{ maxLength: 100 }}
                                value={query.has("keyword") ? query.get("keyword") : ""}
                            />
                            <SelectPrefectures value={prefecture} city={{ cityValue: city }} nocity />
                            <SelectSex defaultValue={query.has("sex") && query.get("sex")} />
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <SelectAge range={age} />

                                <SelectHeight range={height} />
                            </Grid>

                            <AdoptSumRange
                                startValue={adopt_sum_start}
                                endValue={adopt_sum_end}
                                style={{ display: "hidden" }}
                            />

                            <EvaluationRange startValue={evaluation_start} endValue={evaluation_end} />

                            <CategoryInput category_list={category_list} />

                            <SelectSort
                                order_list={order_list}
                                value={sort}
                                onChange={(e) => set_sort(e.currentTarget.value)}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Button variant="contained" color="primary" type="submit" fullWidth ref={submit_ref}>
                    検索
                </Button>
            </form>
            {children}
            {count > 1 && (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        shape="rounded"
                        count={count}
                        variant="outlined"
                        page={page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
};

export default TalentSearchCriteria;
