import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Button, Grid, Container, TextField } from "@material-ui/core/";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import ImageResizer from "~CJS/functions/ImageResizer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  dialogTitle: {
    margin: 0,
    // padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "0",
    // top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  input_file: {
    opacity: "0",
    appearance: "none",
    position: "absolute",
  },
}));

// comment true,false コメント入力するか
// option { height: 500, width: 500 } image bigのサイズ
// error_image エラー時のイメージ
const InputImagePreview = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [viewDialog, setViewDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ImageClick = () => {
    console.log("click");
    setViewDialog(true);
  };
  const initialState = { image: false, comment: "", image_big: false };

  const [image, setImage] = useState(initialState.image);
  const [image_big, setImageBig] = useState(initialState.image_big);
  const [comment, setComment] = useState(initialState.comment);

  // const [input_image, setInputImage] = React.useState(props.image);
  // const [input_image_big, setInputImageBig] = React.useState(props.image_big);
  const [input_image, setInputImage] = React.useState("null");
  const [input_image_big, setInputImageBig] = React.useState("null");

  useEffect(() => {
    if (!props.image) {
      setInputImage("null");
      return;
    }
    setInputImage(props.image);
  }, [props.image]);
  useEffect(() => {
    if (!props.image_big) {
      setInputImageBig("null");
      return;
    }
    setInputImageBig(props.image_big ? props.image_big : "");
  }, [props.image_big]);

  const SetImages = async (e) => {
    const file = e.target.files[0];
    try {
      const url = await ImageResizer(file, props);
      handleClickOpen();
      setImage(url);

      const option = props.option ? props.option : { height: 500, width: 500 };
      const big_url = await ImageResizer(file, option);
      setImageBig(big_url);
      return image;
    } catch (err) {}
  };

  const DelImages = () => {
    setImage(false);
  };
  const SetComment = (e) => {
    setComment(e.target.value);
  };

  const DeleteImages = () => {
    setImage(false);
    setInputImage("");
    setImageBig("");

    setInputImageBig("");
    setComment("");
  };

  const Decision = () => {
    setInputImage(image);
    setInputImageBig(image_big);

    if (props?.onClick) {
      props.onClick({
        comment: comment,
        image: image,
        image_big: image_big,
      });
    }
    DelImages();
  };

  const FileInputButton = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <Button
          key="fileinput"
          component="label"
          variant="contained"
          style={{
            width: "50%",
            maxWidth: "20rem",
            margin: "0.5rem",
          }}
          color="primary"
        >
          ファイルを選択
          <Input
            className={classes.input_file}
            type="file"
            inputProps={{
              accept: ".jpg,.gif,.png,image/gif,image/jpeg,image/png",
            }}
            name="file_input"
            onChange={SetImages}
            color="primary"
          ></Input>
        </Button>
        <Button
          component="label"
          variant="contained"
          style={{
            width: "50%",
            maxWidth: "20rem",
            margin: "0.5rem",
          }}
          color="secondary"
          onClick={DeleteImages}
        >
          削除
        </Button>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {props.label && !props.disabled && <>{props.label}</>}
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}
        className={"input-image-preview"}
        onClick={ImageClick}
      >
        <img
          src={input_image_big ? input_image_big : input_image ? input_image : "null"}
          alt={input_image_big ? "input_image_big" : "image"}
          style={{
            maxWidth: "100%",
            maxHeight: "500px",
          }}
          onError={(e) => {
            e.target.onerror = "null";
            e.target.src = props.error_image ? props.error_image : "/images/no_image_150px_150px.png";
          }}
        />
      </div>

      <input
        type="text"
        key={`${Math.floor(Math.random() * 1000)}-talent_name`}
        value={input_image}
        name={props.name ? props.name : "image"}
        disabled
        style={{ display: "none" }}
      />
      <input
        type="text"
        key={`${Math.floor(Math.random() * 1000)}-talent_name`}
        value={input_image_big}
        disabled
        name={props.name ? props.name + "_big" : "image_big"}
        style={{ display: "none" }}
      />
      {image ? (
        <ImageDialog open={open} onClose={handleClose} classes={classes}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Button key="fileinput" component="label" style={{ width: "90%", margin: "5%" }}>
              <img style={{ height: "100%", maxWidth: "100%" }} src={image} />
              <Input
                className={classes.input_file}
                type="file"
                name="file_input"
                onChange={SetImages}
                color="primary"
              ></Input>
            </Button>
          </Grid>
          {props.comment && <ImageComment onChange={SetComment} value={comment} />}
          <Judgment onDecision={Decision} onCancel={DelImages} />
        </ImageDialog>
      ) : (
        <>
          {props.disabled ? (
            <>
              <ImageDialog open={viewDialog} onClose={() => setViewDialog(false)} classes={classes}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <img
                    style={{
                      //  height: "200%",
                      width: "inherit",
                      // maxWidth: "200%"
                    }}
                    src={input_image_big}
                  />
                </Grid>
              </ImageDialog>
            </>
          ) : (
            <>
              <ImageDialog open={viewDialog} onClose={() => setViewDialog(false)} classes={classes}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <img
                    style={{
                      //  height: "200%",
                      width: "inherit",
                      // maxWidth: "200%"
                    }}
                    src={input_image_big ? input_image_big : "null"}
                    onError={(e) => {
                      e.target.onerror = "null";
                      e.target.src = props.error_image ? props.error_image : "/images/no_image_150px_150px.png";
                    }}
                  />
                </Grid>
              </ImageDialog>
              <FileInputButton />
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

const ImageDialog = (props) => {
  const { open, onClose, classes, children } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      style={{
        backdropFilter: "blur(12px)",
        background: "none",
      }}
    >
      <ImageDialogTitle classes={classes} onClose={onClose} />
      <Container
        style={{
          width: "96%",
          // padding: "1rem",
          marginTop: "1rem",
          marginBottom: "1rem",
          paddingBottom: "1rem",

          borderRadius: "0",
          // boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .5)",
        }}
      >
        {children}
      </Container>
    </Dialog>
  );
};

const ImageDialogTitle = (props) => {
  const { children, onClose, classes, ...other } = props;

  return (
    <>
      {/* <DialogTitle className={classes.dialogTitle} {...other}> */}

      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <div style={{ display: "block", height: "1.5rem" }}></div>
      {/* </DialogTitle> */}
    </>
  );
};

const ImageComment = (props) => {
  const { value, onChange } = props;
  return (
    <TextField
      type="text"
      value={value}
      name="text_input"
      style={{
        width: "100%",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
      inputProps={{ maxLength: 255 }}
      onChange={onChange}
      variant="outlined"
      label="画像説明"
      multiline
    ></TextField>
  );
};

const Judgment = (props) => {
  const { onCancel, onDecision } = props;
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Button id="cansel" component="label" variant="outlined" style={{ width: "45%" }} onClick={onCancel}>
        キャンセル
      </Button>
      <Button component="label" variant="contained" style={{ width: "45%" }} onClick={onDecision} color="primary">
        決定
      </Button>
    </Grid>
  );
};

export default InputImagePreview;
