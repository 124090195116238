import React, { useEffect } from "react";
import { orange, red, deepOrange } from "@material-ui/core/colors";

import { InputMaxLength } from "~CJS/atoms/TextFields";
import InputImagePreview from "~CJS/atoms/InputImagePreview";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import { InputStyle } from "~/components/AppStyle";

const theme = createTheme({ ...InputStyle });

const SponsorProfileInput = (props) => {
    const { disabled, company_detail, ...other } = props;

    const {
        image_big,
        image,
        company_name,
        capital,
        prefectures,
        city,
        address_1,
        address_2,
        industries,
        business_detail,
        foundation,
        representative,
        company_page,
    } = company_detail;

    const key = (e) => {
        return `${Math.floor(Math.random() * 1000)}-min`;
    };

    const company_data_label = [
        { value: company_name, name: "company_name", label: "企業名", inputProps: { maxLength: 254 } },
        { value: prefectures, name: "prefectures", label: "都道府県", inputProps: { maxLength: 254 } },
        { value: city, name: "city", label: "市町村", inputProps: { maxLength: 254 } },
        { value: address_1, name: "address_1", label: "それより下の住所", inputProps: { maxLength: 254 } },
        { value: address_2, name: "address_2", label: "マンション名など", inputProps: { maxLength: 254 } },
        { value: company_page, name: "company_page", label: "企業ホームページ", inputProps: { maxLength: 254 } },
    ];

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                {image_big && (
                    <InputImagePreview
                        key={`${Math.floor(Math.random() * 1000)}-image`}
                        comment={false}
                        height={200}
                        image_big={image_big}
                        image={image}
                        disabled={disabled}
                    />
                )}
                {company_data_label.map((value, index) => (
                    <InputMaxLength
                        key={`${Math.floor(Math.random() * 1000)}-${value.name}`}
                        label={value.label}
                        name={value.name}
                        inputProps={value.inputProps}
                        disabled={disabled}
                        multiline={value.multiline}
                        {...(disabled ? { value: value.value } : { defaultValue: value.value })}
                    />
                ))}
            </ThemeProvider>
        </React.Fragment>
    );
};

export default SponsorProfileInput;
