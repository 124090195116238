import Resizer from "react-image-file-resizer";

const ImageResizer = (file, option) =>
    new Promise((resolve) => {
        const heigth = option && option.height ? option.height : 200;
        const width = option && option.width ? option.width : 200;
        const minWidth = option && option.minWidth ? option.minWidth : null;
        const minHeight = option && option.minHeight ? option.minHeight : null;

        Resizer.imageFileResizer(
            file,
            width,
            heigth,
            "PNG",
            0,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64",
            minWidth,
            minHeight
        );
    });

export default ImageResizer;
