import React from "react";
import TextField from "@material-ui/core/TextField";

const SelectStatus = (props) => {
    const { status_list, ...other } = props;
    return (
        <>
            <TextField
                id="status"
                select
                name="status"
                label="状態"
                variant="outlined"
                SelectProps={{
                    native: true,
                }}
                {...other}
            >
                {status_list.map((status, index) => (
                    <option key={index} value={status.value}>
                        {status.label}
                    </option>
                ))}
            </TextField>
        </>
    );
};

export default SelectStatus;
