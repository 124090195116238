import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import TagsDialog from "~CJS/block/TagsDialog";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { initialize, GetCategoryList } from "~stores/TALENTBANK/Category";

import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover": {
            color: "blue",
        },
    },
    likeInput: {
        minHeight: "4rem",
        padding: "1rem 1rem 0rem 1rem",
        marginBottom: "1rem",
        marginTop: "1rem",
        border: "solid 1px #CCC",
        borderRadius: "0.2rem",
        width: "100%",
        "&:hover": {
            border: "solid 1px #111",
        },
    },
    likeInputDisabled: {
        minHeight: "4rem",
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem",
        // border: "solid 1px #CCC",
        borderRadius: "0.2rem",
    },
    inputLabel: {
        fontSize: "0.8rem",
        background: "#fff",
        marginTop: "-1.2rem",
        marginLeft: "-0.4rem",
        display: "table",
    },
}));

const CategoryInput = (props) => {
    const { StertSerch, page, submit_ref, disabled, category_list, ...other } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [tag_list, set_tag] = React.useState([]);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetCategoryList({ item_type: 1 }));
    }, []);

    const thisState = useSelector((state) => state.Category);

    const [tag_select_list, set_tag_select] = React.useState(category_list ? category_list : []);

    useEffect(() => {
        set_tag_select(props.category_list);
    }, [props.category_list]);

    useEffect(() => {
        set_tag(thisState.category_list);
    }, [thisState.category_list]);

    const TagClick = (category_name, category_id) => {
        if (tag_select_list.find((v) => v.category_name === category_name)) {
            // 選択タグリストから削除

            set_tag_select(tag_select_list.filter((item) => item.category_name !== category_name));
        } else {
            set_tag_select([...tag_select_list, { category_name: category_name, category_id: category_id }]);
        }
    };

    return (
        <React.Fragment>
            <div className={disabled ? classes.likeInputDisabled : classes.likeInput}>
                <Typography className={classes.inputLabel}>ジャンル</Typography>
                {tag_select_list.length > 0 ? (
                    ""
                ) : (
                    <Grid>
                        <Typography align="center">※ジャンルが選択されていません</Typography>
                    </Grid>
                )}
                {tag_select_list.map((data, index) => {
                    return (
                        <Chip
                            label={data?.category_name}
                            // onDelete={() => handleDelete(data)}
                            color="primary"
                            key={index}
                        />
                    );
                })}
                {!disabled && (
                    <TagsDialog
                        TagClick={TagClick}
                        tag_list={tag_list}
                        tag_select_list={tag_select_list}
                        title="ジャンル"
                        ButtonProps={{
                            style: {
                                border: "solid 1px black",
                                margin: "0.5rem 0rem",
                                width: "100%",
                            },
                        }}
                    />
                )}
                <input
                    type="text"
                    disabled
                    name="category"
                    value={JSON.stringify(tag_select_list)}
                    style={{ display: "none" }}
                />
            </div>
        </React.Fragment>
    );
};

export default CategoryInput;
