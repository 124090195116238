import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";

import { BackButton } from "~CJS/atoms/BackButton";
import LINEAt from "~/components/block/LINEAt";

const TalentSignUp = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [activeStep, set_activeStep] = React.useState(0);

    return (
        <Container>
            <Grid container>
                <BackButton history={history} />
                <Typography variant="h1">タレント新規登録</Typography>

                <Typography variant="body1">登録はラインアットから!</Typography>
                <LINEAt link="https://lin.ee/wOTDi0J" image="/images/talentBank/LOGIN/ラインアットタレント.png" />
            </Grid>
        </Container>
    );
};

export default TalentSignUp;
