import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    selected_vote: [],
    vote_talent_list: [],
    vote_talent_info: {
        page: 0,
        page_count: 0,
        total: 0,
    },
    vote_data: {
        poll: 0,
    },
    // 投票フラグ
    vote_flag: false,
};

export const GetVoteList = createAsyncThunk("Vote/VoteList", async (arg, thunkAPI) => {
    const url = "/api/vote_list";
    const params = arg;
    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {});
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});
export const GetVote = createAsyncThunk("Vote/GetVote", async (arg, thunkAPI) => {
    const url = "/api/get_vote";
    const params = arg;
    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {});
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const VoteSet = createAsyncThunk("Vote/VoteSet", async (arg, thunkAPI) => {
    const url = "/api/vote";
    const params = arg;
    try {
        const response = await axios.post(url, params, {});
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "Vote",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                return initialState;
            }
        },
        vote_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        // 企業検索
        [GetVoteList.pending]: (state, action) => {},
        [GetVoteList.fulfilled]: (state, action) => {
            const resdata = action.payload;
            state.selected_vote = resdata.selected_vote;
            state.vote_talent_list = resdata.vote_talent_list;
            state.vote_talent_info = resdata.vote_talent_info;
            // state.company_serch.page_count = resdata?.serch_info?.page_count;
            // state.company_serch.page = resdata?.serch_info?.page;
            // state.company_serch.total = resdata?.serch_info?.total;
        },
        [GetVote.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (resdata?.vote_data) {
                state.vote_data = resdata.vote_data;
            }
            if (resdata?.selected_vote) {
                state.selected_vote = resdata.selected_vote;
            }
        },
        [VoteSet.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, vote_set } = slice.actions;
