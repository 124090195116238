import React, { useEffect } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { LinkPanel } from "~CJS/atoms/LinkButton";
import OfferCard from "~/components/block/OfferCard";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import { useDispatch, useSelector } from "react-redux";

const OfferCardList = (props) => {
    const { offerlist, ...other } = props;

    console.log(offerlist);
    return (
        <React.Fragment>
            <Grid container direction="row" justifyContent="space-evenly" {...other}>
                {offerlist.length ? (
                    offerlist.map((item, index) => (
                        <React.Fragment key={index}>
                            <OfferCard data={item} />
                        </React.Fragment>
                    ))
                ) : (
                    <>
                        {"応募中のオーディションはありません"}
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <LinkPanel
                                style={{ backgroundColor: "#bcffff", marginTop: "1rem" }}
                                label="お仕事を探す"
                                to="/common/OfferSearch"
                                icon={<SearchSharpIcon style={{ fontSize: "70px" }} />}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default OfferCardList;
