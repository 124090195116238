import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StepLabel from "@material-ui/core/StepLabel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    stepper: {
        padding: "24px 5px",
    },
    step: {
        padding: 0,
    },
}));

const LoginStepper = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Stepper
                activeStep={props.activeStep}
                alternativeLabel
                className={classes.stepper}
            >
                <Step key="email" className={classes.step}>
                    <StepLabel>
                        メールアドレス
                        <br />
                        認証
                    </StepLabel>
                </Step>
                <Step key="ontime" className={classes.step}>
                    <StepLabel>
                        ワンタイム
                        <br />
                        パスワード入力
                    </StepLabel>
                </Step>
                <Step key="password" className={classes.step}>
                    <StepLabel>パスワード入力</StepLabel>
                </Step>
                <Step key="completed" className={classes.step}>
                    <StepLabel>ログイン</StepLabel>
                </Step>
            </Stepper>
        </div>
    );
};

export default LoginStepper;
