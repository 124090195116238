import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import OfferCard from "~/components/block/OfferCard";
import { LinkButton } from "~CJS/atoms/LinkButton";

import { initialize, GetMyOfferList, offer_set } from "~stores/TALENTBANK/Offer";
import { BackButton } from "~CJS/atoms/BackButton";

import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";

const MyOfferList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const OfferState = useSelector((state) => state.Offer);

    const handleChangePage = (event, new_page) => {
        const view_info = { page: new_page };

        // dispatch(offer_set(["my_offer_view_info", view_info]));
        dispatch(GetMyOfferList(view_info));
    };

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetMyOfferList(OfferState.my_offer_view_info.page));
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <BackButton history={history} />
                <LinkButton label="お仕事作成" to="/sponsor/OfferNew" />
            </Grid>
            <Typography variant="h1">お仕事一覧 </Typography>
            <Grid container direction="row" justifyContent="space-evenly">
                {OfferState?.my_offer_list && (
                    <>
                        {OfferState.my_offer_list.map((item, index) => (
                            <React.Fragment key={index}>
                                <OfferCard data={item} />
                            </React.Fragment>
                        ))}
                    </>
                )}
            </Grid>
            {OfferState?.my_offer_view_info?.page_count > 1 && (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        shape="rounded"
                        count={OfferState.my_offer_view_info.page_count}
                        variant="outlined"
                        page={OfferState.my_offer_view_info.page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
};

export default MyOfferList;
