import React from "react";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import faq_data from "../../../const/faq_data";

const useStyles = makeStyles((theme) => ({
    contents: {
        padding: "5px",
        backgroundColor: "#ffffffb5",
    },
    accordion: {
        "&.MuiPaper-root.MuiPaper-elevation1": {
            width: "100%",
            borderRadius: "unset",
            boxShadow: "none",
        },
        "&.Mui-expanded": {
            margin: 0,
        },
        "&.Mui-expanded:before": {
            opacity: "1",
        },
    },
    category_summary: {
        backgroundColor: "#ffd3a6",
    },
    category_detail: {
        display: "block",
        padding: 0,
    },
    qa_summary_detail: {
        paddingLeft: "10px",
    },
    icon: {
        fontWeight: "bold",
        fontSize: "180%",
        margin: "0 15px auto 0",
    },
    icon_q: {
        color: "mediumturquoise",
    },
    icon_a: {
        color: "#ff8600",
    },
    qa_text: {
        margin: "auto 0",
        whiteSpace: "pre-line",
    },
}));

const Question = () => {
    const classes = useStyles();

    return (
        <>
            <ButtonNavi left_label="Top" left_link="/" />
            <Typography variant="h1">よくある質問</Typography>
            <div className={classes.contents}>
                <CategoriesAccordion categories={faq_data} />
                <Typography variant="h5" style={{ marginTop: "30px" }}>
                    お問い合わせについて
                </Typography>
                <Typography style={{ padding: "20px" }}>
                    このページでは解決しないご質問や、ご意見・ご要望等ございましたら、下記のお問い合わせ先までお願いいたします。
                </Typography>
                <Typography style={{ padding: "20px" }}>
                    HYBRID BANK Co.Ltd
                    <br />
                    TALENT PARK担当
                    <br />
                    <br />
                    <span style={{ fontWeight: "bolder" }}>mail: info@talen-park.net</span>
                </Typography>
            </div>
        </>
    );
};

// 質問ジャンルーの折り畳みリスト
const CategoriesAccordion = ({ categories }) => {
    const classes = useStyles();

    return categories.map((categoryQAs) => (
        <Accordion key={categoryQAs[0].category} className={classes.accordion}>
            <AccordionSummary className={classes.category_summary} expandIcon={<ExpandMoreIcon />}>
                {categoryQAs[0].category}
            </AccordionSummary>
            <AccordionDetails className={classes.category_detail}>
                <QAsAccordion qas={categoryQAs} />
            </AccordionDetails>
        </Accordion>
    ));
};

// 質問の折り畳みリスト
const QAsAccordion = ({ qas }) => {
    const classes = useStyles();

    return qas.map((qa, index) => (
        <Accordion key={`${qa.category}_${index}`} className={classes.accordion}>
            <AccordionSummary className={classes.qa_summary_detail} expandIcon={<ExpandMoreIcon />}>
                <span className={`${classes.icon} ${classes.icon_q}`}>Q.</span>
                <span className={classes.qa_text}>{qa.question}</span>
            </AccordionSummary>
            <AccordionDetails className={classes.qa_summary_detail}>
                <span className={`${classes.icon} ${classes.icon_a}`}>A.</span>
                <span className={classes.qa_text}>{qa.answer}</span>
            </AccordionDetails>
        </Accordion>
    ));
};

export default Question;
