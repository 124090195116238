import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    // お仕事検索の条件
    offer_serch_criteria: {
        favorite: false,
    },
    // お仕事検索の条件
    offer_serch: {
        page: 1,
        page_count: 1,
        total: 0,
    },
    // お仕事検索の結果
    offer_list: [
        // {
        //     talent_id: "",
        //     name: "",
        //     image: "",
        //     new_flag: false,
        //     excellent_flag: false,
        // },
    ],
    // お仕事詳細
    offer_detail: {
        offer_id: "",
        company_id: "",
        offer_title: "",
        prefectures: "",
        city: "",
        address_1: "",
        address_2: "",
        guarantee: "",
        recruitment_count: "",
        audition_start: "",
        audition_close: "",
        job_start: "",
        job_end: "",
        job_content: "",
        job_detail: "",
        job_place: "",
        qualification: "",
        image_big: "",
        image: "",
        status: "",
    },
    // お仕事詳細画面の企業情報
    company_info: [],
    // ログイン中企業が登録したお仕事一覧
    my_offer_list: [],
    my_offer_view_info: {
        page: 1,
        page_count: 1,
        total: 0,
    },
    // ログイン中タレントが応募したお仕事一覧
    my_audition_list: [],
    my_audition_view_info: {
        page: 1,
        page_count: 1,
        total: 0,
    },
    // オーディションシート
    audition_seet: {},
    // ログイン中タレントが応募したお仕事一覧
    FavoriteAuditionList: [],
};

export const GetOfferList = createAsyncThunk("Offer/GetOfferList", async (arg, thunkAPI) => {
    const url = "/api/offer_list";
    const params = arg;
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const GetMyOfferList = createAsyncThunk("Offer/GetMyOfferList", async (arg, thunkAPI) => {
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    const url = "/api/my_offer_list";
    const params = arg;

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});
export const GetMyAuditionList = createAsyncThunk("タレント/応募したお仕事一覧", async (arg, thunkAPI) => {
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    const url = "/api/my_audition_list";
    const params = arg;
    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const GetDetail = createAsyncThunk("Offer/GetDetail", async (arg, thunkAPI) => {
    let url = "/api/offer_detail";
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    const params = arg;
    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const OfferCreate = createAsyncThunk("Offer/OfferCreate", async (arg, thunkAPI) => {
    let url = "/api/offer_create";
    const params = arg;
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    try {
        const response = await axios.post(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const OfferFixApi = createAsyncThunk("Offer/OfferFixApi", async (arg, thunkAPI) => {
    let url = "/api/offer_fix";
    const params = arg;
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    try {
        const response = await axios.put(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const OfferDecision = createAsyncThunk("Offer/OfferDecision", async (arg, thunkAPI) => {
    const api_key = "";
    let url = "/api/offer_decision";

    const params = {
        ...arg, //必須
    };
    try {
        const response = await axios.put(url, params, {
            headers: {
                "API-KEY": api_key,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const SetFavorite = createAsyncThunk("タレント/オーディションお気に入り追加", async (arg, thunkAPI) => {
    let url = "/api/set_favorite";
    const params = arg;
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    try {
        const response = await axios.post(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "Offer",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        offer_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        // お仕事検索
        [GetOfferList.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (!resdata?.offer_list) return;
            state.offer_list = resdata.offer_list;
            state.offer_serch.page_count = resdata?.serch_info?.page_count;
            state.offer_serch.page = resdata?.serch_info?.page;
            state.offer_serch.total = resdata?.serch_info?.total;
        },
        // お仕事一覧その企業が登録した
        [GetMyOfferList.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.offer_list) {
                state.my_offer_list = resdata.offer_list;
            }
            if (resdata.serch_info) {
                state.my_offer_view_info = resdata.serch_info;
            }
        },
        // お仕事詳細取得
        [GetDetail.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (!resdata?.offer_info) return;
            state.offer_detail = resdata?.offer_info;
            state.company_info = resdata?.company_info;
            state.audition_seet = resdata?.audition_seet;
            state.favorite = resdata?.favorite;
        },
        // お仕事作成
        [OfferCreate.fulfilled]: (state, action) => {},
        // お仕事編集
        [OfferFixApi.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "1") {
                alert("更新に成功しました。");
            }
        },
        // お仕事否認･承認
        [OfferDecision.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },
        //応募したお仕事一覧
        [GetMyAuditionList.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (resdata.result == "1") {
                state.my_audition_list = resdata.offer_list;
                state.my_audition_view_info.page_count = resdata?.view_info?.page_count;
                state.my_audition_view_info.page = resdata?.view_info?.page;
                state.my_audition_view_info.total = resdata?.view_info?.total;
            }
        },
        // お気に入りの登録・削除
        [SetFavorite.fulfilled]: (state, action) => {
            const resdata = action.payload;
            state.favorite = resdata?.favorite;
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, offer_set } = slice.actions;
//
// message
// timestamp
// photoURL
// displayName
// avatarDisp
//
//
//
//
