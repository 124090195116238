import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import SelectStatus from "~CJS/atoms/SelectStatus";
import AuditionSeetCard from "~/components/block/AuditionSeetCard";
import MessageDialog from "~/components/block/MessageDialog";
import {
    initialize,
    GetAuditionSeetList,
    SetAuditionSeetStatus,
    audition_seet_set,
} from "~stores/TALENTBANK/AuditionSeet";
import { useDispatch, useSelector } from "react-redux";

const AuditionSeetList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const InformationState = useSelector((state) => state.Information);
    const company_id = InformationState.sponsor.company_id;

    const search = useLocation().search;

    const query = new URLSearchParams(search);
    const offer_id = query.get("offer_id");

    const AuditionSeetState = useSelector((state) => state.AuditionSeet);
    const [status, setStatus] = useState(query.get("initial") === "true" ? "all" : AuditionSeetState.statusCondition);

    const statusList = [
        { label: "すべて", value: "all" },
        { label: "未判定", value: "0" },
        { label: "採用", value: "1" },
        { label: "不採用", value: "2" },
    ];

    useEffect(() => {
        if (query.get("initial") === "true") {
            dispatch(initialize());
            history.push(`/sponsor/AuditionSeetList?offer_id=${offer_id}`);
        }
    }, []);
    useEffect(() => {
        const params = {
            offer_id: offer_id,
            page: AuditionSeetState.page,
            status: status,
        };
        dispatch(GetAuditionSeetList(params));
    }, [AuditionSeetState.page, AuditionSeetState.statusCondition]);

    // ステータス条件変更
    const handleChangeStatusCondition = (e) => {
        setStatus(e.target.value);
    };
    // ステータス条件適用
    const applyStatusCondition = () => {
        dispatch(audition_seet_set(["statusCondition", status]));
        dispatch(audition_seet_set(["page", 1]));
    };

    // 採用
    const onAdoption = (talent_id, offer_id) => {
        const reason = window.prompt("採用理由を入力してください", "");
        if (reason === null) {
            return;
        }
        dispatch(
            SetAuditionSeetStatus({
                status: "1",
                talent_id: talent_id,
                offer_id: offer_id,
                reason: reason,
            })
        ).then((data) => {
            if (data.payload.result == "1") {
                const params = {
                    offer_id: offer_id,
                    page: AuditionSeetState.page,
                    status: status,
                };
                dispatch(GetAuditionSeetList(params));
                alert("採用しました");
            }
        });
    };
    // 不採用
    const onRepudiation = (talent_id, offer_id) => {
        const reason = window.prompt("不採用理由を入力してください", "");
        if (reason === null) {
            return;
        }
        console.log(reason);
        console.log(talent_id, offer_id);
        dispatch(
            SetAuditionSeetStatus({
                status: "2",
                talent_id: talent_id,
                offer_id: offer_id,
                reason: reason,
            })
        ).then((data) => {
            console.debug(data);
            if (data.payload.result == "1") {
                const params = {
                    offer_id: offer_id,
                    page: AuditionSeetState.page,
                    status: status,
                };
                dispatch(GetAuditionSeetList(params));
            }
        });
    };
    // 判定クリア
    const onClear = (talent_id, offer_id) => {
        const reason = window.prompt("判定を取り消す理由を入力してください", "");
        if (reason === null) {
            return;
        }
        dispatch(
            SetAuditionSeetStatus({
                status: "0",
                talent_id: talent_id,
                offer_id: offer_id,
                reason: reason,
            })
        ).then((data) => {
            if (data.payload.result == "1") {
                const params = {
                    offer_id: offer_id,
                    page: AuditionSeetState.page,
                    status: status,
                };
                dispatch(GetAuditionSeetList(params));
            }
        });
    };

    // ページ移動
    const handleChangePage = (e, page) => {
        dispatch(audition_seet_set(["page", page]));
    };

    return (
        <Container>
            <ButtonNavi left_label="戻る" left_link={`/common/OfferDetail/${offer_id}`} />

            <Typography variant="h1">応募一覧</Typography>
            <Grid
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{
                    padding: "1rem",
                }}
            >
                <div style={{ marginRight: "auto" }}>
                    <Typography>
                        採用人数<b>{AuditionSeetState.offer_info?.recruitment_count}</b>人
                    </Typography>
                    <Typography>
                        応募人数<b>{AuditionSeetState.offer_info?.audition_count}</b>人
                    </Typography>
                    <Typography>
                        現在の採用人数
                        <b>{AuditionSeetState.serch_info?.achievement_count_current}</b>人
                    </Typography>
                </div>

                <SelectStatus status_list={statusList} value={status} onChange={handleChangeStatusCondition} />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={applyStatusCondition}
                >
                    適用
                </Button>
            </Grid>
            <Grid
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{
                    padding: "1rem",
                }}
            >
                <MessageDialog sendList={AuditionSeetState.audition_seet_list} />
            </Grid>
            <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginBottom: "30px" }}>
                {AuditionSeetState.audition_seet_list.map((value, index) => (
                    <React.Fragment key={index}>
                        <AuditionSeetCard
                            {...value}
                            company_id={company_id}
                            onAdoption={onAdoption}
                            onRepudiation={onRepudiation}
                            onClear={onClear}
                            jobDate={AuditionSeetState.offer_info.job_date}
                        />
                    </React.Fragment>
                ))}
            </Grid>
            {AuditionSeetState.serch_info?.page_count > 1 && (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        shape="rounded"
                        count={AuditionSeetState.serch_info?.page_count}
                        variant="outlined"
                        page={AuditionSeetState.page}
                        color="secondary"
                        onChange={handleChangePage}
                    />
                </Grid>
            )}
        </Container>
    );
};

export default AuditionSeetList;
