import React from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { InputMaxLength } from "~CJS/atoms/TextFields";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            width: "300px",
            padding: "15px",
            backgroundColor: "#fff",
        },
    },
    underLine: {
        borderBottom: "3px solid #35cb9b70",
        paddingBottom: "2px",
    },
    rating: {
        textAlign: "center",
        marginBottom: "1rem",
    },
    button: {
        border: "none",
        display: "block",
        margin: "auto",
    },
}));

const TalentReviewDialog = (props) => {
    const { open, value, review_comment, onChange, onClose, onClickSend } = props;
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} className={classes.root}>
            <form onSubmit={onClickSend}>
                <p style={{ fontSize: "1.1rem" }}>
                    <span className={classes.underLine}>今回のお仕事の評価</span>
                </p>
                <div className={classes.rating}>
                    <Rating
                        value={Number(value)}
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                        size="large"
                        onChange={onChange}
                        name="review"
                    />
                </div>

                <InputMaxLength
                    multiline
                    value={review_comment}
                    label="レビューコメント"
                    name="review_comment"
                    inputProps={{ maxLength: 10000 }}
                />
                <Button variant="contained" color="primary" className={classes.button} type="submit">
                    送信
                </Button>
            </form>
        </Dialog>
    );
};

export default TalentReviewDialog;
