import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { BackButton } from "~CJS/atoms/BackButton";

import { useDispatch, useSelector } from "react-redux";

import SponsorProfileInput from "~/components/block/SponsorProfileInput";

import { company_set, initialize, GetCompanyDetail } from "~stores/TALENTBANK/Company";

const SponsorProfile = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const CompanyState = useSelector((state) => state.Company);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetCompanyDetail());
    }, []);

    return (
        <Container>
            <BackButton history={history} />
            <Typography variant="h1">企業情報</Typography>
            <SponsorProfileInput disabled company_detail={CompanyState.company_detail} />

            {/* <LinkButton to="/talent/SponsorProfileFix" label="編集" /> */}
        </Container>
    );
};

export default SponsorProfile;
