import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import ApartmentSharpIcon from "@material-ui/icons/ApartmentSharp";
import ContactMailSharpIcon from "@material-ui/icons/ContactMailSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import FaceSharpIcon from "@material-ui/icons/FaceSharp";
import { Input, Button, TextField } from "@material-ui/core/";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { SetLogout } from "~slices/Information";

const Logout = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const Handle_Logout = (e) => {
        dispatch(SetLogout());
        history.push(`/`);
        alert("ログアウトしました。");
    };
    return (
        <React.Fragment>
            <Typography variant="h1">Logout</Typography>
            <Grid container direction="column" justifyContent="space-between" alignItems="center">
                <Grid item style={{ marginTop: "5%" }}>
                    ログアウトしますか?
                </Grid>
                <Button
                    component="label"
                    variant="contained"
                    style={{ width: "45%", minWidth: "200px", marginTop: "10%" }}
                    onClick={Handle_Logout}
                    color="primary"
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ fontSize: "1rem" }}
                    >
                        <Grid item> ログアウト</Grid>
                        <Grid item>
                            <ExitToAppIcon style={{ fontSize: "80px" }} />
                        </Grid>
                    </Grid>
                </Button>
            </Grid>
        </React.Fragment>
    );
};

export default Logout;
