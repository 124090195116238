import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    favorite_list: [],
    talent: {
        login: process.env.MIX_TALENT_TOKEN ? true : false,
        email_address: "",
        talent_name: process.env.MIX_TALENT_NAME ? process.env.MIX_TALENT_NAME : "プロフィール",
        talent_id: process.env.MIX_TALENT_ID ? process.env.MIX_TALENT_ID : "T_00000000",
        password: "",
        prefectures: "",
        raw_year_month: "",
        sex: "",
        input_option: [],
        category: [],
    },
    sponsor: {
        login: process.env.MIX_COMPANY_TOKEN ? true : false,
        email_address: "",
        company_name: "スポンサー名",
        company_id: "C_00000009",
        password: "",
        prefectures: "",
    },

    dev: process?.env?.MIX_APP_ENV === "local" ? true : false,
    token: process.env.MIX_TALENT_TOKEN
        ? process.env.MIX_TALENT_TOKEN
        : process.env.MIX_COMPANY_TOKEN
        ? process.env.MIX_COMPANY_TOKEN
        : "",
    error_message: "",
};

//localStorageに保存したログイントークンを取得
export function getLocalStorage(id) {
    const value = localStorage.getItem(id);
    if (value !== null) {
        return value;
    }
    return "";
}
//localStorageにログイントークンを保存
export function saveLocalStorage(state, id) {
    if (state) {
        try {
            localStorage.setItem(id, state);
        } catch (error) {}
        //
    }
}

export const GetLoginToken = createAsyncThunk("共通/ログイントークンの取得", async (arg, thunkAPI) => {
    try {
        const response = await resolveAfter2Seconds();

        return response;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});
function resolveAfter2Seconds() {
    return new Promise((resolve) => {
        const localStorage_sponsor = getLocalStorage("localStorage_sponsor");
        const localStorage_talent = getLocalStorage("localStorage_talent");
        const login_token = getLocalStorage("localStorage_login_token");

        resolve({
            login_token: login_token,
            localStorage_sponsor: JSON.parse(localStorage_sponsor ? localStorage_sponsor : "{}"),
            localStorage_talent: JSON.parse(localStorage_talent ? localStorage_talent : "{}"),
        });
    });
}

// ***********タレントユーザー情報**************
export const GetTalentUserInfo = createAsyncThunk("タレント/ユーザー情報取得", async (arg, thunkAPI) => {
    const api_key = thunkAPI.getState().Information.token;
    const url = "/api/talent_selfinfo";

    try {
        const response = await axios.get(url, {
            headers: {
                "API-KEY": api_key,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const FixTalentUserInfo = createAsyncThunk("タレント/ユーザー情報更新", async (params, thunkAPI) => {
    let api_key = thunkAPI.getState().Information.token;

    if (!api_key) api_key = "";
    try {
        const res = await axios.put("/api/talent_selfinfo_fix", params, {
            headers: {
                "API-KEY": api_key,
            },
        });
        return res.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// ****************企業ユーザー情報******************
export const GetSponsorUserInfo = createAsyncThunk("企業/ユーザー情報取得", async (arg, thunkAPI) => {
    const api_key = thunkAPI.getState().Information.token;
    const url = "/api/company_detail";

    try {
        const response = await axios.get(url, {
            headers: {
                "API-KEY": api_key,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// ***********タレントログイン**************
export const SendMailTalent = createAsyncThunk("タレント/ログイン-メアド送信", async (params, thunkAPI) => {
    const headers = {};
    try {
        const res = await axios.post("/api/talent_login_mail", params, {
            headers: { ...headers },
        });
        return res.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const SendOnetimeTalent = createAsyncThunk("タレント/ログイン-ワンタイム送信", async (params, thunkAPI) => {
    const headers = {};
    try {
        const res = await axios.post("/api/talent_login_onetime", params, {
            headers: { ...headers },
        });
        return res.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const LoginTalent = createAsyncThunk("タレント/ログイン-パスワード送信", async (arg, thunkAPI) => {
    let url = "/api/talent_login_password";
    const params = arg;
    const headers = {};

    try {
        const response = await axios.post(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// ****************企業ログイン******************
export const SendMailSponsor = createAsyncThunk("企業/ログイン-メアド送信", async (params, thunkAPI) => {
    const headers = {};
    try {
        const res = await axios.post("/api/sponsor_login_mail", params, {
            headers: { ...headers },
        });
        return res.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const SendOnetimeSponsor = createAsyncThunk("企業/ログイン-ワンタイム送信", async (params, thunkAPI) => {
    const headers = {};
    try {
        const res = await axios.post("/api/sponsor_login_onetime", params, {
            headers: { ...headers },
        });
        return res.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const LoginSponsor = createAsyncThunk("企業/ログイン-パスワード送信", async (arg, thunkAPI) => {
    let url = "/api/sponsor_login_password";
    const params = arg;
    const headers = {};

    try {
        const response = await axios.post(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "Information",
    initialState,
    reducers: {
        Information_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        SetLogout: (state, action) => {
            state.talent.login = false;
            state.sponsor.login = false;
            state.token = "";
            localStorage.removeItem("localStorage_talent");
            localStorage.removeItem("localStorage_sponsor");
            localStorage.removeItem("localStorage_login_token");
        },
        SetLogin: (state, action) => {
            const user_type = action.payload[0];
            const token = action.payload[1];
            const id = action.payload[2];
            const name = action.payload[3];

            switch (user_type) {
                case "talent":
                    state.sponsor.login = false;
                    state.talent.login = true;
                    state.talent.talent_id = id;
                    state.talent.talent_name = name;
                    break;
                case "sponsor":
                    state.talent.login = false;
                    state.sponsor.login = true;
                    state.sponsor.company_id = id;
                    state.sponsor.company_name = name;
                    break;

                default:
                    alert("ログインに失敗しました。");
                    return;
            }
            state.token = token;
        },
    },
    extraReducers: {
        // タレントユーザー情報取得
        [GetTalentUserInfo.fulfilled]: (state, action) => {
            const payload = action.payload;

            if (payload.result === "OK") {
                state.talent = { ...state.talent, ...payload.user_info };
                state.talent.input_option = payload.input_option;
                state.talent.category = payload.category;
                state.talent.login = true;

                if (payload.sponsor !== null) {
                    saveLocalStorage(
                        JSON.stringify({ ...state.talent, ...payload.user_info, login: true }),
                        "localStorage_talent"
                    );
                } else {
                    localStorage.removeItem("localStorage_talent");
                }
            }
        },
        [GetTalentUserInfo.rejected]: (state, action) => {
            if (action.payload) {
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        // タレントユーザー情報更新
        [FixTalentUserInfo.rejected]: (state, action) => {
            if (action.payload) {
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        // 企業ユーザー情報取得
        [GetSponsorUserInfo.fulfilled]: (state, action) => {
            const payload = action.payload;

            if (payload.result === "1") {
                state.sponsor = { ...state.sponsor, ...payload.company_info };
                state.sponsor.login = true;

                if (payload.sponsor !== null) {
                    saveLocalStorage(JSON.stringify({ ...payload.company_info, login: true }), "localStorage_sponsor");
                } else {
                    localStorage.removeItem("localStorage_sponsor");
                }
            }
        },
        [GetSponsorUserInfo.rejected]: (state, action) => {
            if (action.payload) {
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        // タレントログイン-メアド送信
        [SendMailTalent.fulfilled]: (state, action) => {
            const payload = action.payload;
            console.debug(payload);
        },
        [SendMailTalent.rejected]: (state, action) => {
            if (action.payload) {
                console.debug(action);
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        // タレントログイン-ワンタイム送信
        [SendOnetimeTalent.fulfilled]: (state, action) => {
            const payload = action.payload;
            console.debug(payload);

            if (payload.result == "OK") {
            } else if (1 <= payload.failure_count && payload.failure_count < 5) {
                payload.error_message = "ワンタイムパスワードが違います。";
            } else {
                payload.error_message = payload.error_message + "　メールアドレス送信からもう一度やり直してください。";
            }
        },
        [SendOnetimeTalent.rejected]: (state, action) => {
            if (action.payload) {
                console.debug(action);
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        // タレントログイン-パスワード送信
        [LoginTalent.fulfilled]: (state, action) => {
            const payload = action.payload;
            console.debug(payload);

            if (payload.result == "OK") {
                state.sponsor.login = false;
                state.token = payload.user_token;
                console.debug("login成功");

                if (payload.user_token !== null) {
                    saveLocalStorage(payload.user_token, "localStorage_login_token");
                } else {
                    localStorage.removeItem("localStorage_login_token");
                }
            }
        },
        [LoginTalent.rejected]: (state, action) => {
            console.debug("loginrejected");
            if (action.payload) {
                console.debug(action);
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        // 企業ログイン-メアド送信
        [SendMailSponsor.fulfilled]: (state, action) => {
            const payload = action.payload;
            console.debug(payload);
        },
        [SendMailSponsor.rejected]: (state, action) => {
            if (action.payload) {
                console.debug(action);
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        // 企業ログイン-ワンタイム送信
        [SendOnetimeSponsor.fulfilled]: (state, action) => {
            const payload = action.payload;
            console.debug(payload);

            if (payload.result == "OK") {
            } else if (1 <= payload.failure_count && payload.failure_count < 5) {
                payload.error_message = "ワンタイムパスワードが違います。";
            } else {
                payload.error_message = payload.error_message + "　メールアドレス送信からもう一度やり直してください。";
            }
        },
        [SendOnetimeSponsor.rejected]: (state, action) => {
            if (action.payload) {
                console.debug(action);
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        // 企業ログイン-パスワード送信
        [LoginSponsor.fulfilled]: (state, action) => {
            const payload = action.payload;
            console.debug(payload);

            if (payload.result == "OK") {
                state.talent.login = false;
                state.token = payload.user_token;

                if (payload.user_token !== null) {
                    saveLocalStorage(payload.user_token, "localStorage_login_token");
                } else {
                    localStorage.removeItem("localStorage_login_token");
                }
            }
        },
        [LoginSponsor.rejected]: (state, action) => {
            if (action.payload) {
                console.debug(action);
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        [GetLoginToken.fulfilled]: (state, action) => {
            const payload = action.payload;

            if (payload?.login_token) {
                state.token = payload.login_token;
                if (payload?.localStorage_sponsor) {
                    state.sponsor = payload.localStorage_sponsor;
                }
                if (payload?.localStorage_talent) {
                    state.talent = payload.localStorage_talent;
                }
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Information_set, SetLogout, SetLogin } = slice.actions;
