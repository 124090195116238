import React from "react";
import { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core/";
import { errorHandler_set } from "~slices/ErrorHandler";
import Button from "@material-ui/core/Button";

import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ErrerModal = (props) => {
    const dispatch = useDispatch();

    const errorHandlerState = useSelector((state) => state.ErrorHandler);

    const handleClose = () => {
        dispatch(errorHandler_set(["error_flag", false]));
    };
    return (
        <>
            <Dialog open={errorHandlerState.error_flag} onClose={handleClose}>
                <DialogTitle style={{ background: "#fff" }}>エラーが発生しました。</DialogTitle>
                <DialogContent style={{ background: "#fff" }}>
                    <DialogContentText>{errorHandlerState.error_message}</DialogContentText>
                </DialogContent>
                <DialogActions style={{ background: "#fff" }}>
                    <Button onClick={handleClose} color="primary">
                        確認
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ErrerModal;
