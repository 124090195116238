import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "~CJS/atoms/BackButton";
import OfferDetailInput from "~/components/block/OfferDetailInput";
import { initialize, GetDetail, OfferFixApi } from "~stores/TALENTBANK/Offer";

const OfferDetailFix = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const path = history.location.pathname.split("/");
    const offer_id = path[3];

    const InformationState = useSelector((state) => state.Information);
    const OfferState = useSelector((state) => state.Offer);

    console.log(offer_id);

    useEffect(() => {
        dispatch(initialize());
        dispatch(GetDetail({ offer_id: offer_id }));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const elements = e.target?.elements;

        const getValue = (name) => {
            let value = "";
            if (elements[name]?.value) {
                value = elements[name]?.value;
            }

            return value;
        };

        const offer_info = {
            offer_id: offer_id,
            offer_title: getValue("offer_title"),
            prefectures: getValue("prefectures"),
            city: getValue("city"),
            guarantee: getValue("guarantee"),
            recruitment_count: getValue("recruitment_count"),
            job_start_time: getValue("job_start_time"),
            job_end_time: getValue("job_end_time"),
            job_detail: getValue("job_detail"),
            job_date: getValue("job_date"),
            image_big: getValue("image_big"),
            image: getValue("image"),
            entry_close: getValue("entry_close"),
        };
        const params = { offer_info: offer_info };

        dispatch(OfferFixApi(params)).then((data) => {
            history.push(`/common/OfferDetail/${offer_id}?status=1`);
        });
        
    };

    return (
        <React.Fragment>
            <Container>
                <BackButton history={history} />
                <Typography variant="h1">お仕事変更申請</Typography>

                <form onSubmit={handleSubmit}>
                    <OfferDetailInput data={OfferState.offer_detail} />
                    <Button fullWidth color="secondary" variant="contained" type="submit">
                        変更申請
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default OfferDetailFix;
