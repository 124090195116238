import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

const LINEAt = (props) => {
    const { link, image } = props;
    return (
        <React.Fragment>
            <Grid
                style={{
                    background: "#00B900",
                    borderRadius: "1rem",
                }}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid>
                    <Typography
                        align="center"
                        style={{
                            color: "#fff",
                            fontSize: "2rem",
                            margin: "1rem",
                        }}
                        variant="subtitle2"
                    >
                        LINE＠
                    </Typography>
                </Grid>
                <Grid>
                    <img src={image} style={{ maxWidth: "100%" }} />
                </Grid>
                <Grid
                    style={{
                        background: "rgb(255, 255, 255)",
                        margin: "1rem",
                        padding: "0.1rem 0.5rem",
                    }}
                >
                    <span style={{ fontWeight: "bolder" }}>
                        <a href={link}>{link}</a>
                    </span>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default LINEAt;
