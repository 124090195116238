import React from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { InputMaxLength } from "~CJS/atoms/TextFields";
import Grid from "@material-ui/core/Grid";
import { indexOf } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { initialize, BulkSendMessage } from "~stores/slices/common/Chat";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            width: "300px",
            padding: "15px",
            backgroundColor: "#fff",
        },
    },
    underLine: {
        borderBottom: "3px solid #35cb9b70",
        paddingBottom: "2px",
    },
    rating: {
        textAlign: "center",
        marginBottom: "1rem",
    },
    button: {
        border: "none",
        display: "block",
        margin: "auto",
    },
}));

const MessageDialog = (props) => {
    const { sendList = [] } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, set_open] = React.useState(false);

    const onClickSend = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        const message = elem?.message?.value;

        //  const res = confirm(`削除しますか?`);
        if (!message) return;

        const talent_id_list = sendList.map((item) => item["talent_id"]);
        const image = "";

        const params = {
            message_data: { message: message, image: image },
            talent_id_list: talent_id_list,
        };

        dispatch(BulkSendMessage(params)).then((data) => {
            if (data?.payload?.result == 1) {
                alert("送信が完了しました。");
            }
            set_open(false);
        });
    };

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={() => set_open(true)}
                className={classes.button}
                type="submit"
            >
                トーク 一斉送信
            </Button>
            <Dialog open={open} onClose={() => set_open(false)} className={classes.root}>
                <form onSubmit={onClickSend}>
                    <p style={{ fontSize: "1.1rem" }}>
                        <span className={classes.underLine}>メッセージ一括送信</span>
                    </p>
                    送信先
                    <Grid container>
                        {sendList.map((audition_data, index) => {
                            return (
                                <Grid key={index} container>
                                    <Grid item xs={1}>
                                        {index + 1}
                                    </Grid>
                                    <Grid item xs>
                                        {audition_data.talent_name}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <InputMaxLength
                        multiline
                        label="メッセージ"
                        name="message"
                        inputProps={{ maxLength: 10000 }}
                        minRows={5}
                    />
                    <Button variant="contained" color="primary" className={classes.button} type="submit">
                        送信
                    </Button>
                </form>
            </Dialog>
        </React.Fragment>
    );
};

export default MessageDialog;
