import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    audition_seet_list: [],
    offer_info: {
        recruitment_count: 0,
        audition_start: "",
        audition_close: "",
        audition_count: 0,
    },
    serch_info: {
        page_count: "",
        page: "",
        total: "",
        achievement_count_current: "",
    },
    audition_seet_detail: {},
    statusCondition: "all",
    page: 1,
};

export const GetAuditionSeetList = createAsyncThunk("オーディションシート一覧の取得", async (arg, thunkAPI) => {
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    const url = "/api/audition_seet_list";

    const params = arg;

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});
export const GetAuditionSeet = createAsyncThunk("オーディションシートの取得", async (arg, thunkAPI) => {
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    const url = "/api/audition_seet";

    const params = arg;

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const AuditionSeetCreateApi = createAsyncThunk("オーディションシートの作成", async (arg, thunkAPI) => {
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    const url = "/api/audition_seet_create";

    const params = arg;
    try {
        const response = await axios.post(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const SetAuditionSeetStatus = createAsyncThunk("オーディションシートの採用、不採用", async (arg, thunkAPI) => {
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    let url = "/api/audition_seet_status";

    const params = {
        ...arg, //必須
    };
    try {
        const response = await axios.put(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const ReviewTalent = createAsyncThunk("企業/タレント評価", async (params, thunkAPI) => {
    let api_key = thunkAPI.getState().Information.token;

    if (!api_key) api_key = "";
    try {
        const res = await axios.put("/api/talent_review", params, {
            headers: {
                "API-KEY": api_key,
            },
        });
        return res.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "AuditionSeet",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                return initialState;
            }
        },
        audition_seet_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },

    extraReducers: {
        [GetAuditionSeetList.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "1") {
                state.offer_info = resdata.offer_info;
                state.serch_info = resdata.serch_info;
                state.audition_seet_list = resdata.audition_seet_list;
            }
        },
        [GetAuditionSeet.fulfilled]: (state, action) => {
            const resdata = action.payload;

            if (resdata.result == "1") {
                state.audition_seet_detail = resdata.audition_seet_info;
                state.offer_info.job_date = resdata.offer_info.job_date;
            }
        },
        [GetAuditionSeetList.rejected]: (state, action) => {},
        [AuditionSeetCreateApi.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },
        [AuditionSeetCreateApi.rejected]: (state, action) => {},
        [SetAuditionSeetStatus.fulfilled]: (state, action) => {
            const resdata = action.payload;
        },
    },
});

export default slice.reducer;
export const { initialize, audition_seet_set } = slice.actions;
