import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import OptionInput from "~CJS/block/OptionInput";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useDispatch, useSelector } from "react-redux";
import TalentProfileInput from "~/components/block/TalentProfileInput";
import { GetTalentUserInfo } from "../../../stores/slices/Information";

const TalentProfile = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const informationState = useSelector((state) => state.Information);

    useEffect(() => {
        dispatch(GetTalentUserInfo());
    }, []);

    return (
        <React.Fragment>
            <Container>
                <ButtonNavi left_label="TOP" left_link="/" />
                <Typography variant="h1">プロフィール</Typography>
                <TalentProfileInput disabled {...informationState.talent} />
                <OptionInput disabled inputs={informationState.talent.input_option} />
                <LinkButton to="/talent/TalentProfileFix" label="編集" fullWidth />
            </Container>
        </React.Fragment>
    );
};

export default TalentProfile;
