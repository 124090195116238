import React, { useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getDateObj, getStringFromDate, getTargetDate } from "~CJS/functions/Time";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      margin: "0.8rem 0rem 0.5rem 0rem",
      width: "100%",
      background: "none",
      "& .Mui-disabled": {
        background: "none",
      },
    },
    linkText: {
      margin: "0.5rem 0rem",
      color: "mediumblue",
      width: "100%",
      "&:hover": {
        color: "blue",
        fontWeight: "600",
      },
    },
    title: {
      fontSize: 30,
    },
    button: {
      //margin: theme.spacing(1),
    },
  })
);

export const InputMail = (props) => {
  return (
    <ValidationText
      label="メールアドレス"
      name="email_address"
      inputProps={{
        maxLength: 254,
        pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*.)+[a-zA-Z]{2,}$",
      }}
      errorMessage="適切なメールアドレスを入力してください。"
      {...props}
    />
  );
};

export const InputMaxLength = (props) => {
  return <ValidationText errorMessage={`入力可能文字数は${props.inputProps.maxLength}です`} {...props} />;
};

//バリデーションチェック機能つきTextField
//inputProps={{ maxLength: 4, pattern: "^[a-zA-Z0-9_]+$" }}のような形式でバリデーションを指定する
//errorMessageでエラーメッセージを指定する
export const ValidationText = (props) => {
  const { onChange, errorMessage, link, disabled, value, onClick, defaultValue, ...other } = props;
  const inputRef = useRef(null);
  const [inputError, setInputError] = React.useState(false);
  const [input_value, setInputValue] = React.useState(value ? value : "");

  useEffect(() => {
    setInputValue(value || value == 0 ? value : "");
  }, [value]);
  useEffect(() => {
    if (!value) {
      setInputValue(defaultValue || defaultValue === 0 ? defaultValue : "");
    }
  }, [defaultValue]);

  const handleChange = (e) => {
    const ref = inputRef.current;
    e.target.setCustomValidity("");
    if (inputRef.current) {
      if (!ref.validity.valid) {
        errorMessage && e.target.setCustomValidity(errorMessage);
        setInputError(true);
      } else {
        setInputError(false);
      }
    }

    const needUpdate = onChange && onChange(e);
    // propsにonChangeが渡されていないとき/propsに渡されたonChangeからtrueが返されたとき
    if (!onChange || needUpdate) {
      setInputValue(e.target.value);
    }
  };

  const classes = useStyles();

  const handleClick = (e) => {
    console.log("█████████████████████████████████████");
    if (onClick) {
      onClick(e);
    }
    if (link && value) {
      window.open(value);
    }
  };

  if (disabled && link) {
    console.log(props);
  }
  return (
    <>
      {disabled && link ? (
        <>
          <InputLabel shrink={true} style={{ margin: "1rem" }}>
            {props.label}
          </InputLabel>
          <div style={{ margin: "1rem 1rem 0.5rem", cursor: "pointer" }}>
            <Link onClick={handleClick}>{props.value}</Link>
          </div>
          <Divider />
        </>
      ) : (
        <TextField
          inputRef={inputRef}
          id="standard-text"
          label=""
          fullWidth
          variant={disabled ? "filled" : "outlined"}
          className={`${classes.wrapText}`}
          onChange={handleChange}
          helperText={inputRef?.current?.validationMessage}
          InputLabelProps={{
            shrink: true,
          }}
          onClick={(e) => handleClick(e)}
          error={inputError}
          disabled={disabled}
          value={input_value}
          {...other}
        />
      )}
    </>
  );
};

export const InputDate = (props) => {
  const { onChange, disabled, value, defaultValue, ...other } = props;
  const classes = useStyles();
  const target_date = getTargetDate(defaultValue);
  const date_str = getStringFromDate(target_date, "YYYY-MM-DD");

  const [date, setDate] = React.useState(date_str);

  useEffect(() => {
    if (value) {
      setDate(value);
    } else {
      setDate(date_str);
    }
  }, [value]);

  return (
    <>
      <TextField
        label=""
        fullWidth
        type="date"
        variant={disabled ? "filled" : "outlined"}
        className={`${classes.wrapText}`}
        disabled={disabled}
        value={date}
        onChange={(e) => setDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        {...other}
      />
    </>
  );
};

export const InputTime = (props) => {
  const { onChange, disabled, value, defaultValue, ...other } = props;
  const classes = useStyles();
  const target_date = getTargetDate(defaultValue);
  const date_str = getStringFromDate(target_date, "YYYY-MM-DDThh:00");
  const [dateTime, setDateTime] = React.useState(date_str);
  useEffect(() => {
    if (value) {
      setDateTime(value.replace(" ", "T"));
    } else {
      setDateTime(date_str);
    }
  }, [value]);

  return (
    <>
      <TextField
        label=""
        fullWidth
        type="datetime-local"
        variant={disabled ? "filled" : "outlined"}
        className={`${classes.wrapText}`}
        disabled={disabled}
        value={dateTime}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => setDateTime(e.target.value)}
        {...other}
      />
    </>
  );
};
