import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { LinkButton } from "~CJS/atoms/LinkButton";
import OptionInput from "~CJS/block/OptionInput";
import FixedBand from "~CJS/atoms/FixedBand";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import { useDispatch, useSelector } from "react-redux";
import { VoteBlock } from "~/components/block/VoteBlock";
import TalentProfileInput from "~/components/block/TalentProfileInput";
import { initialize, GetDetail, ToggleFavoriteTalent } from "~stores/TALENTBANK/Talent";

const TalentDetail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const path = history.location.pathname.split("/");
    const talent_id = path[3];
    const thisState = useSelector((state) => state.Talent);
    const InformationState = useSelector((state) => state.Information);
    useEffect(() => {
        dispatch(initialize("talent_detail"));
        dispatch(GetDetail(talent_id));
    }, []);

    return (
        <React.Fragment>
            <Container className="talent-detail-page">
                <CustomButtonNavi talent_id={talent_id} />
                <Typography variant="h1">プロフィール</Typography>
                <VoteBlock talent_id={talent_id} />
                <TalentProfileInput disabled restricted {...thisState.talent_detail} />
                <OptionInput disabled inputs={thisState.talent_detail?.input_option} />
                <div
                    style={{
                        display: "block",
                        height: "3rem",
                    }}
                ></div>
            </Container>

            {InformationState?.sponsor?.login && (
                <FixedBand>
                    <LinkButton
                        label={
                            <>
                                <Typography align="center">このタレントとオファートーク</Typography>
                            </>
                        }
                        to={`/chat/Chat?talent_id=${talent_id}&&company_id=${InformationState?.sponsor?.company_id}`}
                        color="secondary"
                        style={{
                            width: "100%",
                            padding: "1rem 0rem",
                            margin: "0",
                        }}
                    />
                </FixedBand>
            )}
        </React.Fragment>
    );
};

const CustomButtonNavi = (props) => {
    const { talent_id } = props;
    const companyLogin = useSelector((state) => state.Information.sponsor.login);
    const isFavorite = useSelector((state) => state.Talent.talent_detail.isFavorite);
    const dispatch = useDispatch();
    const history = useHistory();

    // 前のページに戻る
    const GoBack = () => {
        history.goBack();
    };
    // お気に入りを切り替える
    const toggleFavorite = () => {
        const params = {
            talent_id: talent_id,
            active_flag: isFavorite ? "0" : "1", // 今お気に入り中なら無効にするため"0"を、そうでなければ"1"を送る
        };
        dispatch(ToggleFavoriteTalent(params));
    };

    return companyLogin ? (
        <ButtonNavi
            left_label="戻る"
            left_props={{ color: "primary", onClick: GoBack }}
            right_label={
                isFavorite ? (
                    <>
                        <FavoriteIcon />
                        お気に入り済み
                    </>
                ) : (
                    <>
                        <FavoriteBorderIcon />
                        お気に入り
                    </>
                )
            }
            right_props={{
                color: isFavorite ? "primary" : "default",
                onClick: toggleFavorite,
            }}
        />
    ) : (
        <ButtonNavi left_label="戻る" left_props={{ color: "primary", onClick: GoBack }} />
    );
};

export default TalentDetail;
