import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { InputMaxLength } from "~CJS/atoms/TextFields";
import InputImagePreview from "~CJS/atoms/InputImagePreview";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          color: "black",
          // opacity: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-disabled": {
          // color: "black",
          // opacity: 1,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        "&$shrink": {
          color: "black",
        },
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      color: "blue",
    },
  },
  likeInput: {
    marginBottom: "1rem",
    border: "solid 1px #CCC",
    borderRadius: "0.2rem",
    "&:hover": {
      border: "solid 1px #111",
    },
  },
  likeInputDisabled: {
    minHeight: "4rem",
    padding: "1rem",
    marginBottom: "1rem",
    border: "solid 1px #CCC",
    borderRadius: "0.2rem",
  },
}));
const OptionInput = (props) => {
  const { disabled, selfFixed, inputs, children, ...other } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <>
          {inputs.map((value, index) => {
            if (selfFixed && value.input_authority === "3") return;

            return (
              <React.Fragment key={index}>
                {/*種類がテキストのとき (1:text,2:link,3:image)  */}
                {(value.input_value || !disabled) && (
                  <>
                    {value.type == "1" && (
                      <InputMaxLength
                        label={value.input_label}
                        name={value.input_id}
                        inputProps={{ maxLength: value.maxlength }}
                        multiline
                        disabled={disabled}
                        value={value.input_value}
                      />
                    )}
                    {/*種類がlinkのとき (1:text,2:link,3:image)  */}
                    {value.type == "2" && (
                      <InputMaxLength
                        label={value.input_label}
                        name={value.input_id}
                        inputProps={{ maxLength: value.maxlength }}
                        disabled={disabled}
                        link
                        value={value.input_value}
                      />
                    )}
                    {/*種類がimageのとき (1:text,2:link,3:image)  */}
                    {value.type == "3" && (
                      <>
                        {(value?.input_value?.length > 10 || !disabled) && (
                          <div className={disabled ? classes.likeInputDisabled : classes.likeInput}>
                            <InputImagePreview
                              comment={false}
                              name={value.input_id}
                              label={value.input_label}
                              width={500}
                              height={500}
                              image_big={value.input_value}
                              image={value.input_value}
                              disabled={disabled}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default OptionInput;
