import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import { LinkButton } from "~CJS/atoms/LinkButton";
import Button from "@material-ui/core/Button";
import TalentProfileInput from "~/components/block/TalentProfileInput";
import OptionInput from "~CJS/block/OptionInput";
import { useDispatch, useSelector } from "react-redux";
import { GetTalentUserInfo, FixTalentUserInfo } from "../../../stores/slices/Information";
import { useHistory } from "react-router-dom";

const TalentProfileFix = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const informationState = useSelector((state) => state.Information);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        // 基本情報
        const talentInfo = {
            height: elem.height.value,
            prefectures: elem.prefectures.value,
            city: elem.city.value,
            address_1: elem.address_1.value,
            address_2: elem.address_2.value,
            skill: elem.skill.value,
            talent_pr: elem.talent_pr.value,
        };
        // 追加情報
        let inputOption = [];
        informationState.talent.input_option.map((value) => {
            inputOption.push({
                input_id: value.input_id,
                value: elem[value.input_id]?.value,
            });
        });

        const params = {
            talent_info: talentInfo,
            input_option: inputOption,
        };
        dispatch(FixTalentUserInfo(params)).then((res) => {
            if (res.payload?.result === "OK") {
                alert("更新しました。");
                history.push("/talent/TalentProfile");
            }
        });
    };

    useEffect(() => {
        dispatch(GetTalentUserInfo());
    }, []);

    return (
        <Container>
            <Typography variant="h1">プロフィール編集</Typography>
            <form onSubmit={HandleSubmit}>
                {/* <FormControl noValidate autoComplete="off" fullWidth> */}
                <TalentProfileInput selfFixed {...informationState.talent} />
                <OptionInput selfFixed inputs={informationState.talent.input_option} />
                <LinkButton to="/talent/TalentProfile" label="キャンセル" color="secondary" variant="outlined" />
                <Button variant="contained" color="secondary" type="submit">
                    変更
                </Button>
                {/* </FormControl> */}
            </form>
        </Container>
    );
};

export default TalentProfileFix;
