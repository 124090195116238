import Chip from "@material-ui/core/Chip";
import React from "react";

const TagList = (props) => {
  const { tag_list, tag_select_list, TagClick, onDelete, ...other } = props;

  const HandleDelete = (value) => {
    if (onDelete) {
      onDelete(value.category_name, value.category_id);
    } else {
      TagClick(value.category_name, value.category_id);
    }
  };
  return (
    <React.Fragment>
      <div>
        {tag_list.map((value, index) => (
          <Chip
            key={index}
            label={value.category_name}
            name={value.category_id}
            onClick={() => TagClick(value.category_name, value.category_id)}
            onDelete={() => HandleDelete(value)}
            style={{ borderRadius: "unset", margin: "0.2rem" }}
            color={
              tag_select_list.find((v) => v.category_name == value.category_name) ||
              tag_select_list.find((v) => v == value.category_name)
                ? "primary"
                : "default"
            }
            {...other}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default TagList;
