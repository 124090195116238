import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
export const initialState = {
    talent_list: [
        // {
        //     talent_id: "",
        //     name: "",
        //     image: "",
        //     new_flag: false,
        //     excellent_flag: false,
        // },
    ],
    talent_serch: {
        page: 0,
        page_count: 0,
        total: 0,
    },
    search_condition: {
        prefectures: "未選択",
        city: "未選択",
        age: "0,80",
        height: "50,200",
        adoptsum_range: "0,99999",
        evaluation_range: "0,5",
        tag_select_list: [],
        order: "採用件数順",
    },
    talent_detail: {
        talent_name: "",
        age: "",
        height: "",
        weight: "",
        sns_link: "",
        talent_pr: "",
        prefectures: "未選択",
        image_big: "",
        image: "",
        isFavorite: false,
        input_option: [],
        category: [],
    },
};
const debug = true;

const debugLog = (arg) => {
    if (!debug) return;
    console.debug(arg);
};
export const GetTalentList = createAsyncThunk("Talent/GetTalentList", async (arg, thunkAPI) => {
    const url = "/api/talent_list";
    const params = arg;

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: {
                "API-KEY": thunkAPI.getState().Information.token,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const GetDetail = createAsyncThunk("Talent/GetDetail", async (arg, thunkAPI) => {
    let url = "/api/talent_detail";

    const params = {
        talent_id: arg, //必須
    };

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: {
                "API-KEY": thunkAPI.getState().Information.token,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const ToggleFavoriteTalent = createAsyncThunk("企業/タレントお気に入り登録/解除", async (params, thunkAPI) => {
    let url = "/api/favorite_talent";
    const headers = {
        "API-KEY": thunkAPI.getState().Information.token,
    };
    try {
        const response = await axios.post(url, params, {
            headers: { ...headers },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const TalentCreate = createAsyncThunk("Talent/TalentCreate", async (arg, thunkAPI) => {
    const api_key = "";
    let url = "/api/talent_create";

    const params = {
        ...arg, //必須
    };
    try {
        const response = await axios.post(url, params, {
            headers: {
                "API-KEY": api_key,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const TalentFix = createAsyncThunk("Talent/TalentFix", async (arg, thunkAPI) => {
    const api_key = "";
    let url = "/api/talent_fix";

    const params = {
        ...arg, //必須
    };
    try {
        const response = await axios.put(url, params, {
            headers: {
                "API-KEY": api_key,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const TalentActive = createAsyncThunk("Talent/TalentActive", async (arg, thunkAPI) => {
    const api_key = "";
    let url = "/api/talent_active";

    const params = {
        ...arg, //必須
    };

    try {
        const response = await axios.delete(url + "?" + new URLSearchParams(params), {
            headers: {
                "API-KEY": api_key,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});
export const TalentDelete = createAsyncThunk("Talent/TalentDelete", async (arg, thunkAPI) => {
    const api_key = thunkAPI.getState().Information.admin.admin_token;
    let url = "/api/talent_delete";

    const params = {
        ...arg, //必須
    };

    try {
        const response = await axios.delete(url + "?" + new URLSearchParams(params), {
            headers: {
                "API-KEY": api_key,
                "content-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});
function addItem(array, item) {
    return Array.from(new Set([...array, item])); //重複データが入らないようにするための対応
}

// Sliceを生成する
const slice = createSlice({
    name: "TalentDetail",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                return initialState;
            }
        },
        talent_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetTalentList.fulfilled]: (state, action) => {
            const resdata = action.payload;
            state.talent_serch.page_count = resdata?.serch_info?.page_count;
            state.talent_serch.page = resdata?.serch_info?.page;
            state.talent_serch.total = resdata?.serch_info?.total;
            state.talent_list = resdata.talent_list;
            // 検索条件保持
            state.search_condition.prefectures = action.meta.arg.prefectures;
            state.search_condition.city = action.meta.arg.city;
            state.search_condition.age = action.meta.arg.age;
            state.search_condition.height = action.meta.arg.height;
            state.search_condition.adoptsum_range = action.meta.arg.adoptsum_range;
            state.search_condition.evaluation_range = action.meta.arg.evaluation_range;
            state.search_condition.tag_select_list = action.meta.arg.tag_select_list;
            state.search_condition.order = action.meta.arg.order;
        },
        [GetTalentList.rejected]: (state, action) => {
            console.log("検索に失敗しました。");
        },
        [GetDetail.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (action.payload.result == "1") {
                state.talent_detail = resdata?.talent_info;
                state.talent_detail.input_option = resdata?.input_option;
                state.talent_detail.category = resdata?.category;
                state.talent_detail.isFavorite = resdata?.is_favorite;
            }
        },
        [ToggleFavoriteTalent.fulfilled]: (state, action) => {
            if (action.payload.result === "OK") {
                state.talent_detail.isFavorite = action.payload.is_favorite;
            }
        },
        [ToggleFavoriteTalent.rejected]: (state, action) => {
            if (action.payload) {
            } else {
                action.payload = { error_message: "時間をおいてもう一度お試しください。" };
            }
        },
        [TalentCreate.pending]: (state, action) => {
            // console.log("====33333333333================");
            // console.log(action.meta.arg);
            // console.log("====================");
            // state.talent_detail = action.meta.arg;
        },
        [TalentCreate.fulfilled]: (state, action) => {},
        [TalentCreate.rejected]: (state, action) => {},
        [TalentFix.fulfilled]: (state, action) => {},
        [TalentFix.rejected]: (state, action) => {},
        [TalentDelete.fulfilled]: (state, action) => {},
        [TalentActive.fulfilled]: (state, action) => {},
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, talent_set } = slice.actions;
//
// message
// timestamp
// photoURL
// displayName
// avatarDisp
//
//
//
//
