import React, { useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import SelectCity from "~CJS/atoms/SelectCity";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    background: "none",
    "& .Mui-disabled": {
      background: "none",
    },
    "& svg": {
      display: "none",
    },
  },
});
export const prefectures_list = [
  {
    label: "北海道",
    pref: ["北海道"],
  },
  {
    label: "東北",
    pref: ["青森県", "岩手県", "秋田県", "宮城県", "山形県", "福島県"],
  },
  {
    label: "関東",
    pref: ["茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県"],
  },
  {
    label: "中部",
    pref: ["山梨県", "長野県", "新潟県", "富山県", "石川県", "福井県", "静岡県", "愛知県", "岐阜県"],
  },
  {
    label: "近畿",
    pref: ["三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県"],
  },
  {
    label: "中国",
    pref: ["鳥取県", "島根県", "岡山県", "広島県", "山口県"],
  },
  {
    label: "四国",
    pref: ["香川県", "愛媛県", "徳島県", "高知県"],
  },
  {
    label: "九州",
    pref: ["福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"],
  },
];

const SelectPrefectures = (props) => {
  const { disabled, value, city, nocity, ...other } = props;
  const { cityValue, ...otherCity } = city;
  const classes = useStyles();

  const [prefectures, set_prefectures] = React.useState(value);
  const [cities, set_cities] = React.useState(cityValue);

  useEffect(() => {
    set_prefectures(value);
    set_cities(cityValue);
  }, [value, cityValue]);

  return (
    <>
      <TextField
        key={`${Math.floor(Math.random() * 1000)}-prefectures`}
        style={{ width: "100%", marginBottom: "1rem" }}
        id="select-prefecture"
        select
        className={classes.root}
        name="prefectures"
        label="都道府県"
        fullWidth
        variant="outlined"
        variant={disabled ? "filled" : "outlined"}
        SelectProps={{
          native: true,
        }}
        disabled={disabled}
        onChange={(e) => set_prefectures(e.currentTarget.value)}
        value={prefectures}
        {...other}
      >
        <option value={"未選択"}>未選択</option>
        {prefectures_list.map((area, index) => (
          <optgroup key={index} label={area.label}>
            {area.pref.map((prefecture, index_in) => (
              <option key={index_in} value={prefecture}>
                {prefecture}
              </option>
            ))}
          </optgroup>
        ))}
      </TextField>
      {!nocity && (
        <SelectCity
          className={classes.root}
          key={`${Math.floor(Math.random() * 1000)}-city`}
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          prefectures={prefectures}
          value={cities}
          onChange={(e) => set_cities(e.currentTarget.value)}
          {...otherCity}
        />
      )}
    </>
  );
};

SelectPrefectures.defaultProps = {
  city: {},
};

export default SelectPrefectures;
