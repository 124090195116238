import React from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import LoginMailForm from "~/components/block/LoginMailForm";
import LoginPasswordForm from "~/components/block/LoginPasswordForm";
import LoginStepper from "~/components/block/LoginStepper";
import LoginOnetimeForm from "~/components/block/LoginOnetimeForm";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { BackButton } from "~CJS/atoms/BackButton";
import { SendMailTalent, SendOnetimeTalent, LoginTalent, GetTalentUserInfo } from "~slices/Information";

const TalentLogin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [activeStep, set_activeStep] = React.useState(0);
    const [email_address, set_email_address] = React.useState(0);

    const SendMaid = (e) => {
        e.preventDefault();
        const email_address = e.target.elements.email_address.value;
        set_email_address(email_address);
        dispatch(SendMailTalent({email_address: email_address})).then((res) => {
            if (res.payload?.result === "OK") {
                set_activeStep(1);
            }
        });
        
    };

    const SendOnetime = (e) => {
        e.preventDefault();
        const onetime = e.target.elements.onetime_password.value;
        const params = {
            email_address: email_address,
            one_time: onetime,
        };
        dispatch(SendOnetimeTalent(params)).then((res) => {
            if (res.payload?.result === "OK") {
                set_activeStep(2);
            }
        });
    };

    const Login = (e) => {
        e.preventDefault();
        const password = e.target.elements.password.value;
        dispatch(
            LoginTalent({
                password: password,
                email_address: email_address,
            })
        )
        .then((res) => {
            if (res.payload?.result === "OK") {
                dispatch(GetTalentUserInfo());
                set_activeStep(3);
            }
        });

        
    };
    return (
        <Container>
            <Grid container>
                <BackButton history={history} />
                <Typography variant="h1">タレントログイン</Typography>
                <LoginStepper activeStep={activeStep} />

                {activeStep == 0 && <LoginMailForm HandleSubmit={SendMaid} />}
                {activeStep == 1 && <LoginOnetimeForm HandleSubmit={SendOnetime} />}
                {activeStep == 2 && <LoginPasswordForm HandleSubmit={Login} />}
                {activeStep == 3 && <LoginComplete />}

            </Grid>
        </Container>
    );
};

const LoginComplete = () => {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography>ログインしました。</Typography>
            <LinkButton label="TOP画面へ" to="/" />
        </Grid>
    );
};

export default TalentLogin;
