import React, { useRef, useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
// import OfferCard from "~/components/block/OfferCard";

import Link from "@material-ui/core/Link";

import Chat from "~/components/block/Chat";

import { GetTop, initialize } from "~stores/TALENTBANK/Top";

import { useDispatch, useSelector } from "react-redux";

import { LinkButton } from "~CJS/atoms/LinkButton";

import { LinkPanel } from "~CJS/atoms/LinkButton";
import { ImageSwiper } from "~/components/block/ImageSwiper";
import ImageList from "~/components/block/ImageList";
import { map } from "jquery";

import { format } from "date-fns";
import { ja } from "date-fns/locale";

import InfoIcon from "@material-ui/icons/Info";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";

import {
    TwitterTimelineEmbed,
    TwitterShareButton,
    TwitterFollowButton,
    TwitterHashtagButton,
    TwitterMentionButton,
    TwitterTweetEmbed,
    TwitterMomentShare,
    TwitterDMButton,
    TwitterVideoEmbed,
    TwitterOnAirButton,
} from "react-twitter-embed";
// import "/css/top/base.css";
// import "/css/top/reset.css";
// import "/css/top/common.css";
// import "/css/top/slick-theme.css";
// import "/css/top/slick.css";

// import "../../../css/top/base.css"

const Top = () => {
    const dispatch = useDispatch();
    const TalentListState = useSelector((state) => state.TalentList);
    const TopState = useSelector((state) => state.Top);

    // const handleChange = (e) => {
    //     dispatch(GetTop());
    // };

    useEffect(() => {
        if (TopState.talent_list.length < 1) {
            dispatch(initialize());
            dispatch(GetTop());
        }
    }, []);
    const preventDefault = (event) => event.preventDefault();

    return (
        <React.Fragment>
            {/* <Typography variant="h1">TOP</Typography> */}

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className="Top"
                style={{
                    background: "#333333",
                }}
            >
                {TopState.slide_list && <ImageSwiper data={TopState.slide_list} />}
                {/* <img src="/images/talentBank/TopShot/上001.png" style={{ width: "100%" }} /> */}

                <div
                    style={{
                        position: "relative",
                    }}
                >
                    <a href="https://talent-park.net/statics/company/index.html">
                        <img
                            src="/images/talentBank/TopShot/wappen.png"
                            style={{
                                width: "15vw",
                                maxWidth: "200px",
                                position: "absolute",
                                top: "25px",
                                right: "25px",
                            }}
                        />
                    </a>
                    <img src="/images/talentBank/TopShot/top上右上リンクなし.jpeg" style={{ width: "100%" }} />
                </div>
                <Grid
                    container
                    style={{ background: "#FCAD37" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <NewsList data={TopState.news_list} />
                </Grid>

                {/* <Grid
                    container
                    style={{ background: "#333333" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <ImageList />
                </Grid> */}

                <Grid
                    container
                    style={{ fontFamily: "'M PLUS Rounded 1c', sans-serif", background: "#FCAD37" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {/* <img src="/images/talentBank/TopShot/上.png" style={{ width: "100%" }} /> */}
                    {/* <img src="/images/talentBank/TopShot/中001.jpeg" style={{ width: "90%" }} /> */}

                    {/* <Button
                        style={{
                            background: "#85dbf0",
                            display: "block",
                            // fontSize: "2vw",
                            fontWeight: "900",
                            padding: "10px 30px",
                            border: "none",
                            width: "80%",
                            textAlign: "center",
                            fontSize: "min(2vw,4rem)",
                            marginTop: "2rem",
                            marginButtom: "2rem",
                        }}
                        href="https://line.me/R/ti/p/@209dugak"
                        target="_blank"
                    >
                        タレントパークにLINEで応募！
                    </Button> */}
                </Grid>

                {/* <img src="/images/talentBank/TopShot/中002.jpeg" style={{ width: "100%" }} /> */}
                {/* <img src="/images/talentBank/TopShot/T02.png" style={{ width: "100%" }} /> */}

                {/* <img src="/images/talentBank/TopShot/五ステップ.jpeg" style={{ width: "100%" }} /> */}
                {/* <img src="/images/talentBank/TopShot/登録とお仕事依頼の仕組み.jpeg" style={{ width: "100%" }} /> */}
                {/* <img src="/images/talentBank/TopShot/F01.jpeg" style={{ width: "100%" }} /> */}

                {/* <img src="/images/talentBank/TopShot/下001.jpeg" style={{ width: "90%" }} /> */}
                {/* LINEで応募！ */}
                {/* <Button
                    style={{
                        background: "#fff",
                        display: "block",
                        // fontSize: "2vw",
                        marginBottom: "1rem",
                        marginTop: "2rem",
                        fontWeight: "900",
                        padding: "10px 30px",
                        border: "none",
                        width: "60%",
                        textAlign: "center",
                        fontSize: "min(2vw,4rem)",
                    }}
                    href="https://line.me/R/ti/p/@209dugak"
                    target="_blank"
                >
                    
                    <img src="/images/talentBank/TopShot/下button01.jpeg" style={{ width: "100%" }} />
                </Button> */}
                <img src="/images/talentBank/TopShot/下002.jpeg" style={{ width: "100%" }} />

                <TopFooter />

                {/* <TopMain /> */}
            </Grid>
        </React.Fragment>
    );
};

// const TopStyle = (props) => {
//     return <style jsx></style>;
// };
// const TopMain = (props) => {
//     return <main>ss</main>;
// };
const NewsList = (props) => {
    const { data = [], ...other } = props;
    // const data = [];
    // const data_array = [...data];
    // const half = Math.ceil(data_array.length / 2);

    // const left_data = data_array.splice(0, half);
    // const right_data = data_array.splice(0, half);

    return (
        <>
            {data?.length ? (
                <Grid container justifyContent="flex-start">
                    <Typography style={{ color: "#fff", paddingLeft: "0.5rem", fontSize: "2rem" }}>
                        ♦ News ♦
                    </Typography>
                </Grid>
            ) : (
                ""
            )}

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                // alignItems="start"
                // style={{ borderTop: "solid 1px gray", borderBottom: "solid 1px gray" }}
            >
                <List>
                    {data.map((news, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start" style={{ paddingLeft: "0.5rem" }}>
                                <ListItemAvatar style={{ minWidth: "10px", paddingRight: "2px", marginTop: "5px" }}>
                                    <InfoIcon fontSize="small" style={{ color: "#fff", fontSize: "1.5rem" }} />
                                </ListItemAvatar>
                                {/* <ListItemText
                                    style={{ fontSize: "0.5rem" }}
                                    // primary={format(new Date(news?.created_at), "MMM do EEEE", { locale: ja })}
                                    secondary={
                                        <React.Fragment> */}
                                {/* <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                style={{ fontSize: "0.8rem" }}
                                            > */}
                                <div
                                    style={{ fontSize: "1.2rem", marginTop: "0.2rem", color: "#fff" }}
                                    className="news_content_div"
                                    dangerouslySetInnerHTML={{
                                        __html: news?.content.replace(/\n/g, "<br>"),
                                    }}
                                ></div>

                                {/* {news?.content} */}
                                {/* </Typography> */}
                                {/* </React.Fragment> */}
                                {/* } */}
                                {/* /> */}
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    ))}
                </List>
                <Grid
                    container
                    style={{ background: "#FCAD37" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <div style={{ marginRight: "auto", marginLeft: "auto", padding: "0.2rem", width: "100%" }}>
                        <TwitterTimelineEmbed
                            onLoad={function noRefCheck() {}}
                            options={{
                                height: 500,
                                //     // width: 600,
                                //     maxWidth: 1200,
                                //     minWidth: 500,
                            }}
                            sourceType="timeline"
                            userId={"1614847623906885633"}
                            // userId={"1279418164397305862"}
                        />
                    </div>
                </Grid>
                {/* <Grid item xs={6}>
                    {left_data.map((news, index) => (
                        <List key={index}>
                            <ListItem alignItems="flex-start" style={{ padding: "1px" }}>
                                <ListItemText
                                    style={{ fontSize: "0.2rem" }}
                                    primary={format(new Date(news?.created_at), "MMM do EEEE", { locale: ja })}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                style={{ fontSize: "0.2rem" }}
                                            >
                                                {news?.content}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </List>
                    ))}
                </Grid>
                <Grid item xs={6}>
                    {right_data.map((news, index) => (
                        <List key={index}>
                            <ListItem alignItems="flex-start" style={{ padding: "1px" }}>
                                <ListItemText
                                    primary={format(new Date(news?.created_at), "MMM do EEEE", { locale: ja })}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                style={{ fontSize: "0.2rem" }}
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                fontSize={"1px"}
                                            >
                                                {news?.content}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </List>
                    ))}
                </Grid> */}
            </Grid>
        </>
    );
};
const TopFooter = (props) => {
    return (
        <Grid container style={{ background: "#333333", padding: "1rem", paddingBottom: "5vw" }}>
            <footer style={{ width: "100%" }}>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <a href="https://www.instagram.com/hybridbank_officialmedia/" target="_blank">
                            <img
                                src="/images/talentBank/Top/foot_icon01.png"
                                alt="instagram"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.facebook.com/hybridbank" target="_blank">
                            <img
                                src="/images/talentBank/Top/foot_icon02.png"
                                alt="facebook"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://twitter.com/hybridbank_jp?s=21&t=uoTxam5Y2jYBP9hjCOpvLA" target="_blank">
                            <img
                                src="/images/talentBank/Top/foot_icon03.png"
                                alt="twitter"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a
                            href="https://www.tiktok.com/@hybridbank_official?_d=secCgYIASAHKAESPgo8dgssuuZKkLoLj0Px%2Fospek6P2NaaSVoheXpy2ND1IuTcRPH%2BeHYy6DH6NBmhTg1N0fYEuNxDQ%2FE7P4ioGgA%3D&_r=1&checksum=9a7f278c48702ba363800c64fa71ba04306b1fe8274609cf5962930fc0fa7893&language=ja&sec_uid=MS4wLjABAAAANUK33aTxFBsx66wy8cRADk2FgkJpGbwvVDt5_ZAdYJpqzs5TQ3N7GHGEOoNoCE3_&sec_user_id=MS4wLjABAAAANUK33aTxFBsx66wy8cRADk2FgkJpGbwvVDt5_ZAdYJpqzs5TQ3N7GHGEOoNoCE3_&share_app_id=1180&share_author_id=6968760698750993410&share_link_id=EFAAEEC0-6E44-4056-8132-78C3CBB74C47&source=h5_t&tt_from=copy&u_code=dimkj28l507ibg&user_id=6968760698750993410&utm_campaign=client_share&utm_medium=ios&utm_source=copy"
                            target="_blank"
                        >
                            <img
                                src="/images/talentBank/Top/foot_icon04.png"
                                alt="tictok"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.youtube.com/user/modelouendan" target="_blank">
                            <img
                                src="/images/talentBank/Top/foot_icon05.png"
                                alt="youtube"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                </Grid>
            </footer>
        </Grid>
    );
};

export default Top;
