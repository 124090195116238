import React, { useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      margin: "0.5rem 0rem",
      width: "100%",
      background: "none",
      "& .Mui-disabled": {
        background: "none",
      },
      "& svg": {
        display: "none",
      },
    },
    linkText: {},
    button: {},
  })
);

const InputAge = (props) => {
  const { onChange, errorMessage, link, disabled, value, onClick, ...other } = props;
  const inputRef = useRef(null);
  const [inputError, setInputError] = React.useState(false);

  const [age, setAge] = React.useState(value ? value : "");

  useEffect(() => {
    if (disabled) {
      const birthdate = value;
      const ageCalculation = (birthDate, nowDate) => {
        const age = nowDate.getFullYear() - birthDate.getFullYear();
        const thisYearsBirthday = new Date(nowDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());
        return age + (thisYearsBirthday.getTime() > nowDate.getTime() ? -1 : 0);
      };
      if (value) {
        const age = ageCalculation(new Date(birthdate), new Date());

        setAge(age);
      }
    }
  }, []);
  useEffect(() => {
    if (disabled && value) {
      const birthdate = value;
      const ageCalculation = (birthDate, nowDate) => {
        const age = nowDate.getFullYear() - birthDate.getFullYear();
        const thisYearsBirthday = new Date(nowDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());
        return age + (thisYearsBirthday.getTime() > nowDate.getTime() ? -1 : 0);
      };
      if (value) {
        const age = ageCalculation(new Date(birthdate), new Date());

        setAge(age);
      }
    }
  }, [value]);
  const handleChange = (e) => {
    const ref = inputRef.current;
    setAge(e.target.value);
    e.target.setCustomValidity("");
    if (inputRef.current) {
      if (!ref.validity.valid) {
        errorMessage && e.target.setCustomValidity(errorMessage);
        setInputError(true);
      } else {
        setInputError(false);
      }
    }

    onChange && onChange(e);
  };

  const classes = useStyles();

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (link && value) {
      window.open(value);
    }
  };

  const OnAgePrivate = (e, val) => {
    console.log(val);
    if (val) {
      setAge("");
    }
  };

  return (
    <>
      {disabled ? (
        <>
          {age ? (
            <TextField
              inputRef={inputRef}
              id="standard-text"
              fullWidth
              variant={disabled ? "filled" : "outlined"}
              className={`${classes.wrapText}`}
              onChange={handleChange}
              helperText={inputRef?.current?.validationMessage}
              InputProps={{
                classes: {
                  input: classes.linkText,
                },
              }}
              onClick={handleClick}
              error={inputError}
              disabled={disabled}
              {...other}
              label="年齢"
              value={age}
              type="text"
            />
          ) : (
            <>
              <TextField
                fullWidth
                className={`${classes.wrapText}`}
                variant={disabled ? "filled" : "outlined"}
                InputProps={{
                  classes: {
                    input: classes.linkText,
                  },
                }}
                disabled={disabled}
                {...other}
                label="年齢"
                value="非公開"
                type="text"
              />
            </>
          )}
        </>
      ) : (
        <>
          <TextField
            inputRef={inputRef}
            id="standard-text"
            label=""
            fullWidth
            variant={disabled ? "filled" : "outlined"}
            className={`${classes.wrapText}`}
            onChange={handleChange}
            helperText={inputRef?.current?.validationMessage}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                input: classes.linkText,
              },
            }}
            onClick={handleClick}
            error={inputError}
            {...other}
            value={age}
          />

          <FormControlLabel
            control={<Checkbox name="age_private" onChange={OnAgePrivate} checked={age ? false : true} />}
            label="年齢非公開"
          />
        </>
      )}
    </>
  );
};

export default InputAge;
