import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// Stateの初期状態
const initialState = {
    talent_list: [],
    slide_list: [],
    news_list: [],
};

export const GetTop = createAsyncThunk("共通/TOP情報取得", async (arg, thunkAPI) => {
    const url = "/api/top";

    const params = arg;
    const headers = {};

    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: { ...headers },
        });
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "Top",
    initialState,
    reducers: {
        initialize: (state, action) => {
            if (action.payload) {
                state[action.payload] = initialState[action.payload];
            } else {
                initialState;
            }
        },
        top_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: {
        [GetTop.fulfilled]: (state, action) => {
            const resdata = action.payload;
            if (resdata.result == "1") {
                state.talent_list = resdata.talent_list ? resdata.talent_list : [];
                state.slide_list = resdata.slide_list ? resdata.slide_list : [];
                state.news_list = resdata?.news_list ? resdata.news_list : [];
            }
        },
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, top_set } = slice.actions;
