import React from "react";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Grid from "@material-ui/core/Grid";

const SelectRatings = (props) => {
  const { name, value, ...other } = props;
  
  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Rating
          name={name}
          value={Number(value)}
          defaultValue={0}
          precision={0.5}
          emptyIcon={<StarBorderIcon fontSize="inherit" />}
          {...other}
        />
      </Grid>
    </>
  );
};

export default SelectRatings;
