import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { useDispatch, useSelector } from "react-redux";
import ApartmentSharpIcon from "@material-ui/icons/ApartmentSharp";
import ContactMailSharpIcon from "@material-ui/icons/ContactMailSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import FaceSharpIcon from "@material-ui/icons/FaceSharp";
import { LinkPanel, LinkImage } from "~CJS/atoms/LinkButton";

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Typography variant="h1">ログイン</Typography>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                style={{
                    paddingBottom: "10rem",
                    marginTop: "auto",
                    marginBottom: "auto",
                }}
            >
                <LinkPanel
                    label="タレント"
                    to="/login/TalentLogin"
                    icon={<FaceSharpIcon style={{ fontSize: "80px" }} />}
                />
                <LinkPanel
                    label="企業"
                    to="/login/SponsorLogin"
                    icon={<ApartmentSharpIcon style={{ fontSize: "80px" }} />}
                />

                {/* <Typography variant="h1">新規登録</Typography> */}
                <LinkPanel
                    label="タレント新規登録"
                    to="/login/TalentSignUp"
                    icon={<ContactMailSharpIcon style={{ fontSize: "80px" }} />}
                />
                <LinkPanel
                    label="企業新規登録"
                    to="/login/SponsorSignUp"
                    icon={<EditSharpIcon style={{ fontSize: "80px" }} />}
                />

                {/* <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <LinkImage to="/login/TalentLogin">
                        <img
                            src="/images/talentBank/LOGIN/タレントログイン.jpg"
                            style={{ width: "150px", height: "150px" }}
                        />
                    </LinkImage>
                    <LinkImage to="/login/TalentSignUp">
                        <img
                            src="/images/talentBank/LOGIN/企業ログイン.jpg"
                            style={{ width: "150px", height: "150px" }}
                        />
                    </LinkImage>
                </Grid> */}
                {/* <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <LinkImage to="/login/TalentLogin">
                        <img
                            src="/images/talentBank/LOGIN/タレント新規登録.jpg"
                            style={{ width: "150px", height: "150px" }}
                        />
                    </LinkImage>
                    <LinkImage to="/login/SponsorSignUp">
                        <img
                            src="/images/talentBank/LOGIN/企業新規登録.jpg"
                            style={{ width: "150px", height: "150px" }}
                        />
                    </LinkImage>{" "}
                </Grid> */}
            </Grid>
        </React.Fragment>
    );
};

export default Login;
