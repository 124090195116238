import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import OfferCard from "~/components/block/OfferCard";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { Input, Button, TextField } from "@material-ui/core/";

import { initialize, GetMyOfferList } from "~stores/TALENTBANK/Offer";
import { BackButton } from "~CJS/atoms/BackButton";

import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { SetLogin } from "~slices/Information";
import { GetUserList } from "~stores/TALENTBANK/Config";

import canvas2image from "~/components/functions/canvas2image";

const Config = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const ConfigState = useSelector((state) => state.Config);
    const [data_count, setDataCount] = React.useState(10);
    const [data_start_count, setDataStartCount] = React.useState(0);

    useEffect(() => {
        dispatch(GetUserList());
    }, []);

    const token_list = [
        {
            id: "C_00000013",
            name: "タレントパーク事業部",
            token: "eyJpdiI6IlNrTDgxYzFIT1Z2RlRYTUpEN0ZGYmc9PSIsInZhbHVlIjoiVUxEL2dGN0JaTXlVeEk3bGVIQW9yaHFZWVdwbDAwT0ZQcTFkbGtwOXNSNlk3WFVwNGYyQVgrUHlDQXpjcGVUMmxBM2xBdDhaVlNBUzB3VnoxaFRjZnVxQ3h1VHE2bEF0Q2ZkSXU1THZONVk1TFkvS1hyaWg3L2pHSjluNTNSTkUiLCJtYWMiOiIyZTg1MmMxZjA2ZmVjNmYwNDBkMDczODViMmM1NDJmNDA2MmIwY2ZhYjYyMGQ2YTU0NWJjM2Q0MDZhMjc5NDdlIn0=",
        },
        {
            id: "C_00000014",
            name: "株式会社MAGLINK",
            token: "eyJpdiI6Ink2Wkg5d3BzR2hwdXRHbTVLVHVlK0E9PSIsInZhbHVlIjoiemQrMmpFK1pHQjMva2hsV0xnYXZLZXE4R2p5RTlvdnhkQ2RSQlVTV0RUcGhWeFlYcWltd3ExWk9JZUhQamM3WDluUWFWanpiaGNpaDdTQXpudGNNa1hta0JOVHlaQXVkcjhLQW1yOXBoTlFzV1N5Uk5kWjRCN0NxRWdOcnpBQjQiLCJtYWMiOiI0OGQ0ZWM2MjUzZDkxOWRkYmYxZjhlMzUwMWUxNThiN2E4YmNlOTk5ODg3ODQ3YTNmZGU1MGEzZTAyNTlmNDc2In0=",
        },
        {
            id: "C_00000015",
            name: "ロード撮影会",
            token: "eyJpdiI6ImtGQWRmc1Z5Q2N0NnIxUVpzbDhMU2c9PSIsInZhbHVlIjoibloxdmtXSFpzSEZlbjk2MU1lZUhPSDN2ejNIMnl4TUdRMGlqNkZLb0I5UEdCQ2pieUFFMTM2bGxsVFk2RTE4R1hwQnptYlBRcmhtRitWUURSQ083ZUNuVjd1eGNiR2ZLL1o0MXg3UUxxZTVXU0V0NXpRRlhsZHUySmhuT3BudUgiLCJtYWMiOiJmNjI4MzdhMTI0MzIyMmVmNWQ3MTA3NDJjY2MxYzdmNTUyNDNmOWIyOGVmMmYxZWE5M2JkOGMwZTc2MDE3MmVmIn0=",
        },
        {
            id: "C_00000016",
            name: "株式会社エフィール",
            token: "eyJpdiI6InUrelNkVExJUW5USDdkeHNlUmFsb2c9PSIsInZhbHVlIjoiM2ZvQzhlbmZIcjdvWkdQb3NqNDRKakhzcExSMEdvM1NqRnFZNU5QWDdDRENpQmNQVWdkRHg1L2RxUzRJOGhXMndER2ZnbUtZSGZxTGV2SXZHeXMwcGtmR1J1eldNa2pyWjI3WHA1bmphYU1QNC95Ti9ncE1Od0FjYVUza1ZrMEYiLCJtYWMiOiI0ODZiZGJiNDdlODFmNzJiZjdiZmVkNTIxOTljMzNiZmE4YTgxNGIwNjM3MmJlMmI4NWQ0NzgzOTZlM2RkNWQ0In0=",
        },
        {
            id: "C_00000017",
            name: "アウルデザインワークス",
            token: "eyJpdiI6IjloMU0yS0cvRy9OeTJxajlhZ3Vwdnc9PSIsInZhbHVlIjoiaFJxNkpYc3owT1QzdzRMRHVzTjQ2VmovT2R1TUE0YkIzT0JWUVBzanZiVXZJWVZEZUlTSVN4amdmeHZRcGdmUEZSWHA2Qmp6V2NHYjdoTEtCaXZEcUE2eW9WTW5GRXY0aXp6c2svbGZuUlptaER5QlpPdkVRY3NPK0lpa0xkcTciLCJtYWMiOiIyY2JhYzg5Yjg4ZGYyNzY1ZjBlNTc0NWViZmIxMTQ1MDhmNTkxNzQ5NTFhMjMxZDQ5NTM0YmNkMjE0M2RjOGYyIn0=",
        },
        {
            id: "C_00000018",
            name: "有限会社アトリエ・イングス",
            token: "eyJpdiI6IlhaS21adVRIK1ZYUjdEb1poNUxmS2c9PSIsInZhbHVlIjoiM1RScjl0QWtrOVE5UEtSNTB2OEZGaDkrd3pwamNmWGUvdlF3TklCV0xrZUcySVJzK1dSZjRsTHk5RnNicUlwRmMrd3lLWWVLZ3NYQzdRUFg1ME9adUlQNU9iVHRvS2hUQ2s2S3dyczl0L1pOcXhjdVFRYS8yekhZL1Fua3o4czYiLCJtYWMiOiIxYjljZGFiOGQwMGM2MjhmNTAzODM3OGE0N2IzNGVmYjg2ZDVhYzIzYWYyZmQwMWNlNGY0NjBmZTA5MmVhMjQ3In0=",
        },
    ];

    const talent_token_list = [
        {
            id: "T_00001001",
            name: "橘吉孝",
            token: "eyJpdiI6ImRnVlJvRXdBNXBPeCtjcnNhcXV3QkE9PSIsInZhbHVlIjoicGVxUGFzV1FXWitWYUdzT2prYkVuRzRjSHR2K0N1cUl4VzRpcjNXRE8vLzBNay8vUUN4YndDRlhoNERCWmZ1S3hsMGMrM1BZOWNZNE5DRUZRbDhiNVhScjllY20ycjBmN1REU0o3M2htVG9XRTVmZUV6U0xsTXc2akxKOTYwNStnOXBoMkYzYTdwMUVLeTlpT0pHbVJ3PT0iLCJtYWMiOiIwYmE3MTZmMTVjOWEwNzk4N2EyN2Y5MDc1OTQ1NmE3YjUyNDAyZmJhOTY5MzBjYTIzMzVlMGNlOWNmNjZhMmQ2In0=",
        },
        {
            id: "T_00001002",
            name: "しゅり",
            token: "eyJpdiI6Ik5GRm80T0tXckdmUHNGQ29CSWFkYlE9PSIsInZhbHVlIjoiS2NlRGRtTjBVbXR3N1ZrT3RaRDhPNy9FMjFIcDltRTFvajExMm8wc1IvQnlmam9PU0lPUWluVVVBYzFRVXRXdXlJWC9Ha244V0t1SkdWNG5mUTlrZmwvazlaaW4xZkpIWFBOcHA0eXZjQjcxelNDVExqSDVBRnZXNGM5UC9qTVFMQTEwdkxWOFo3SytiSFBKMDhjNHNnPT0iLCJtYWMiOiIyMzc5NDFlNTFhMzg4MTg4YTQ0ZWI1NzcyMzMyZWYwNTgyNTkyMDY5N2IwMTU2N2ZmMjc4NjlkZWVmZDk5MDZmIn0=",
        },
        {
            id: "T_00001003",
            name: "沙希",
            token: "eyJpdiI6IkxqQUd6NTB6L3ZlSnBhc2QzbXp5Znc9PSIsInZhbHVlIjoiZElLeVRUb2luVGUvM2FpNVIvc1E0YnQwdmZkR3pPNnd2UW1uaU0xWVcvY2FmZmJ0QXZCbDd5NmxUNGpsUUJqdG9iK1dDeFFWSCtSazRLemQxcFFpMk9EeGk0ajJrMmsxSGpxTWZhWGdIdjVzaCt6L0dPazlncm1vR3ZZSWtOKytBQll6Y3BwUEhpVDhOMC9OVUxVZGh3PT0iLCJtYWMiOiJmMjRlZjE2NjNhMzRlZjdiMTRhNzk5MDViYTI1NDgyOTFiYzYzYzk4N2M0ZTZmODg3ZDY3NmMyYWJlN2I5NWI1In0=",
        },
        {
            id: "T_00001004",
            name: "芝颯樹",
            token: "eyJpdiI6IjdNWURUUVFaM2kxeVVqNVpVYXI4VVE9PSIsInZhbHVlIjoiYVJRT0N5MFllSUJEQTk0U1VQMGNoWW1FWkJHVGdGcE9EZFVnZi95UXFTUDhFWTh6RFlvY3FKbDlxVWQ3WHc0cElzUGNBT0NJak9MelJyOURTaHdXUzd2blRrU2I1VUxUeXFRWEZwRVZmdHhReVpLVGErdVlmMTJHZWIzdytWakhGTFFjcVR5b1FzVVY1KzR2UzFxRkhBPT0iLCJtYWMiOiIxY2NhYmU4MmJmZWM3YmRkNzQ2MzUxMTliYTViZjdhOWM0MzJmMDU3MDhiZGZhNzZjZjczZTQ0ZDdhM2ZlMjgxIn0=",
        },
        {
            id: "T_00001005",
            name: "木村あやの",
            token: "eyJpdiI6IlhYbi90OFpxVkhhMU9mdjB6SjNtUHc9PSIsInZhbHVlIjoiVUU0YUFGTWZUZ1RjZnNMMHZ0UENwamZFdTl5SjF2OFdwMkNlc2JmRUE0MjZiYi95cm1mQVRqbVpYQVRYYTRScFVkd2tnOGQxblA0eWloSHlyQnFMbjNVYnkwYVFDck91V25GL0Z3RXZKL1c2UHFmMDEzMmw3U1ozaFBVYTFoN0RZMmtET2puWXhEZ0xyUFBhSUhwUlNnPT0iLCJtYWMiOiIyNTBjMGE0NzMxMDUwMTJjNzI1NTNkMDhkODYxYzhlNDU2NDhmZDRjODkyYzdjMTNkNGRhMzMyZDk5NTE3ODcwIn0=",
        },
        {
            id: "T_00001006",
            name: "大屋百菜",
            token: "eyJpdiI6Im5jSXBaZ0U0U1NKc2VHSjcvZTNMRmc9PSIsInZhbHVlIjoiMHQrRWZvWEFKZTVTL1VBSEJvanhTakt2RTRhMWFBMDdYaGdWaSs0NWhhVVhiVmhYUkN1MFB5c1R6OXlsdUlRR2tvZWRsbElYUnhlQ0JMNjdVTUtzQVhieG0rbkdpTnpDd2ZOcHRrd0RaVS84MGltMnlmMW1TaE9DZTJmZXhoUHdJWDBZNjVTNEZvbG4zWTYxd0RqQlB3PT0iLCJtYWMiOiI5MzU1MzUwZTJlOTJkMDRmMzUxMGJjNTAyZTkxZjMxYjc0ZDFkY2MyZWMyNjlkNDE4YzEyM2QzYjY5MzQ2MDBiIn0=",
        },
    ];

    const talentLogin = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        if (elem.talent_login_token_list.value == "未選択") {
            alert("タレントを指定してください");
            return;
        }
        const select_value = ConfigState.talent_list[elem.talent_login_token_list.value];
        // const token = select_value.token;
        const token = select_value.user_token;
        const id = select_value.talent_id;
        const name = select_value.talent_name;
        if (!token) {
            alert("トークンを指定してください");
            return;
        }

        saveLocalStorage(
            JSON.stringify({
                token: token,
                talent_id: id,
                talent_name: name,
                login: true,
                input_option: [],
                category: [],
            }),
            "localStorage_talent"
        );
        saveLocalStorage(token, "localStorage_login_token");

        dispatch(SetLogin(["talent", token, id, name]));
    };

    //localStorageにログイントークンを保存
    function saveLocalStorage(state, id) {
        if (state) {
            localStorage.setItem(id, state);
        }
    }

    const sponsorLogin = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        if (elem.sponsor_login_token_list.value == "未選択") {
            alert("企業を指定してください");
            return;
        }

        const select_value = token_list[elem.sponsor_login_token_list.value];
        const token = select_value.token;
        const id = select_value.id;
        const name = select_value.name;

        if (!token) {
            alert("トークンを指定してください");
            return;
        }

        saveLocalStorage(
            JSON.stringify({ token: token, company_id: id, company_name: name, login: true }),
            "localStorage_sponsor"
        );
        saveLocalStorage(token, "localStorage_login_token");

        dispatch(SetLogin(["sponsor", token, id, name]));
    };

    return (
        <React.Fragment>
            <Container>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <BackButton history={history} />
                    <LinkButton label="Top" to="/" />
                </Grid>
                <Typography variant="h1">テスト設定 </Typography>

                <Grid item style={{ border: "solid 1px", marginTop: "1rem", padding: "1rem" }}>
                    <Typography align="center" variant="h6">
                        タレントログイン
                    </Typography>
                    <form onSubmit={talentLogin}>
                        <TextField
                            style={{ width: "100%", marginBottom: "1rem" }}
                            name="talent_login_token_list"
                            select
                            label="タレントユーザー"
                            variant="outlined"
                            SelectProps={{
                                native: true,
                            }}
                        >
                            <option value={"未選択"}>未選択</option>
                            {ConfigState.talent_list.map((data, index) => (
                                <option key={index} value={index}>
                                    {data.talent_name}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            style={{ width: "100%", marginBottom: "1rem" }}
                            name="talent_login_token"
                            label="トークン"
                            variant="outlined"
                        ></TextField>

                        <Button variant="contained" style={{ width: "45%" }} color="primary" type="submit">
                            タレントログイン
                        </Button>
                    </form>
                </Grid>
                <canvas
                    id="myCanvas"
                    width="500"
                    height="500"
                    style={{
                        border: "1px solid #d3d3d3",
                        display: "none",
                    }}
                ></canvas>
                <canvas
                    id="myCanvas150"
                    width="150"
                    height="150"
                    style={{
                        border: "1px solid #d3d3d3",
                        display: "none",
                    }}
                ></canvas>
                <Grid item style={{ border: "solid 1px", marginTop: "1rem", padding: "1rem" }}>
                    <Typography align="center" variant="h6">
                        企業ログイン
                    </Typography>
                    <form onSubmit={sponsorLogin}>
                        <TextField
                            style={{ width: "100%", marginBottom: "1rem" }}
                            name="sponsor_login_token_list"
                            select
                            label="企業ユーザー"
                            variant="outlined"
                            SelectProps={{
                                native: true,
                            }}
                        >
                            <option value={"未選択"}>未選択</option>
                            {token_list.map((data, index) => (
                                <option key={index} value={index}>
                                    {data.id}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            style={{ width: "100%", marginBottom: "1rem" }}
                            name="sponsor_login_token"
                            label="トークン"
                            variant="outlined"
                        ></TextField>

                        <Button variant="contained" style={{ width: "45%" }} color="primary" type="submit">
                            企業ログイン
                        </Button>
                    </form>
                </Grid>
                {/* <Grid item style={{ border: "solid 1px", marginTop: "1rem", padding: "1rem" }}> */}
                {/* <Typography align="center" variant="h6">
                        テストデータ出力
                    </Typography>
                    <TextField
                        style={{ width: "100%", marginBottom: "1rem" }}
                        name="data_count"
                        label="作成数"
                        variant="outlined"
                        type="number"
                        value={data_count}
                        onChange={(e) => setDataCount(Number(e.target.value))}
                    ></TextField>
                    <TextField
                        style={{ width: "100%", marginBottom: "1rem" }}
                        name="data_start_count"
                        label="開始ID"
                        variant="outlined"
                        type="number"
                        value={data_start_count}
                        onChange={(e) => setDataStartCount(Number(e.target.value))}
                    ></TextField> */}
                {/* <Button variant="contained" style={{ width: "45%" }} color="primary" onClick={export_offer_test}>
                        お仕事テストデータ出力
                    </Button>
                    <Button variant="contained" style={{ width: "45%" }} color="primary" onClick={export_favorite_test}>
                        お気に入りテストデータ出力
                    </Button>
                    <Button variant="contained" style={{ width: "45%" }} color="primary" onClick={export_talent_test}>
                        タレントテストデータ出力
                    </Button> */}
                {/* </Grid> */}
            </Container>
        </React.Fragment>
    );
};

export default Config;

const Downloader = (sql, file_name) => {
    let blob = new Blob([sql], { type: "text/txt" });
    let uri = URL.createObjectURL(blob);
    //リンクタグを作る
    let link = document.createElement("a");
    link.download = file_name;
    link.href = uri;
    //作ったリンクタグをクリックさせる
    document.body.appendChild(link);
    link.click();
    //クリックしたら即リンクタグを消す
    // document.body.removeChild(link);
    // delete link;
    // canvas2image.saveAsImage(c, 200, 100, "png");
};

const Imagecreator = (i) => {
    const color = ("#c0000" + (i * 3).toString(16)).slice(-8);
    const myCanvas = document.getElementById("myCanvas");
    const ctx500 = myCanvas.getContext("2d");

    const num = (i % 15) + 1;
    const image_name = ("000" + num).slice(-3);

    var c = document.getElementById("myCanvas150");
    var ctx = c.getContext("2d");

    return new Promise(function (resolve) {
        // test().then((chara) => {
        const chara = new Image();
        // if (back) {
        //     back.count ? back.count : 1;
        //     chara.src = `${back}${image_name}.png`; // 画像のURLを指定
        // } else {
        chara.src = `/images/talentBank/test/R/R${image_name}.png`; // 画像のURLを指定
        // }

        chara.onload = function () {
            ctx500.drawImage(chara, 0, 0, 500, 500);
            ctx.drawImage(chara, 0, 0, 150, 150);
            ctx500.font = "50px Arial";
            ctx500.fillStyle = color;
            ctx.font = "20px Arial";
            ctx.fillStyle = color;
            ctx500.fillText(i, 70, 250);
            ctx.fillText(i, 20, 75);

            // ctx500.fillRect(0, 0, 500, 50); //上
            // ctx500.fillRect(0, 0, 50, 500); //下
            // ctx500.fillRect(0, 450, 500, 50); //左
            // ctx500.fillRect(450, 0, 50, 500); //右

            // ctx.fillRect(0, 0, 150, 15); //上
            // ctx.fillRect(0, 0, 15, 150); //下
            // ctx.fillRect(0, 135, 150, 15); //左
            // ctx.fillRect(135, 0, 15, 150); //右

            const image500 = canvas2image.convertToImage(myCanvas, 500, 500, "png");
            const image_big = image500.src;
            const image = canvas2image.convertToImage(c, 150, 150, "png");
            const image_small = image.src;
            // return { image_big: image_big, image_small: image_small };
            resolve({ image_big: image_big, image_small: image_small, index_num: i });
        };
        // });
    });

    // chara.onload = () => {

    // ctx500.font = "50px Arial";
    // ctx500.fillStyle = color;
    // // ctx500.fillRect(0, 0, 500, 50); //上
    // // ctx500.fillRect(0, 0, 50, 500); //下
    // // ctx500.fillRect(0, 450, 500, 50); //左
    // // ctx500.fillRect(450, 0, 50, 500); //右
    // ctx500.fillText(i, 70, 250);

    // ctx.drawImage(chara, 0, 0, 150, 150);
    // ctx.font = "20px Arial";
    // ctx.fillStyle = color;
    // // ctx.fillRect(0, 0, 150, 15); //上
    // // ctx.fillRect(0, 0, 15, 150); //下
    // // ctx.fillRect(0, 135, 150, 15); //左
    // // ctx.fillRect(135, 0, 15, 150); //右
    // ctx.fillText(i, 20, 75);

    // };

    // const image500 = canvas2image.convertToImage(myCanvas, 500, 500, "png");
    // const image_big = image500.src;
    // const image = canvas2image.convertToImage(c, 150, 150, "png");
    // const image_small = image.src;

    // console.log(image.src);
    // ctx500.clearRect(0, 0, 500, 500);
    // ctx.clearRect(0, 0, 150, 150);
};
