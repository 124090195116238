// ジャンルー定数
const SITE_INFO = "タレントパークサイト内について";
const TALENT_APP_FEATURES = "【タレント】TALENTPARKについて";
const TALENT_ACCOUNT = "【タレント】アカウント";
const TALENT_OTHERS = "【タレント】その他";
const COMPANY_APP_FEATURES = "【企業】TALENTPARKについて";
const COMPANY_ACCOUNT = "【企業】アカウント";
const COMPANY_OTHERS = "【企業】その他";

const faq_data = [
    // TALENT_APP_FEATURES
    [
        {
            category: TALENT_APP_FEATURES,
            question: "タレントパークに登録したい",
            answer: "ログインページにあるタレントパークのタレント⽤official LINEを追加していただきお問合せください。",
        },
        {
            category: TALENT_APP_FEATURES,
            question: "タレントパークに登録する際に条件などありますか？",
            answer: "タレントパークと連動しているフィフティーカードアプリ（⽉々500円）に登録していただく必要があります。",
        },
        {
            category: TALENT_APP_FEATURES,
            question: "どんなお仕事がありますか？",
            answer: "広告撮影やTV出演、SNS案件など幅広くございます。",
        },
    ],
    // TALENT_ACCOUNT
    [
        {
            category: TALENT_ACCOUNT,
            question: "プロフィールを修正したい。",
            answer: "プロフィール確認のページで修正できます。メイン画像の変更などはタレントパークのofficial LINE にご連絡ください。",
        },
        {
            category: TALENT_ACCOUNT,
            question: "ログインパスワードが分からない",
            answer: "フィフティーカードで登録されたパスワードでログインしていただけます。",
        },
        {
            category: TALENT_ACCOUNT,
            question: "パスワードを変更したい",
            answer: ".フィフティーカードアプリからご変更ください。",
        },
    ],
    // TALENT_OTHERS
    [
        {
            category: TALENT_OTHERS,
            question: "トークで挿⼊できる形式は何ですか",
            answer: ".jpgまたは.pngのみとなります。",
        },
        {
            category: TALENT_OTHERS,
            question: "採⽤されたが辞退したい",
            answer: "企業様に直接トークにてご相談ください。",
        },
    ],
    // COMPANY_APP_FEATURES
    [
        {
            category: COMPANY_APP_FEATURES,
            question: "タレントパークに登録したい",
            answer: "ログインページにあるタレントパークの企業様⽤official LINEを追加していただき お問合せください。",
        },
        {
            category: COMPANY_APP_FEATURES,
            question: "タレントパークに登録する際に費⽤などかかりますか？",
            answer: "登録するのに費⽤は⼀切かかりません。",
        },
    ],
    // COMPANY_ACCOUNT
    [
        {
            category: COMPANY_ACCOUNT,
            question: ".ログインパスワードを忘れてしまった。",
            answer: ".タレントパークのofficial LINE にご連絡ください。",
        },
        {
            category: COMPANY_ACCOUNT,
            question: "事業部に分けて複数のアカウントを持ちたい。",
            answer: "担当者を付けていただき、個々に登録していただけます。",
        },
    ],
    // COMPANY_OTHERS
    [
        {
            category: COMPANY_OTHERS,
            question: "トークで挿⼊できる形式は何ですか？",
            answer: ".jpgまたは.pngのみとなります。",
        },
        {
            category: COMPANY_OTHERS,
            question: "タレントへのキャスティング後、⽀払いはどうすればいいですか？",
            answer: ".タレントに直接請求書など取り寄せていただきお⽀払いください。",
        },
    ],
];

export default faq_data;
