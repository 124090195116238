import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "~CJS/atoms/LinkButton";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FixedBand from "~CJS/atoms/FixedBand";
import OfferDetailInput from "~/components/block/OfferDetailInput";
import CompanyCard from "~CJS/block/CompanyCard";
import { ButtonNavi } from "~CJS/block/ButtonNavi";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { initialize, GetDetail, SetFavorite } from "~stores/TALENTBANK/Offer";

import { getStringFromDate } from "~CJS/functions/Time";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    fixedLink: {
        width: "100%",
        padding: "1rem 0rem",
        margin: "0",
        "&.MuiButton-contained.Mui-disabled": {
            backgroundColor: "#e3e3e3",
        },
    },
}));

const OfferDetail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const path = history.location.pathname.split("/");
    const offer_id = path[3];

    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const status = query.get("status");

    const InformationState = useSelector((state) => state.Information);
    const OfferState = useSelector((state) => state.Offer);
    let nextDayOfJob = new Date(OfferState?.offer_detail?.entry_close?.replaceAll("-", "/"));
    nextDayOfJob = nextDayOfJob.setDate(nextDayOfJob.getDate() + 1);
    const now = new Date();
    useEffect(() => {
        dispatch(initialize());
        dispatch(GetDetail({ offer_id: offer_id }));
    }, []);

    // ログインしている企業ユーザーのお仕事かどうかのチェック
    const check_my_offer = () => {
        const this_company_id = OfferState.offer_detail?.company_id;
        const login_company_id = InformationState?.sponsor?.company_id;
        if (this_company_id != login_company_id) {
            return false;
        }
        if (InformationState?.sponsor?.login != true) {
            return false;
        }
        return true;
    };
    const setFavorite = () => {
        dispatch(SetFavorite({ offer_id: offer_id, active_flag: 1 }));
    };
    const unsetFavorite = () => {
        dispatch(SetFavorite({ offer_id: offer_id, active_flag: 0 }));
    };

    // 前のページに戻る
    const GoBack = () => {
        history.goBack();
    };
    const selectButtonNavi = () => {
        if (check_my_offer()) {
            return (
                <ButtonNavi
                    left_label="お仕事一覧へ"
                    left_link="/sponsor/MyOfferList"
                    right_label="オーディションシート一覧"
                    right_link={`/sponsor/AuditionSeetList?offer_id=${offer_id}&initial=true`}
                />
            );
        }
        if (InformationState?.talent?.login != true) {
            return (
                <ButtonNavi
                    // left_label="お仕事を探す"
                    left_label="もどる"
                    left_props={{ color: "primary", onClick: GoBack }}
                    // left_link="/common/OfferSearch"
                />
            );
        }
        if (OfferState.favorite) {
            return (
                <ButtonNavi
                    // left_label="お仕事をさがす"
                    // left_link="/common/OfferSearch"
                    left_label="もどる"
                    // left_props={{ color: "primary", onClick: GoBack }}
                    right_label={
                        <>
                            <FavoriteIcon />
                            お気に入り済
                        </>
                    }
                    left_props={{ style: { fontSize: "0.8rem" }, onClick: GoBack }}
                    right_props={{ color: "secondary", onClick: unsetFavorite, style: { fontSize: "0.8rem" } }}
                />
            );
        } else {
            return (
                <ButtonNavi
                    left_label="もどる"
                    // left_link="/common/OfferSearch"

                    right_label={
                        <>
                            <FavoriteBorderIcon />
                            お気に入り
                        </>
                    }
                    left_props={{ style: { fontSize: "0.8rem" }, onClick: GoBack }}
                    right_props={{ onClick: setFavorite, style: { fontSize: "0.8rem" } }}
                />
            );
        }
    };

    return (
        <Container className="page_container">
            {selectButtonNavi()}
            <Typography variant="h1">お仕事詳細</Typography>
            <OfferDetailInput disabled data={OfferState.offer_detail} />
            <CompanyCard data={OfferState.company_info} />

            {InformationState.talent.login && (
                <FixedBand>
                    {/* タレントがオーディションに応募してる場合*/}
                    {OfferState.audition_seet ? (
                        <LinkButton
                            label={
                                <>
                                    <Typography align="center">
                                        応募中
                                        <br />
                                        オーディションシートへ
                                    </Typography>
                                </>
                            }
                            to={`/talent/AuditionSeet/${offer_id}`}
                            color="secondary"
                            style={{
                                width: "100%",
                                padding: "1rem 0rem",
                                margin: "0",
                            }}
                        />
                    ) : (
                        <LinkButton
                            label={now < nextDayOfJob ? <>オーディションを受ける</> : <>募集終了</>}
                            to={`/talent/AuditionSeetFix/${offer_id}`}
                            color="secondary"
                            className={classes.fixedLink}
                            disabled={now >= nextDayOfJob}
                        />
                    )}
                </FixedBand>
            )}

            {InformationState.sponsor.login && (
                <>
                    {/*ログインした企業が登録したお仕事の場合 */}
                    {OfferState.offer_detail?.company_id == InformationState?.sponsor?.company_id && (
                        <FixedBand>
                            {OfferState.offer_detail.status == "1" && (
                                <LinkButton
                                    label={
                                        OfferState.offer_detail.audition_count == 0 ? (
                                            <Typography align="center">変更申請する</Typography>
                                        ) : (
                                            <Typography align="center">応募者がいるため変更できません</Typography>
                                        )
                                    }
                                    to={`/sponsor/OfferDetailFix/${offer_id}`}
                                    color="secondary"
                                    className={classes.fixedLink}
                                    disabled={OfferState.offer_detail.audition_count > 0}
                                />
                            )}
                            {OfferState.offer_detail.status == "0" && (
                                <LinkButton
                                    label={
                                        <>
                                            <Typography align="center">【変更申請中】 申請を修正する</Typography>
                                        </>
                                    }
                                    to={`/sponsor/OfferDetailFix/${offer_id}`}
                                    color="secondary"
                                    style={{
                                        width: "100%",
                                        padding: "1rem 0rem",
                                        margin: "0",
                                        background: "red",
                                    }}
                                />
                            )}
                        </FixedBand>
                    )}
                </>
            )}
        </Container>
    );
};

export default OfferDetail;
