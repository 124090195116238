import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

import ListItemText from "@material-ui/core/ListItemText";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { LinkButton } from "~CJS/atoms/LinkButton";

// icon
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import ForumIcon from "@material-ui/icons/Forum";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PolicyIcon from "@material-ui/icons/Policy";
import HelpIcon from "@material-ui/icons/Help";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import BuildIcon from "@material-ui/icons/Build";

import ModalProgress from "~CJS/atoms/ModalProgress";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    container: {
        padding: "0px",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    content: {
        width: "100%",
    },
    mainContents: {
        minHeight: "calc(100vh - 64px)",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        display: "flex !important",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "0rem",
        paddingRight: "0rem",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
}));

const DrawerMenu = (props) => {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const InformationState = useSelector((state) => state.Information);
    const ProgressState = useSelector((state) => state.Progress);
    let pages = {
        共通: [
            { name: "タレントをさがす", link: "/common/TalentSearch", icon: <SearchIcon /> },
            { name: "ポリシー", link: "/common/Policy", icon: <PolicyIcon /> },
            { name: "よくある質問", link: "/common/Question", icon: <HelpIcon /> },
        ],
    };

    switch (true) {
        case InformationState.talent.login:
            // console.debug("タレントログイン");
            pages["タレント"] = [
                { name: "お仕事をさがす", link: "/common/OfferSearch", icon: <SearchIcon /> },
                {
                    name: "お気に入り",
                    link: "/common/OfferSearch?favorite=true",
                    icon: <FavoriteIcon />,
                },
                { name: "応募中一覧", link: "/talent/MyAuditionList", icon: <ListAltIcon /> },
                { name: "プロフィール確認", link: "/talent/TalentProfile", icon: <AccountCircleIcon /> },
                // { name: "設定", link: "/talent/TalentConfig", icon: <BuildIcon /> },
                { name: "トーク", link: "/chat/ChatList", icon: <ForumIcon /> },
                { name: "ログアウト", link: "/login/logout", icon: <MeetingRoomIcon /> },
            ];
            break;
        case InformationState.sponsor.login:
            // console.debug("スポンサーログイン");
            pages["スポンサー"] = [
                { name: "お気に入りタレント", link: "/common/TalentSearch?favorite=true", icon: <FavoriteIcon /> },
                { name: "お仕事一覧", link: "/sponsor/MyOfferList", icon: <ListAltIcon /> },
                // { name: "応募一覧", link: "/sponsor/AuditionSeetList", icon: <ListAltIcon /> },
                { name: "オファートーク", link: "/chat/ChatList", icon: <ForumIcon /> },
                { name: "ログアウト", link: "/login/logout", icon: <MeetingRoomIcon /> },
            ];
            break;
        default:
            // console.debug("非ログイン");
            pages["非ログイン"] = [{ name: "ログイン", link: "/login/Login", icon: <AccountCircleIcon /> }];
            break;
    }
    if (InformationState.dev == true) {
        pages["開発"] = [
            { name: "管理者設定", link: "/dev/Config", icon: <BuildIcon /> },
            { name: "設定", link: "/talent/TalentConfig", icon: <BuildIcon /> },
        ];
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            {Object.keys(pages).map((pages_value, pages_index) => (
                <React.Fragment key={pages_index}>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {Object.keys(pages)[pages_index]}
                            </ListSubheader>
                        }
                    >
                        {pages[pages_value].map((value, index) => (
                            <ListItem
                                button
                                key={index}
                                component={Link}
                                to={value.link}
                                onClick={() => {
                                    setMobileOpen(false);
                                }}
                            >
                                {" "}
                                <ListItemIcon>{value.icon}</ListItemIcon>
                                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                                <ListItemText primary={value.name} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </React.Fragment>
            ))}
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div style={{ height: "56px" }}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon style={{ color: "white" }} />
                        </IconButton>
                        <Typography variant="h6" noWrap component={Link} to="/" style={{ color: "#fff" }}>
                            TALENT_PARK
                        </Typography>
                        {/* ログインしているかどうかの判断 */}

                        <RightMenu InformationState={InformationState} />
                        {/* {InformationState.talent?.login ||
                        InformationState.sponsor?.login ? (
                            <LinkButton
                                style={{ marginRight: "0", marginLeft: "auto" }}
                                color="inherit"
                                to="/talent/TalentProfile"
                                label="プロフィール"
                                variant="text"
                            />
                        ) : (
                            <LinkButton
                                style={{ marginRight: "0", marginLeft: "auto" }}
                                color="inherit"
                                to="/login/Login/"
                                label="Login"
                                variant="text"
                            />
                        )} */}

                        {/* <MenuItem>sssss</MenuItem> */}
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === "rtl" ? "right" : "left"}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <ModalProgress open={ProgressState.Progress} />
                    <div className={classes.toolbar} />
                    <Container className={classes.mainContents}>{props.children}</Container>
                </main>
            </div>
        </div>
    );
};

const RightMenu = (props) => {
    const { InformationState, ...other } = props;
    switch (true) {
        case InformationState.talent?.login:
            return (
                <LinkButton
                    style={{
                        marginLeft: "auto",
                        marginRight: "0px",
                    }}
                    color="inherit"
                    to="/talent/TalentProfile"
                    label={
                        <div
                            style={{
                                marginLeft: "auto",
                                marginRight: "0px",
                                whiteSpace: "nowrap",
                                marginLeft: "auto",
                                maxWidth: "30vw",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                        >
                            {InformationState.talent.talent_name}
                        </div>
                    }
                    variant="text"
                />
            );

        case InformationState.sponsor?.login:
            return (
                <LinkButton
                    style={{
                        marginLeft: "auto",
                        marginRight: "0px",
                    }}
                    color="inherit"
                    to="/sponsor/SponsorProfile"
                    label={
                        <div
                            style={{
                                marginLeft: "auto",
                                marginRight: "0px",
                                whiteSpace: "nowrap",
                                marginLeft: "auto",
                                maxWidth: "30vw",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                        >
                            {InformationState.sponsor.company_name}
                        </div>
                    }
                    variant="text"
                />
            );

        default:
            return (
                <LinkButton
                    style={{ marginRight: "0", marginLeft: "auto" }}
                    color="inherit"
                    to="/login/Login/"
                    label="Login"
                    variant="text"
                />
            );
    }
};

DrawerMenu.propTypes = {
    window: PropTypes.func,
};

export default DrawerMenu;
