import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// それぞれ slice.reducer を default export している前提
import Information from "./slices/Information";
import Progress from "./slices/Progress";
import ErrorHandler from "./slices/ErrorHandler";
import Chat from "./slices/common/Chat";
import Talent from "./TALENTBANK/Talent";
import Category from "./TALENTBANK/Category";
import Offer from "./TALENTBANK/Offer";
import Company from "./TALENTBANK/Company";
import AuditionSeet from "./TALENTBANK/AuditionSeet";
import Vote from "./TALENTBANK/Vote";
import Top from "./TALENTBANK/Top";
import TalentCreateRequest from "./TALENTBANK/TalentCreateRequest";
import Config from "./TALENTBANK/Config";

const reducer = combineReducers({
    Information: Information,
    Progress: Progress,
    ErrorHandler: ErrorHandler,
    Chat: Chat,
    // タレントバンク
    Talent: Talent,
    Category: Category,
    Offer: Offer,
    Company: Company,
    AuditionSeet: AuditionSeet,
    Vote: Vote,
    Top: Top,
    TalentCreateRequest: TalentCreateRequest,
    Config: Config,
});

const store = configureStore({ reducer });

export default store;
