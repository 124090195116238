import React from "react";
import { LinkButton } from "~CJS/atoms/LinkButton";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        // width: "100%",
    },
    left_item: {
        marginBottom: "1rem",
        fontSize: "1.1rem",
        marginLeft: "auto",
        marginRight: "0",
        display: "table",
    },
    center_item: {
        marginBottom: "1rem",
        fontSize: "1.1rem",
        marginLeft: "auto",
        marginRight: "auto",
        display: "table",
    },
    right_item: {
        marginBottom: "1rem",
        fontSize: "1.1rem",
        marginLeft: "auto",
        marginRight: "0",
        display: "table",
    },
    general_size: {
        fontSize: "1.1rem",
    },
    admin_size: {
        height: "3rem",
        fontSize: "1.4rem", 
    },
    full_width: {
        margin: "0rem",
        marginBottom: "1rem",
        height: "3rem",
        fontSize: "1.4rem",
        width: "100%",
    },
}));
export const Fullwidth = (props) => {
    const classes = useStyles();

    const { label, link, ...other } = props;
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.root}
            >
                {link ? (
                    <LinkButton
                        variant="contained"
                        // color="secondary"
                        to={link}
                        className={classes.full_width}
                        fullWidth
                        label={label}
                        {...other}
                    ></LinkButton>
                ) : (
                    <Button
                        fullWidth
                        variant="contained"
                        className={classes.full_width}
                        {...other}
                    >
                        {label}
                    </Button>
                )}
            </Grid>
        </>
    );
};
export const ButtonNavi = (props) => {
    const {
        left_label,
        left_link,
        left_props,
        center_label,
        center_link,
        center_props,
        right_label,
        right_link,
        right_props,
        children,
        
        ...other
    } = props;
    
    const classes = useStyles();
    // const sizeClass = 

    
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.root}
            >
                <Grid item>
                    {left_link ? (
                        <LinkButton
                            variant="contained"
                            // color="secondary"
                            to={left_link}
                            className={classes.left_item}
                            label={left_label}
                            {...left_props}
                        ></LinkButton>
                    ) : (
                        <Button
                            variant="contained"
                            className={classes.left_item}
                            {...left_props}
                        >
                            {left_label}
                        </Button>
                    )}
                </Grid>
                {center_label && (
                    <Grid item>
                        {center_link ? (
                            <LinkButton
                                variant="contained"
                                color="secondary"
                                to={center_link}
                                className={classes.center_item}
                                label={center_label}
                                {...center_props}
                            ></LinkButton>
                        ) : (
                            <Button
                                variant="contained"
                                className={classes.center_item}
                                {...center_props}
                            >
                                {center_label}
                            </Button>
                        )}
                    </Grid>
                )}
                {right_label && (
                    <Grid item>
                        {right_link ? (
                            <LinkButton
                                variant="contained"
                                color="secondary"
                                to={right_link}
                                className={classes.right_item}
                                label={right_label}
                                {...right_props}
                            ></LinkButton>
                        ) : (
                            <Button
                                variant="contained"
                                className={classes.right_item}
                                {...right_props}
                            >
                                {right_label}
                            </Button>
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};