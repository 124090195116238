import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";

const CompanyCard = (props) => {
  const {
    company_id,
    image,
    image_small,
    company_name,
    prefectures,
    city,
    address_1,
    address_2,
    company_page,
    staff_email_address,
  } = props?.data;

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className="cards"
        style={{
          border: "solid 1px",
          boxShadow: "0 2px 10px 0 rgba(0, 0, 0, .5)",
          position: "relative",
          backgroundColor: "#fff",
          width: "96%",
          margin: "2%",
          marginBottom: "1rem",
        }}
      >
        <Grid container>
          <Grid item>
            <Avatar
              style={{
                height: "3.5rem",
                width: "3.5rem",
                margin: "0.2rem",
              }}
              variant="square"
              src={image_small ? image_small : image}
              sizes="100%"
            />
          </Grid>
          <Grid item xs>
            <Typography style={{ fontSize: "1rem" }}>企業名</Typography>
            {props.admin && (
              <Grid item xs>
                <Typography style={{ fontSize: "1rem" }} align="right">
                  {company_id}
                </Typography>
              </Grid>
            )}
            <Typography style={{ fontSize: "1.4rem" }}>{company_name}</Typography>
          </Grid>
        </Grid>

        <DitailText value={prefectures ? prefectures : "" + city ? city : ""} label="所在地" />
        <DitailText value={address_1 ? address_1 : "" + address_2 ? address_2 : ""} label="" />
        <DitailText value={company_page} label="ホームページ" link />

        {props.admin && (
          <>
            <DitailText value={staff_email_address} label="担当者のメールアドレス" />
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {props.link_detail && (
                <Grid item xs={6}>
                  <Button fullWidth color="primary" variant="contained" onClick={() => props.link_detail(company_id)}>
                    編集
                  </Button>
                </Grid>
              )}
              {props.link_talk_list && (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => props.link_talk_list(company_id)}
                  >
                    トーク確認
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};

const DitailText = (props) => {
  const label = props.label;
  const value = props.value ? props.value : "";
  const link = props.link;

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        {label && <Divider component="div" variant="fullWidth" style={{ width: "100%" }} />}
        <Grid item xs={3}>
          {label && <Typography>{label}</Typography>}
        </Grid>

        <Grid item xs>
          {value && (
            <>
              {link ? (
                <a href={value} target="blank" rel="noopener noreferrer">
                  {value}
                </a>
              ) : (
                <Typography>{value}</Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyCard;
