import React, { useRef, useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
// import OfferCard from "~/components/block/OfferCard";
import ImageListItem from "@material-ui/core/ImageListItem";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router";

import Chat from "~/components/block/Chat";

import { GetTop, initialize } from "~stores/TALENTBANK/Top";

import { useDispatch, useSelector } from "react-redux";

import { LinkButton } from "~CJS/atoms/LinkButton";

import { LinkPanel } from "~CJS/atoms/LinkButton";
import { ImageSwiper } from "~/components/block/ImageSwiper";
import ImageList from "~/components/block/ImageList";
import { map } from "jquery";

import { format } from "date-fns";
import { ja } from "date-fns/locale";

import InfoIcon from "@material-ui/icons/Info";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";

import {
    TwitterTimelineEmbed,
    TwitterShareButton,
    TwitterFollowButton,
    TwitterHashtagButton,
    TwitterMentionButton,
    TwitterTweetEmbed,
    TwitterMomentShare,
    TwitterDMButton,
    TwitterVideoEmbed,
    TwitterOnAirButton,
} from "react-twitter-embed";
// import "/css/top/base.css";
// import "/css/top/reset.css";
// import "/css/top/common.css";
// import "/css/top/slick-theme.css";
// import "/css/top/slick.css";

// import "../../../css/top/base.css"

const Top = () => {
    const dispatch = useDispatch();
    const TalentListState = useSelector((state) => state.TalentList);
    const TopState = useSelector((state) => state.Top);

    // const handleChange = (e) => {
    //     dispatch(GetTop());
    // };

    useEffect(() => {
        if (TopState.talent_list.length < 1) {
            dispatch(initialize());
            dispatch(GetTop());
        }
    }, []);
    // const preventDefault = (event) => event.preventDefault();



    // var tag = document.createElement('script');
    // tag.src = "https://www.youtube.com/ifzrame_api";
    // var firstScriptTag = document.getElementsByTagName('script')[0];
    // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // // 3. iframe の設定を行います。
    // var player;
    // function onYouTubeIframeAPIReady() {
    //     player = new YT.Player('player', {
    //         videoId: 'aqa9h-nL-TA',
    //         events: {
    //             'onReady': onPlayerReady,
    //         }
    //     });
    // }

    // // 4. 最初にこちらの onPlayerReady() が呼び出され動画を再生します。
    // function onPlayerReady(event) {
    //     event.target.mute();
    //     event.target.playVideo();
    // }



    return (
        <React.Fragment>





            <div class="box_youtube">

                <iframe width="100%" height="560px" src="https://www.youtube.com/embed/w8XFEDkcIxw?autoplay=1&mute=1&loop=1&playlist=w8XFEDkcIxw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" playlist='dancenettv6409'></iframe>
            </div>
            {/* <video id='topvideo' src="/images/top/test.mp4" autoplay muted playsinline loop></video> */}

            <Box style={{ width: '100%' }}>



                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    style={{
                        //  background: 'rgb(246, 132, 0)',

                        padding: '1rem',
                        zIndex: '2',
                        position: 'absolute',
                        top: '500px',
                        width: '-webkit-fill-available',
                        maxWidth: '1200px'
                        // width: '80vw'


                    }}>
                    <a href="/common/TalentSearch" >
                        <Button
                            style={{ background: 'rgb(246 132 0)', padding: '1rem', width: '30vw', height: '5vw', maxWidth: '500px', minWidth: '150px', fontSize: 'max(1.5vw,1rem)' }}>
                            企業用
                        </Button>
                    </a>
                    <a href="/sponsor/OfferNew" >
                        <Button style={{ background: 'rgb(243 207 68)', padding: '1rem', width: '30vw', height: '5vw', maxWidth: '500px', minWidth: '150px', fontSize: 'max(1.5vw,1rem)' }}>
                            タレント用
                        </Button>
                    </a>
                </Grid>

            </Box>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className="Top"
                style={{
                    background: "rgb(246 132 0)",
                }}
            >



                {/* <Box style={{
                    minWidth: '50vw',
                    background: 'rgb(243 207 68)', padding: '0.5rem', paddingRight: '2rem', paddingLeft: '2rem', border: 'solid 1px ', margin: '1rem', borderRadius: '0.5rem'
                }}> */}

                <Title text='ピックアップタレント' />



                <TalentList data={TopState?.pick_up_takent_list} />


                <Title text='高評価順' />

                <TalentList data={TopState?.kouhyouka_takent_list} />

                <Title text='ログイン順' />

                <TalentList data={TopState?.login_takent_list} />



                {/* {TopState.slide_list && <ImageSwiper data={TopState.slide_list} />} */}
                {/* <img src="/images/talentBank/TopShot/上001.png" style={{ width: "100%" }} /> */}



                {/* <div
                    style={{
                        position: "relative",
                    }}
                >
                    <a href="https://talent-park.net/statics/company/index.html">
                        <img
                            src="/images/talentBank/TopShot/wappen.png"
                            style={{
                                width: "15vw",
                                maxWidth: "200px",
                                position: "absolute",
                                top: "25px",
                                right: "25px",
                            }}
                        />
                    </a>
                    <img src="/images/talentBank/TopShot/top上右上リンクなし.jpeg" style={{ width: "100%" }} />
                </div> */}
                <Grid
                    container
                    style={{ padding: '1rem' }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <NewsList data={TopState.news_list} />
                </Grid>



                {/* <Box style={{ padding: '0.5rem' }}>
                    {TopState.slide_list.map((val, i) => (<React.Fragment key={i}>

                        <Box style={{ padding: '0.5rem' }}>
                            <a href={val?.link_url}>
                                <img src={val?.image} style={{ width: "100%" }} />
                            </a>
                        </Box>

                    </React.Fragment>))}
                </Box> */}

                <Box style={{ padding: '0.5rem' }}>
                    <Grid container spacing={2}>
                        {TopState.slide_list.map((val, i) => (
                            <Grid
                                item
                                xs={12} // スマホでは1列
                                md={6}  // PCでは2列 (6/12 = 50% の幅)
                                key={i}
                            >
                                <Box style={{ padding: '0.5rem' }}>
                                    <a href={val?.link_url}>
                                        <img src={val?.image} style={{ width: '100%' }} />
                                    </a>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* <Grid
                    container
                    style={{ background: "#333333" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <ImageList />
                </Grid> */}

                <Grid
                    container
                    style={{ fontFamily: "'M PLUS Rounded 1c', sans-serif", background: "#FCAD37" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {/* <img src="/images/talentBank/TopShot/上.png" style={{ width: "100%" }} /> */}
                    {/* <img src="/images/talentBank/TopShot/中001.jpeg" style={{ width: "90%" }} /> */}

                    {/* <Button
                        style={{
                            background: "#85dbf0",
                            display: "block",
                            // fontSize: "2vw",
                            fontWeight: "900",
                            padding: "10px 30px",
                            border: "none",
                            width: "80%",
                            textAlign: "center",
                            fontSize: "min(2vw,4rem)",
                            marginTop: "2rem",
                            marginButtom: "2rem",
                        }}
                        href="https://line.me/R/ti/p/@209dugak"
                        target="_blank"
                    >
                        タレントパークにLINEで応募！
                    </Button> */}
                </Grid>

                {/* <img src="/images/talentBank/TopShot/中002.jpeg" style={{ width: "100%" }} /> */}
                {/* <img src="/images/talentBank/TopShot/T02.png" style={{ width: "100%" }} /> */}

                {/* <img src="/images/talentBank/TopShot/五ステップ.jpeg" style={{ width: "100%" }} /> */}
                {/* <img src="/images/talentBank/TopShot/登録とお仕事依頼の仕組み.jpeg" style={{ width: "100%" }} /> */}
                {/* <img src="/images/talentBank/TopShot/F01.jpeg" style={{ width: "100%" }} /> */}

                {/* <img src="/images/talentBank/TopShot/下001.jpeg" style={{ width: "90%" }} /> */}
                {/* LINEで応募！ */}
                {/* <Button
                    style={{
                        background: "#fff",
                        display: "block",
                        // fontSize: "2vw",
                        marginBottom: "1rem",
                        marginTop: "2rem",
                        fontWeight: "900",
                        padding: "10px 30px",
                        border: "none",
                        width: "60%",
                        textAlign: "center",
                        fontSize: "min(2vw,4rem)",
                    }}
                    href="https://line.me/R/ti/p/@209dugak"
                    target="_blank"
                >
                    
                    <img src="/images/talentBank/TopShot/下button01.jpeg" style={{ width: "100%" }} />
                </Button> */}
                <img src="/images/talentBank/TopShot/下002.jpeg" style={{
                    width: '100%',
                    paddingTop: '1rem',
                    background: '#333333'
                }} />

                <TopFooter />

                {/* <TopMain /> */}
            </Grid>
        </React.Fragment>
    );
};

const TalentList = (props) => {
    const { data = [], ...other } = props;
    const history = useHistory();

    return (<React.Fragment>
        {/* <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
        >

            {data.map((item, index) => (
                <React.Fragment key={index}>
                    <Box key={index} onClick={(e) => history.push(`/common/TalentDetail/${e.target.name}`)}>
                        <img
                            src={item.image_small}
                            name={item.talent_id}
                            alt={item.talent_id}
                            style={{ width: "100px", height: "auto", padding: '0.3rem' }}
                        />
                    </Box>
                </React.Fragment>))}
        </Grid> */}
        <Grid container spacing={2} justifyContent="space-around" alignItems="center">
            {data.map((item, index) => (
                <Grid item xs={3} md={2} key={index}>
                    <Box onClick={(e) => history.push(`/common/TalentDetail/${e.target.name}`)}>
                        <img
                            src={item.image_small}
                            name={item.talent_id}
                            alt={item.talent_id}
                            style={{ width: "100%", height: "auto", padding: '0.1rem' }}
                        />
                    </Box>
                </Grid>
            ))}
        </Grid>


    </React.Fragment>
    );

}


const Title = (props) => {
    const { text = '', ...other } = props;
    return (
        <Grid container justifyContent="center">
            <Box style={{
                background: 'rgb(243 207 68)', padding: '0.5rem',
                paddingRight: '2rem', paddingLeft: '2rem',
                textAlign: 'center',
                border: 'double 3px #fff',
                margin: '1rem',
                //  borderRadius: '0.5rem',
                // minWidth: '50vw'
                // fontWeight: 'bold',
                width: '100%',
                color: '#fff'
            }}>
                {text}
            </Box>
        </Grid>

    )
}
// const TopStyle = (props) => {
//     return <style jsx></style>;
// };
// const TopMain = (props) => {
//     return <main>ss</main>;
// };
const NewsList = (props) => {
    const { data = [], ...other } = props;


    // const data = [];
    // const data_array = [...data];
    // const half = Math.ceil(data_array.length / 2);

    // const left_data = data_array.splice(0, half);
    // const right_data = data_array.splice(0, half);

    return (
        <>

            {data?.length ? (
                // <Grid container justifyContent="center">
                //     {/* <Typography style={{ color: "#000", paddingLeft: "0.5rem", fontSize: "2rem" }}>
                //         ♦ News ♦
                //     </Typography> */}

                //     <Box style={{
                //         background: 'rgb(243 207 68)', padding: '0.5rem',
                //         paddingRight: '2rem', paddingLeft: '2rem',
                //         textAlign: 'center',
                //         border: 'solid 1px ', margin: '1rem', borderRadius: '0.5rem',
                //         minWidth: '50vw'
                //     }}>

                //     </Box>
                // </Grid>
                <Title text='タレントパークニュース' />

            ) : (
                ""
            )}
            <Box style={{ background: '#FFF', width: '100%' }}>




                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    // alignItems="start"
                    style={{ background: '#fff', padding: '1rem' }}
                // style={{ borderTop: "solid 1px gray", borderBottom: "solid 1px gray" }}

                >
                    <List>
                        {data.map((news, index) => (
                            <React.Fragment key={index}>
                                <ListItem alignItems="flex-start" style={{ paddingLeft: "0.5rem" }}>
                                    <ListItemAvatar style={{ minWidth: "10px", paddingRight: "2px", marginTop: "5px" }}>
                                        <InfoIcon fontSize="small" style={{ color: "#000", fontSize: "1.5rem" }} />
                                    </ListItemAvatar>
                                    {/* <ListItemText
                                    style={{ fontSize: "0.5rem" }}
                                    // primary={format(new Date(news?.created_at), "MMM do EEEE", { locale: ja })}
                                    secondary={
                                        <React.Fragment> */}
                                    {/* <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                style={{ fontSize: "0.8rem" }}
                                            > */}
                                    <div
                                        style={{ fontSize: "1.2rem", marginTop: "0.2rem", color: "#000" }}
                                        className="news_content_div"
                                        dangerouslySetInnerHTML={{
                                            __html: news?.content.replace(/\n/g, "<br>"),
                                        }}
                                    ></div>

                                    {/* {news?.content} */}
                                    {/* </Typography> */}
                                    {/* </React.Fragment> */}
                                    {/* } */}
                                    {/* /> */}
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                    <Grid
                        container
                        style={{ background: "#FCAD37" }}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {/* <div style={{ marginRight: "auto", marginLeft: "auto", padding: "0.2rem", width: "100%" }}>
                        <TwitterTimelineEmbed
                            onLoad={function noRefCheck() { }}
                            options={{
                                height: 500,
                                //     // width: 600,
                                //     maxWidth: 1200,
                                //     minWidth: 500,
                            }}
                            sourceType="timeline"
                            userId={"1614847623906885633"}
                        // userId={"1279418164397305862"}
                        />
                    </div> */}
                    </Grid>
                    {/* <Grid item xs={6}>
                    {left_data.map((news, index) => (
                        <List key={index}>
                            <ListItem alignItems="flex-start" style={{ padding: "1px" }}>
                                <ListItemText
                                    style={{ fontSize: "0.2rem" }}
                                    primary={format(new Date(news?.created_at), "MMM do EEEE", { locale: ja })}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                style={{ fontSize: "0.2rem" }}
                                            >
                                                {news?.content}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </List>
                    ))}
                </Grid>
                <Grid item xs={6}>
                    {right_data.map((news, index) => (
                        <List key={index}>
                            <ListItem alignItems="flex-start" style={{ padding: "1px" }}>
                                <ListItemText
                                    primary={format(new Date(news?.created_at), "MMM do EEEE", { locale: ja })}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                style={{ fontSize: "0.2rem" }}
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                fontSize={"1px"}
                                            >
                                                {news?.content}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </List>
                    ))}
                </Grid> */}
                </Grid>
            </Box>
        </>
    );
};
const TopFooter = (props) => {
    return (
        <Grid container style={{ background: "#333333", padding: "1rem", paddingBottom: "5vw" }}>
            <footer style={{ width: "100%" }}>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <a href="https://www.instagram.com/hybridbank_officialmedia/" target="_blank">
                            <img
                                src="/images/talentBank/Top/foot_icon01.png"
                                alt="instagram"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.facebook.com/hybridbank" target="_blank">
                            <img
                                src="/images/talentBank/Top/foot_icon02.png"
                                alt="facebook"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://twitter.com/talentpark_1?s=11&t=u-4wXAdz_JiCJoFwFXZXRg" target="_blank">
                            <img
                                src="/images/talentBank/Top/logo-white.png"
                                alt="twitter"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a
                            href="https://www.tiktok.com/@hybridbank_official?_d=secCgYIASAHKAESPgo8dgssuuZKkLoLj0Px%2Fospek6P2NaaSVoheXpy2ND1IuTcRPH%2BeHYy6DH6NBmhTg1N0fYEuNxDQ%2FE7P4ioGgA%3D&_r=1&checksum=9a7f278c48702ba363800c64fa71ba04306b1fe8274609cf5962930fc0fa7893&language=ja&sec_uid=MS4wLjABAAAANUK33aTxFBsx66wy8cRADk2FgkJpGbwvVDt5_ZAdYJpqzs5TQ3N7GHGEOoNoCE3_&sec_user_id=MS4wLjABAAAANUK33aTxFBsx66wy8cRADk2FgkJpGbwvVDt5_ZAdYJpqzs5TQ3N7GHGEOoNoCE3_&share_app_id=1180&share_author_id=6968760698750993410&share_link_id=EFAAEEC0-6E44-4056-8132-78C3CBB74C47&source=h5_t&tt_from=copy&u_code=dimkj28l507ibg&user_id=6968760698750993410&utm_campaign=client_share&utm_medium=ios&utm_source=copy"
                            target="_blank"
                        >
                            <img
                                src="/images/talentBank/Top/foot_icon04.png"
                                alt="tictok"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.youtube.com/user/modelouendan" target="_blank">
                            <img
                                src="/images/talentBank/Top/foot_icon05.png"
                                alt="youtube"
                                style={{ width: "10vw", maxWidth: "100px" }}
                            />
                        </a>
                    </Grid>
                </Grid>
            </footer>
        </Grid>
    );
};

export default Top;
