import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    input_message: "",
    chat_list: [],
    read_count: [],
    message_list: [],
    chat_list_info: {
        page: 1,
        page_count: 0,
        total: 0,
    },
};

function addItem(array, item) {
    return Array.from(new Set([...array, item])); //重複データが入らないようにするための対応
}

export const GetChatList = createAsyncThunk("Chat/GetChatList", async (arg, thunkAPI) => {
    const api_key = thunkAPI.getState().Information.token;
    const params = arg;

    let url = "/api/chat_list";
    if (thunkAPI.getState().Information.talent.login) {
        url = "/api/chat_list";
    }

    if (thunkAPI.getState().Information.sponsor.login) {
        url = "/api/company_chat_list";
    }
    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: {
                "API-KEY": api_key,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const GetChatMessage = createAsyncThunk("Chat/GetChatMessage", async (arg, thunkAPI) => {
    const api_key = thunkAPI.getState().Information.token;
    const params = arg;
    let url = "/api/message_list";
    if (thunkAPI.getState().Information.talent.login) {
        url = "/api/message_list";
    }

    if (thunkAPI.getState().Information.sponsor.login) {
        url = "/api/company_message_list";
    }
    try {
        const response = await axios.get(url + "?" + new URLSearchParams(params), {
            headers: {
                "API-KEY": api_key,
                "ontent-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const SendMessage = createAsyncThunk("Chat/SendMessage", async (arg, thunkAPI) => {
    const api_key = thunkAPI.getState().Information.token;
    const params = arg;
    let url = "/api/send_message";
    if (thunkAPI.getState().Information.talent.login) {
        url = "/api/send_message";
    }

    if (thunkAPI.getState().Information.sponsor.login) {
        url = "/api/company_send_message";
    }

    try {
        const response = await axios.post(url, params, {
            headers: {
                "API-KEY": api_key,
                "ontent-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const BulkSendMessage = createAsyncThunk("Chat/BulkSendMessage", async (arg, thunkAPI) => {
    const api_key = thunkAPI.getState().Information.token;
    const params = arg;
    let url = "/api/bulk_send_message";

    try {
        const response = await axios.post(url, params, {
            headers: {
                "API-KEY": api_key,
                "ontent-Type": "application/json",
                "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
            },
        });

        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

// Sliceを生成する
const slice = createSlice({
    name: "Chat",
    initialState,
    reducers: {
        initialize: () => initialState,
        chat_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        message_add: (state, action) => {
            state.message_list = addItem(state.message_list, action.payload);
        },
    },
    extraReducers: {
        [GetChatList.pending]: (state, action) => {},
        [GetChatList.fulfilled]: (state, action) => {
            const payload = action.payload;
            const chat_list = payload.chat_list;
            const read_count = payload.read_count;
            const chat_list_info = payload.chat_list_info;
            if (chat_list) {
                state.chat_list = chat_list;
            }
            if (read_count) {
                state.read_count = read_count;
            }
            if (chat_list_info) {
                state.chat_list_info = chat_list_info;
            }
        },
        [GetChatList.rejected]: (state, action) => {},
        [GetChatMessage.pending]: (state, action) => {},
        [GetChatMessage.fulfilled]: (state, action) => {
            const payload = action.payload;
            const message_list = payload.message_list;
            if (message_list) {
                state.message_list = message_list;
            }

            // console.debug(payload);
        },
        [GetChatMessage.rejected]: (state, action) => {},
        [SendMessage.pending]: (state, action) => {},
        [SendMessage.fulfilled]: (state, action) => {
            const payload = action.payload;
        },
        [SendMessage.rejected]: (state, action) => {},
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { initialize, chat_set, message_add } = slice.actions;
//
// message
// timestamp
// photoURL
// displayName
// avatarDisp
//
//
//
//
